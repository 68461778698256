import React from "react";
import "./styles.scss";

interface ICheckboxProps {
    name?: string;
    size?: "sm" | "md" | "lg";
    checked: boolean;
    onClick?: () => void;
}

const Checkbox: React.FC<ICheckboxProps> = ({
    name,
    size,
    checked,
    onClick,
}) => {
    return (
        <input
            name={name}
            className={size ? `size-${size}` : "size-md"}
            type="checkbox"
            checked={checked}
            onClick={onClick}
        />
    );
};

export default Checkbox;
