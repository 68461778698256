import axios from "axios";
import Qs from "qs";
import { IMonthlyParams } from "../transactions/models";
import { IBrandSummary } from "./models";

async function getBrandSummary(
    token: string,
    months: IMonthlyParams
): Promise<IBrandSummary> {
    //GET /api/v1/company/brand_summary
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/brand_summary`,
            {
                headers: { Authorization: `${token}` },
                params: {
                    page: 1,
                    q: {
                        ...months,
                    },
                },
                paramsSerializer: function (params) {
                    return Qs.stringify(params, { arrayFormat: "brackets" });
                },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
}

export default getBrandSummary;
