import axios from "axios";
import Qs from "qs";
import { IMonthlyParams } from "../transactions/models";
import { IUsageListRes } from "./models";

async function getUsageList(
    token: string,
    page: number,
    months: IMonthlyParams
): Promise<IUsageListRes> {
    //GET /api/v1/company/usage_list
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/usage_list`,
            {
                headers: { Authorization: `${token}` },
                params: {
                    page,
                    q: {
                        ...months,
                    },
                },
                paramsSerializer: function (params) {
                    return Qs.stringify(params, { arrayFormat: "brackets" });
                },
            }
        );
        return {
            token: res.headers.authorization,
            data: res.data,
        };
    } catch (e) {
        throw e;
    }
}

export default getUsageList;
