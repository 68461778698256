import dayjs from "dayjs";

export const dateFormat = (date: dayjs.ConfigType, format = 'YYYY/MM/DD') => {
  return dayjs(date).format(format)
}

export const toTime = (date?: dayjs.ConfigType) => {

  return date ? dayjs(date).format("HH:mm") : null;
}

export const toDate = (date?: dayjs.ConfigType) => {
  return date ? dayjs(date).format('YYYY/MM/DD') : null;
}

export const toDateTime = (date?: dayjs.ConfigType) => {
  return date ? dayjs(date).format("YYYY/MM/DD HH:mm") : null;
}
