import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import { errorReset, errorSet } from "../error/actions";
import { RootState } from "../index";
import { punchSuccess } from "./actions";
import { IPunch} from "./models";
import postPunch from "./postPunch";

export const thunkPostPunch =
    (
        token: string,
        body: IPunch
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(punchSuccess(null));
        try {
            // call API
            const res = await postPunch(token, body);
            // set success
            dispatch(punchSuccess(res.status));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            // set success
            dispatch(punchSuccess(false));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const setPunchStatus = (status: boolean)
    : ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(punchSuccess(status))
    }
