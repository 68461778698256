import * as Models from "./models";

export function notificationReducer(
    state = Models.initialNotificationState,
    action: Models.INotificationSet | Models.INotificationHideSet
): Models.INotification {
    switch (action.type) {
        case Models.NotificationActionTypes.NOTIFICATION_SET:
            if (state.kv_notification === action.payload.kv_notification)
                return state;
            return {
                ...state,
                ...action.payload,
            };
        case Models.NotificationActionTypes.NOTIFICATION_HIDE_SET:
            return {
                ...state,
                show: action.payload,
            };
        default:
            return state;
    }
}
