import React, { useEffect, useState } from "react";
import { Column, Label, Row } from "@kortvaluta/admin-twooca-react";
import { SearchUserOption } from "../../../../store/healthcareSearch/models";
import Select, { SingleValue } from "react-select";

interface SelectOption {
    value: string;
    label: string;
}

interface SearchSelectProps {
    options: SearchUserOption[];
    label?: string;
    placeholder: string;
    selected?: string;
    isCreable?: boolean;
    onChange: (user: SearchUserOption | null) => void;
}

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
    const { options, label, placeholder, selected, isCreable, onChange } =
        props;

    const [selectedOption, setSelectedOption] = useState<SearchUserOption>();
    // SearchUserOption[]をSelectOption[]に変換
    const selectOptions: SelectOption[] = options.map((option) => ({
        value: option.value,
        label: option.label,
    }));

    // 選択が変更されたときのハンドラ
    const handleChange = (newValue: SingleValue<SelectOption>) => {
        if (newValue) {
            const selectedUser = options.find(
                (option) => option.value === newValue.value
            );
            onChange(selectedUser || null);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {
        const selectedVal = selected
            ? options.find((option) => option.value === selected)
            : undefined;
        setSelectedOption(selectedVal);
    }, [options, selected]);

    return (
        <>
            <Row>
                {label && (
                    <Column xs={12} md={1}>
                        <Label text={label} />
                    </Column>
                )}
                <Column xs={12} md={12}>
                    <Select<SelectOption>
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={placeholder}
                        isClearable={isCreable || true}
                        isSearchable={true}
                        name="name"
                        value={selectedOption}
                        options={selectOptions}
                        onChange={handleChange}
                    />
                </Column>
            </Row>
        </>
    );
};

export default SearchSelect;
