import { employee } from "./helpers";
// employee models

export enum EmployeesActionTypes {
    EMPLOYEES_SET = "employees/set",
    EMPLOYEE_SET = "employee/set",
    EMPLOYEE_UPDATE_SET = "employee-update/set",
    EMPLOYEE_UPDATE_SUCCESS = "employee-update/success",
    EMPLOYEE_INIT = "employee/init",
    EMPLOYEE_EXAMPLE_ZIP_SET = "EMPLOYEE_EXAMPLE_ZIP_SET",
    EMPLOYEE_EXAMPLE_CSV_SET = "EMPLOYEE_EXAMPLE_CSV_SET",
    EMPLOYEE_UPDATE_IMAGE_SET = "EMPLOYEE_UPDATE_IMAGE_SET",
    EMPLOYEE_UPLOAD_ERROR_SET = "EMPLOYEE_UPLOAD_ERROR_SET",
}

export interface IEmployeeExampleZip {
    zip_file: string | null;
}

export interface IEmployeeExampleCsv {
    csv_file: string | null;
}

export interface IEmployeeExampleState {
    csv_file: string | null;
    zip_file: string | null;
}

interface IUrl {
    url: string;
}

interface IFaceImage {
    url: string;
    small: IUrl;
    thumb: IUrl;
}

export interface IEmployee {
    user_uid: string;
    employee_code: string;
    email: string;
    first_name: string;
    last_name: string;
    first_name_furi: string;
    last_name_furi: string;
    status?: string;
    retired_at?: string;
    face_image?: IFaceImage;
    perk_balance?: number;
    agreement?: boolean;
}

export type TEmployeeSearchParam =
    | "employee_info_employee_code_cont"
    | "user_profile_first_name_cont"
    | "user_profile_first_name_furi_cont"
    | "user_profile_last_name_cont"
    | "user_profile_last_name_furi_cont"
    | "email_cont";

export interface IEmployeesSearchParams {
    email_cont?: string;
    employee_info_employee_code_cont?: string;
    user_profile_first_name_cont?: string;
    user_profile_first_name_furi_cont?: string;
    user_profile_last_name_cont?: string;
    user_profile_last_name_furi_cont?: string;
    employee_info_status_eq?: string;
}

export interface IEmployeeParams {
    email: string;
    employee_code: string;
    last_name: string;
    last_name_furi: string;
    last_name_en: string;
    middle_name: string;
    middle_name_furi: string;
    middle_name_en: string;
    first_name: string;
    first_name_furi: string;
    first_name_en: string;
    gender: string;
    birthday: string;
    postcode: string;
    address: string;
    address2: string;
    status?: string;
    retired_at?: string;
    tel?: string;
    perk_eligible: boolean;
}

export interface IEmployeeParamsError {
    email: boolean;
    employee_code: boolean;
    last_name: boolean;
    last_name_furi: boolean;
    last_name_en: boolean;
    middle_name: boolean;
    middle_name_furi: boolean;
    middle_name_en: boolean;
    first_name: boolean;
    first_name_furi: boolean;
    first_name_en: boolean;
    gender: boolean;
    birthday: boolean;
    postcode: boolean;
    address: boolean;
    address2: boolean;
    status?: boolean;
    tel?: boolean;
    perk_eligible: boolean;

    //just for inoput birthday
    year?: string;
    month?: string;
    day?: string;
}

export interface IEmployees {
    employees: Array<IEmployee>;
    total_pages: number;
    limit_value: number;
}

export interface IEmployeeUpdate {
    email: string;
    user_uid?: string;
    employee_code: string;
    last_name: string;
    last_name_furi: string;
    last_name_en: string;
    middle_name: string;
    middle_name_furi: string;
    middle_name_en: string;
    first_name: string;
    first_name_furi: string;
    first_name_en: string;
    gender: string;
    birthday: string;
    postcode: string;
    address: string;
    address2: string;
    status?: string;
    retired_at?: string;
    face_image?: IFaceImage;
    tel?: string;
    perk_eligible: boolean;
}

export interface IEmployeeEdit {
    employee: IEmployeeUpdate;
}

export interface IEmployeeSuccess {
    success: boolean;
}

export interface IEmployeeInit {
    init: boolean;
}

export interface IEmployeeUpdatedImageData {
    formData: FormData;
    image: string;
}

export interface IEmployeeUpdateImage {
    updatedImageData: IEmployeeUpdatedImageData;
}

export interface IEmployeesSetAction {
    type: EmployeesActionTypes.EMPLOYEES_SET;
    payload: IEmployees;
}

export interface IEmployeeSetAction {
    type: EmployeesActionTypes.EMPLOYEE_SET;
    payload: IEmployeeEdit;
}

export interface IEmployeeUpdateSetAction {
    type: EmployeesActionTypes.EMPLOYEE_UPDATE_SET;
    payload: IEmployeeUpdate;
}

export interface IEmployeeUpdateSuccessAction {
    type: EmployeesActionTypes.EMPLOYEE_UPDATE_SUCCESS;
    payload: IEmployeeSuccess;
}

export interface IEmployeeInitAction {
    type: EmployeesActionTypes.EMPLOYEE_INIT;
    payload: IEmployeeInit;
}

export interface IEmployeeExampleZipSetAction {
    type: EmployeesActionTypes.EMPLOYEE_EXAMPLE_ZIP_SET;
    payload: IEmployeeExampleZip;
}

export interface IEmployeeExampleCsvSetAction {
    type: EmployeesActionTypes.EMPLOYEE_EXAMPLE_CSV_SET;
    payload: IEmployeeExampleCsv;
}

export interface IEmployeeUpdateFormDataSetAction {
    type: EmployeesActionTypes.EMPLOYEE_UPDATE_IMAGE_SET;
    payload: IEmployeeUpdateImage;
}

export interface IEmployeesState {
    employeeItems: IEmployees;
    employee: IEmployeeUpdate;
    success: boolean;
    init: boolean;
    example: IEmployeeExampleState;
    updatedImageData: IEmployeeUpdatedImageData;
}

export const initialEmployeesState: IEmployeesState = {
    employeeItems: {
        employees: [],
        total_pages: 1,
        limit_value: 25,
    },
    employee,
    success: false,
    init: false,
    example: {
        csv_file: null,
        zip_file: null,
    },
    updatedImageData: {
        formData: null,
        image: "",
    },
};
