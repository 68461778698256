export const formatCreatedAt = (dateStr: string) => {
    return new Date(dateStr).toLocaleString();
};

//formatter for month range for searching
export const formatMonthsParams = (month: number, year: number) => {
    const firstDay = new Date(year, month, 1).getDate();
    const lastDay = new Date(year, month + 1, 0).getDate();
    return {
        created_at_gteq: `${year}-${month}-${firstDay}`,
        created_at_lteq: `${year}-${month}-${lastDay}`,
    };
};

//formatter for time (ex 18:30:45)
export const getCurrentHours = () => {
    const today = new Date();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const currentHours = `${hours}:${minutes}:${seconds}`;
    return currentHours;
};

//formatter for birthday (ex 1996/01/16 or 1996年1月16日)
export const dateFormatter = (dateStr: string, toJapanese?: boolean) => {
    const date = new Date(dateStr);
    if (date === null) return "";
    const str =
        date.getFullYear() +
        `${toJapanese ? "年" : "/"}` +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        `${toJapanese ? "月" : "/"}` +
        ("0" + date.getDate()).slice(-2) +
        `${toJapanese ? "日" : ""}`;
    return str;
};

export const dateFormatterForTime = (dateStr: string, toJapanese?: boolean) => {
    const date = new Date(dateStr);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}${toJapanese ? "月" : "/"}${day} ${hours}:${minutes}`;
};

export const dateFormatterForSleep = (
    dateStr: string,
    toJapanese?: boolean
) => {
    const date = new Date(dateStr);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
};

export const getSumTime = (dateStr1: string, dateStr2: string) => {
    const date = new Date(dateStr1);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const date2 = new Date(dateStr2);
    const hours2 = String(date2.getHours()).padStart(2, "0");
    const minutes2 = String(date2.getMinutes()).padStart(2, "0");
    const seconds2 = String(date2.getSeconds()).padStart(2, "0");
    const total1 = Math.round(
        (Number(hours2) * 3600 + Number(minutes2) * 60 + Number(seconds2)) / 60
    );
    const total2 = Math.round(
        (Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)) / 60
    );
    return total1 - total2;
};

export const getRetiredDate = (date: Date) => {
    return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2)
    );
};

export const dateFormatterForDay = (dateStr: string) => {
    const date = new Date(dateStr);
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
};
