import { AnyAction } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { errorSet, errorReset } from "../error/actions";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import { usageListSet, usageSummarySet } from "./actions";
import getUsageList from "./getUsageList";
import getUsageSummary from "./getUsageSummary";
import { IMonthlyParams } from "../transactions/models";
import { sessionStatus } from "../session/actions";

export const thunkGetUsage =
    (
        token: string,
        page = 1,
        months: IMonthlyParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        try {
            // call API
            const list = await getUsageList(token, page, months);
            const summary = await getUsageSummary(token, page, months);
            //set data
            dispatch(usageListSet(list.data));
            dispatch(usageSummarySet(summary));
            //refresh token
            dispatch(
                sessionStatus({
                    status: true,
                    token: list.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkGetUsageList =
    (
        token: string,
        page = 1,
        months: IMonthlyParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        try {
            // call API
            const res = await getUsageList(token, page, months);
            dispatch(usageListSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkGetUsageSummary =
    (
        token: string,
        page = 1,
        months: IMonthlyParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        try {
            // call API
            const summary = await getUsageSummary(token, page, months);
            //set data
            dispatch(usageSummarySet(summary));
            //refresh token
            dispatch(
                sessionStatus({
                    status: true,
                    token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };
