import * as React from "react";

interface IContentProps {
    children: React.ReactNode;
}

const Content: React.VFC<IContentProps> = ({ children }) => {
    return (
        <div
            style={{
                paddingTop: "3.5rem",
                paddingBottom: "1rem",
            }}
        >
            {children}
        </div>
    );
};

export default Content;
