import React, { useState, useEffect } from "react";
import { isAfter, isSameDay, startOfDay } from "date-fns";
import SelectPicker from "../../../../shared/selectPicker";
import DatePicker from "../../../../shared/datePicker";
import AppDataGraph from "./AppDataGraph";
import { ISearchParams } from "../../../../../store/healthcareSearch/models";
import { SearchUserOption } from "../../../../../store/healthcareSearch/models";
import { useDispatch, useSelector } from "react-redux";
import { thunkGetHistoricalData } from "../../../../../store/healthcareSearch/thunk";
import ActionBtn from "../../../../shared/actionButton/ActionBtn";
import { RootState } from "../../../../../store";
import SearchSelect from "../../../../shared/searchContainer/searchSelect";
import { Label } from "@kortvaluta/admin-twooca-react";
import ErrorText from "../../../../shared/error/text/ErrorText";

interface AppViewProps {
    userOptions: SearchUserOption[];
    searchParams: ISearchParams | null;
    setSearchParams: (params: ISearchParams) => void;
    selectedDate: Date;
    periodType: "day" | "week" | "month";
    handlePeriodTypeChange: (type: "day" | "week" | "month") => void;
    handleDateChange: (date: Date) => void;
    navigate: (path: string) => void;
    historicalData: any;
    initialUserId?: string;
}

const AppView: React.FC<AppViewProps> = ({
    userOptions,
    searchParams,
    setSearchParams,
    selectedDate,
    periodType,
    navigate,
    historicalData,
    initialUserId,
}) => {
    const token = useSelector((store: RootState) => store.session.token);

    const dispatch = useDispatch();
    const [localUser, setLocalUser] = useState(initialUserId || "");
    const [localPeriodType, setLocalPeriodType] = useState(periodType);
    const [localDate, setLocalDate] = useState(selectedDate);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (initialUserId) {
            setLocalUser(initialUserId);
        }
    }, [initialUserId]);

    const handleUserChange = (selectedUser: SearchUserOption) => {
        setLocalUser(selectedUser.value);
    };

    const handleSearch = () => {
        setError("");
        if (disableFutureDates(localDate)) {
            setError("未来日は選択できません");
        } else {
            const user = userOptions.find(
                (option) => option.value === localUser
            );
            if (user) {
                setSearchParams({ ...searchParams, user });
                navigate(`?userId=${localUser}`);
                dispatch(
                    thunkGetHistoricalData(token, {
                        user: user,
                        date: localDate,
                        type: localPeriodType,
                    })
                );
            }
        }
    };

    const disableFutureDates = (date: Date) => {
        const today = startOfDay(new Date());
        return isAfter(date, today) && !isSameDay(date, today);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    margin: "20px 0",
                }}
            >
                <div style={{ flex: "1 1 200px" }}>
                    <Label text="日付" />
                    <DatePicker value={localDate} onChange={setLocalDate} />
                </div>
                <div style={{ flex: "1 1 200px" }}>
                    <Label text="グラフ表示タイプ" />
                    <SelectPicker
                        data={[
                            { label: "日", value: "day" },
                            { label: "週", value: "week" },
                            { label: "月", value: "month" },
                        ]}
                        value={localPeriodType}
                        onChange={setLocalPeriodType}
                        style={{ width: "100%" }}
                        placeholder="表示タイプ"
                    />
                </div>
                <div style={{ flex: "2 1 300px" }}>
                    <SearchSelect
                        options={userOptions}
                        label="氏名"
                        placeholder="氏名を選択してください"
                        onChange={handleUserChange}
                        selected={initialUserId}
                        isCreable={false}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                }}
            >
                <ActionBtn
                    text="検索する"
                    onClick={handleSearch}
                    color="primary"
                    submit
                />
            </div>
            {error && (
                <div style={{ marginBottom: "20px" }}>
                    <ErrorText text={error} />
                </div>
            )}
            <AppDataGraph data={historicalData} periodType={localPeriodType} />
        </>
    );
};

export default AppView;
