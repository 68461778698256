import * as React from "react";
import "./styles.scss";

interface TabPanelProps {
    items: string[];
    activeIdx?: number;
    onClick?: (idx: number) => void;
}

const TabPanel: React.FC<TabPanelProps> = ({
    items,
    activeIdx,
    onClick,
    children,
}) => {
    return (
        <>
            <div className="tabs-container">
                <ul className="tabs">
                    {items.map((item: string, index: number) => (
                        <li
                            className={`item ${
                                index === activeIdx && "tab--active"
                            }`}
                            onClick={() => {
                                onClick(index);
                            }}
                            key={index.toString()}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tab-panel">{children}</div>
        </>
    );
};

export default TabPanel;
