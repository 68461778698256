import React, { useEffect, useState } from "react";
import { IEmployeeUpdate } from "../store/employee/models";

export default function useImage(): [
    string,
    FormData,
    boolean,
    (e: React.ChangeEvent<HTMLInputElement>) => void,
    (employee: IEmployeeUpdate) => void
] {
    const [fileLimit, setFileLimit] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [image, setImage] = useState<string>("");

    // face_change select onchange
    const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files.length > 0) {
            if (event.currentTarget.files[0].size / (1024 * 1024) < 1) {
                const newFormData = new FormData();
                newFormData.append(
                    "face_image",
                    event.currentTarget.files[0],
                    event.currentTarget.files[0].name
                );
                setImage(URL.createObjectURL(event.currentTarget.files[0]));
                setFormData(newFormData);
                setFileLimit(false);
            } else setFileLimit(true);
        }
    };

    const setInitImage = (employee: IEmployeeUpdate) => {
        useEffect(() => {
            if (employee.face_image) {
                if (employee.face_image.url) {
                    setImage(employee.face_image.url);
                }
            }
            return () => {
                setImage("");
            };
        }, [employee]);
    };

    return [image, formData, fileLimit, onChangeImage, setInitImage];
}
