import * as Models from "./models";

export function requestEnrollmentSuccessSet(
	success: boolean
): Models.IEnrollmentRequestSuccessSetAction {
	return {
		type: Models.EnrollmentRequestActionTypes.ENROLLMENT_REQUEST_SUCCESS_SET,
		payload: success,
	};
}

export function bulkRequestEnrollmentSuccessSet(
	success: boolean
): Models.IBulkEnrollmentRequestSuccessSetAction {
	return {
		type: Models.EnrollmentRequestActionTypes
			.BULK_ENROLLMENT_REQUEST_SUCCESS_SET,
		payload: success,
	};
}
