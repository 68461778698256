import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";

interface TabSwitchProps {
    tabItems?: Array<React.ReactNode>;
    toggle?: boolean;
}

const TabSwitch: React.FC<TabSwitchProps> = (props) => {
    const { tabItems, toggle } = props;
    return (
        <div className="tab-switch">
            {tabItems.map((item, index) => (
                <CSSTransition
                    key={index.toString()}
                    in={index > 0 ? toggle : !toggle}
                    timeout={{ enter: 500, exit: 0 }}
                    unmountOnExit
                    classNames="tab-fade"
                >
                    <div className="tab-switch__item">{item}</div>
                </CSSTransition>
            ))}
        </div>
    );
};

export default TabSwitch;
