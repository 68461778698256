import * as React from "react";
import { Navigate, RouteProps } from "react-router";

export interface PrivateRouteProps extends RouteProps {
    isAuthenticated: boolean; // ユーザーが認証されているかどうか
    children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const { isAuthenticated, children } = props;
    // switch back
    return isAuthenticated ? children : <Navigate to={"/login"} />;
};

export default PrivateRoute;
