import * as Models from "./models";

export function healthDataDashboardSet(
    users: Models.IHealthcareDashboardState
): Models.IHealthDataDashboardSetAction {
    return {
        type: Models.HealthcareActionTypes.HEALTHCARE_SET,
        payload: users,
    };
}

export function ringUserSet(
    user: Models.IRingUserState
): Models.IRingUserSetAction {
    return {
        type: Models.HealthcareActionTypes.RING_USER_SET,
        payload: user,
    }
}

export function ringUsersSet(
    ringUsers: Models.RingUsersState
): Models.RingUsersSetAction {
    return {
        type: Models.HealthcareActionTypes.RING_USERS_SET,
        payload: ringUsers
    }
}
