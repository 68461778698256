import React, { useState, useEffect, useCallback, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { Content, Modal, Button } from 'rsuite';
import {
  Page,
  Panel,
  FlexBox,
  Container,
} from '@kortvaluta/admin-twooca-react';
import { FaCog } from "react-icons/fa";
import { thunkRefresh } from '../../../store/session/thunk';
import { thunkPostPunch, setPunchStatus } from '../../../store/punch/thunk';
import { RootState } from '../../../store';
import PageBackground from '../../shared/page/PageBackground';
import SpaceBox from '../../shared/box/space/SpaceBox';
import './styles.scss';

type PunchType = 'in' | 'out';
type CameraMode = 'user' | 'environment';
type ModalType = 'success' | 'error'

const PunchButton = ({ type, onClick }) => (
  <button
    className={`punch-button ${type}`}
    onClick={() => onClick(type)}
  >
    {type === 'in' ? '出勤' : '退勤'}
  </button>
);

const CurrentDateTime = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatDate = (date) => {
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const dayOfWeek = days[date.getDay()];
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}年${month}月${day}日 (${dayOfWeek}) ${hours}:${minutes}:${seconds}`;
  };

  return <div className="current-date-time">{formatDate(dateTime)}</div>;
};

const QRReaderComponent = ({ onResult, onTimeout, camera }) => {
  const hasReadRef = useRef(false);

  const handleScan = useCallback((result, error) => {
    if (result && !hasReadRef.current) {
      hasReadRef.current = true;
      onResult(result, error);
    }
  }, [onResult]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onTimeout();
    }, 30000); // 30秒のタイマー

    return () => {
      clearTimeout(timer);
      hasReadRef.current = false;
    };
  }, [onTimeout]);

  return (
    <div className="qr-reader-fullscreen">
      <QrReader
        onResult={handleScan}
        constraints={{
          facingMode: camera
        }}
        className="qr-reader"
      />
      <p className="qr-timer">QRコードを読み取ってください</p>
    </div>
  );
};

const CommonModal = ({ type, message, isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen && type === 'success') {
      const timer = setTimeout(() => {
        onClose();
      }, 10000); // 10秒後に自動で閉じる（成功時のみ）

      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose, type]);

  const title = type === 'success' ? '成功' : 'エラー';

  return (
    <Modal className='common-modal' show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{message}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CameraSettingsModal = ({ isOpen, onClose, currentMode, onModeChange }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>カメラ設定</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>
            <input
              type="radio"
              value="user"
              checked={currentMode === 'user'}
              onChange={() => onModeChange('user')}
            />
            前面カメラ
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="environment"
              checked={currentMode === 'environment'}
              onChange={() => onModeChange('environment')}
            />
            背面カメラ
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PunchScreen = () => {
  const dispatch = useDispatch();
  const token = useSelector((store: RootState) => store.session.token);
  const punchStatus = useSelector((store: RootState) => store.punch.status);
  const [punchType, setPunchType] = useState<PunchType>('in');
  const [showQRReader, setShowQRReader] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: 'error' as ModalType,
    message: '',
  });
  const [cameraMode, setCameraMode] = useState<CameraMode>(() => {
    const savedMode = localStorage.getItem('cameraMode');
    return (savedMode as CameraMode) || 'user';
  });
  const [showCameraSettings, setShowCameraSettings] = useState(false);
  const isProcessingRef = useRef(false);

  useEffect(() => {
    dispatch(setPunchStatus(null));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(thunkRefresh(token));
    }, 1000 * 60 * 3);
    return () => clearInterval(interval);
  }, [dispatch, token]);

  useEffect(() => {
    if (punchStatus === false) {
      setModalState({
        isOpen: true,
        type: 'error',
        message: '勤怠打刻エラー、人事部へご確認下さい',
      });
    } else if (punchStatus === true) {
      setModalState({
        isOpen: true,
        type: 'success',
        message: '読み取りが成功しました',
      });
    }
  }, [punchStatus]);

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handlePunchButtonClick = useCallback((type: PunchType) => {
    setPunchType(type);
    setShowQRReader(true);
    isProcessingRef.current = false;
  }, []);

  const handleResult = useCallback((result, _) => {
    if (result && !isProcessingRef.current) {
      isProcessingRef.current = true;
      setShowQRReader(false);
      sendDataToServer(punchType, result.text);
    }
  }, [punchType]);

  const handleTimeout = useCallback(() => {
    setShowQRReader(false);
    isProcessingRef.current = false;
    setModalState({
      isOpen: true,
      type: 'error',
      message: `QRコードが正しく読み取れませんでした。

以下をご確認ください。
・社員証以外のQRが読み取られていないか
・QRコードの有効期限が切れていないか`,
    });
  }, []);

  const sendDataToServer = useCallback((type: PunchType, qrCode: string) => {
    const body = { type, qrCode };
    dispatch(thunkPostPunch(token, body));
  }, [dispatch, token]);

  const handleCloseModal = useCallback(() => {
    setModalState(prev => ({ ...prev, isOpen: false }));
    isProcessingRef.current = false;
  }, []);

  const handleCameraModeChange = useCallback((mode: CameraMode) => {
    setCameraMode(mode);
    localStorage.setItem('cameraMode', mode);
  }, []);

  return (
    <PageBackground>
      <Page toggle={false}>
        <Content>
          <Container>
            <div className='punch-screen-container'>
              <Panel>
                <SpaceBox>
                  <div className="punch-screen-content punch-screen-layout">
                    <div className="settings-icon" onClick={() => setShowCameraSettings(true)}>
                      <FaCog/>
                    </div>
                    <FlexBox direction="column" justify="center" align="center">
                      <CurrentDateTime />
                      {!showQRReader ? (
                        <div className="button-container">
                          <PunchButton type="in" onClick={() => handlePunchButtonClick('in')} />
                          <PunchButton type="out" onClick={() => handlePunchButtonClick('out')} />
                        </div>
                      ) : (
                        <QRReaderComponent
                          onResult={handleResult}
                          onTimeout={handleTimeout}
                          camera={cameraMode}
                        />
                      )}
                    </FlexBox>
                  </div>
                </SpaceBox>
              </Panel>
            </div>
          </Container>
        </Content>
      </Page>
      <CommonModal
        type={modalState.type}
        message={modalState.message}
        isOpen={modalState.isOpen}
        onClose={handleCloseModal}
      />
      <CameraSettingsModal
        isOpen={showCameraSettings}
        onClose={() => setShowCameraSettings(false)}
        currentMode={cameraMode}
        onModeChange={handleCameraModeChange}
      />
    </PageBackground>
  );
};

export default PunchScreen;
