export enum SidebarActionTypes {
  SIDEBAR_TOGGLE = "sidebar/toggle",
}

export interface ISidebarToggle {
  sidebarToggle: boolean;
}

export interface ISidebarToggleAction {
  type: SidebarActionTypes.SIDEBAR_TOGGLE;
  payload: ISidebarToggle;
}

const vw = Math.max(
  document.documentElement.clientWidth,
  window.innerWidth || 0
);
export const initialSidebarToggleState: ISidebarToggle = {
  sidebarToggle: vw >= 1200 ? true : false,
};
