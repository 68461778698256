import * as Models from "./models";

export function usageListSet(
  list: Models.IUsageList
): Models.IUsageListSetAction {
  return {
    type: Models.UsageActionTypes.USAGE_LIST_SET,
    payload: {
      ...list,
    },
  };
}

export function usageSummarySet(
  item: Models.IUsageSummary
): Models.IUsageSummarySetAction {
  return {
    type: Models.UsageActionTypes.USAGE_SUMMARY_SET,
    payload: {
      ...item,
    },
  };
}
