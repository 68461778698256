import axios from "axios";
import { logoutUrl } from "../../constants";

const logout = async (token: string): Promise<void> => {
    try {
        const res = await axios.delete(logoutUrl, {
            headers: { Authorization: `${token}` },
        });
    } catch (e) {
        throw e;
    }
};

export default logout;
