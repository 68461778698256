import * as Models from "./models";

export function adminInfoReducer(
  state = Models.initialAdminInfoState,
  action: Models.IAdminInfoSetAction
): Models.IAdminInfo {
  switch (action.type) {
    case Models.AdminInfoActionTypes.ADMIN_INFO_SET:
      sessionStorage.setItem("company_name", action.payload.company_name);
      sessionStorage.setItem(
        "comapny_admin_name",
        action.payload.company_admin_name
      );
      sessionStorage.setItem(
        "comapny_admin_email",
        action.payload.company_admin_email
      );

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
