import * as Models from "./models";

export function categorySummarySet(
	data: Models.ICategorySummaryResponse
): Models.ICategorySummarySetAction {
	return {
		type: Models.CategorySummaryActionTypes.CATEGORY_SUMMARY_SET,
		payload: data,
	};
}
