import axios from "axios";
import { IBulkEnrollmentParams } from "./models";

async function postBulkRequestEnrollment(
	token: string,
	body: IBulkEnrollmentParams
): Promise<void> {
	// POST /api/v1/company/request_enrollment/bulk_request_form
	try {
		const res = await axios.post(
			`${process.env.COMPANY_ADMIN_BASE}/request_enrollment/bulk_request_form`,
			body,
			{
				headers: { Authorization: `${token}` },
			}
		);
	} catch (e) {
		throw e;
	}
}

export default postBulkRequestEnrollment;
