import * as Models from "./models";

export function punchSuccess(
    status: boolean
): Models.IPunchSuccessAction {
    return {
        type: Models.PunchActionTypes.PUNCH_SUCCESS,
        payload: status,
    };
}
