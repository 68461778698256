export enum HealthcareActionTypes {
  HEALTHCARE_SET = "healthcare/set",
  RING_USER_SET = "healthcare/ring_user_set",
  RING_USERS_SET = "healthcare/ring_users_set"
}

export interface IHealthcareDashboard {
  user_uid: string;
  name: string;
  heart_rate: number | null;
  heart_rate_average: number | null;
  heart_rate_date: string | null;
  steps_count: number | null;
  cumulative_steps_count: number | null;
  steps_count_date: string | null;
  temperature: number | null;
  temperature_average: number | null;
  temperature_date: string | null;
  sleep_start: Date | null;
  sleep_end: Date | null;
  sleep_status: string | null;
  sleep_sum: number | null;
}

export interface IRingUser {
  user_uid: string;
  email: string;
  name: string;
  mac_address: string;
}

export interface RingUser {
  uid: any;
  employee_code: string,
  name: string
}

export interface RingUsers {
  list: Array<RingUser>,
  total_pages: number,
  limit_value: number,
}

export interface IHealthDataDashboardSetAction {
  type: HealthcareActionTypes.HEALTHCARE_SET;
  payload: IHealthcareDashboardState;
}

export interface IRingUserSetAction {
  type: HealthcareActionTypes.RING_USER_SET;
  payload: IRingUserState;
}

export interface RingUsersSetAction {
  type: HealthcareActionTypes.RING_USERS_SET;
  payload: RingUsersState;
}

export interface IHealthcareDashboardState {
  dashboard: {
    users: Array<IHealthcareDashboard>;
    total_pages: number;
    limit_value: number;
  }
}

export interface IRingUserState {
  userSearch: {
    users: IRingUser[]
  }
}

export type RingUsersState = RingUsers

export const initialHealthCare: IRingUserState & IHealthcareDashboardState = {
  dashboard: {
    users: [{
      user_uid: "",
      name: "",
      heart_rate: null,
      heart_rate_average: null,
      heart_rate_date: null,
      steps_count: null,
      cumulative_steps_count: null,
      steps_count_date: null,
      temperature: null,
      temperature_average: null,
      temperature_date: null,
      sleep_start: null,
      sleep_end: null,
      sleep_status: null,
      sleep_sum: null,
    }],
    total_pages: 0,
    limit_value: 25,
  },
  userSearch: {
    users: [{
      user_uid: "",
      name: "",
      email: "",
      mac_address: "",
    }]
  },
}

export const initialRingUsers: RingUsers = {
  list: [{
    employee_code: "",
    name: "",
    uid: "",
  }],
  total_pages: 0,
  limit_value: 25,
}
