import * as Models from "./models";

export function sidebarToggleReducer(
  state = Models.initialSidebarToggleState,
  action: Models.ISidebarToggleAction
): Models.ISidebarToggle {
  switch (action.type) {
    case Models.SidebarActionTypes.SIDEBAR_TOGGLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
