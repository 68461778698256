import { createStore, combineReducers, AnyAction } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { navigationReducer } from "./navigation/reducers";
import { errorReducer } from "./error/reducers";
import { sessionReducer } from "./session/reducers";
import { employeeReducer } from "./employee/reducers";
import { sidebarToggleReducer } from "./sidebar/reducers";
import { transactionReducer } from "./transactions/reducers";
import { adminInfoReducer } from "./admin/reducers";
import { contactReducer } from "./contact/reducers";
import middleware from "../middleware";
import { announcementsReducer } from "./announcements/reducers";
import { notificationReducer } from "./notification/reducers";
import { usageReducer } from "./usage/reducers";
import { categorySummaryReducer } from "./category/reducers";
import { brandSummaryReducer } from "./brand/reducers";
import { requestEnrollmentReducer } from "./enrollment/reducers";
import { currentPageReducer } from "./page/reducer";
import { healthcareReducer, healthcareRingUserReducer } from "./healthcare/reducers";
import {
    healthcareSearchReducer,
    searchUserOptionsReducer,
    HistoricalDataReducer,
} from "./healthcareSearch/reducers";
import { companyServiceReducer } from "./company/reducers";
import { punchReducer } from "./punch/reducers";

// combine reducers
const rootReducer = combineReducers({
    loadingBar: loadingBarReducer,
    navigation: navigationReducer,
    errors: errorReducer,
    session: sessionReducer,
    employeesInfo: employeeReducer,
    sidebar: sidebarToggleReducer,
    transactions: transactionReducer,
    adminInfo: adminInfoReducer,
    contact: contactReducer,
    announcements: announcementsReducer,
    notifications: notificationReducer,
    categoryUsage: categorySummaryReducer,
    usage: usageReducer,
    brand: brandSummaryReducer,
    requestEnrollment: requestEnrollmentReducer,
    currentPage: currentPageReducer,
    healthcare: healthcareReducer,
    healthcareSearch: healthcareSearchReducer,
    historicalData: HistoricalDataReducer,
    ringUsers: healthcareRingUserReducer,
    services: companyServiceReducer,
    userOptions: searchUserOptionsReducer,
    punch: punchReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore<RootState, AnyAction, any, any>(
    rootReducer,
    middleware
);
