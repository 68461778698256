import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import {
    ISearchParams,
    ISearchTarget,
    SearchUserOption,
} from "../store/healthcareSearch/models";
import { thunkGetSearchData } from "../store/healthcareSearch/thunk";

interface HealthcareSearchParams {
    user: SearchUserOption;
    minDate: string | null;
    maxDate: string | null;
    minValue: string | null;
    maxValue: string | null;
}

export const useHealthCareData = (token: string) => {
    const dispatch = useDispatch();
    const [viewMode, setViewMode] = useState<"data" | "app">("data");
    const [searchParams, setSearchParams] = useState<ISearchParams | null>(
        null
    );
    const [periodType, setPeriodType] = useState<"day" | "week" | "month">(
        "day"
    );
    const [displayType, setDisplayType] = useState<"record" | "summary">(
        "record"
    );
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isSearched, setIsSearched] = useState(false);
    const isInitialMount = useRef(true);

    useEffect(() => {
        // 初回マウント後にフラグを false に設定
        isInitialMount.current = false;
    }, []);

    const handleSearch = useCallback(
        (inputValue: ISearchParams, type) => {
            if (isInitialMount.current) {
                // 初回マウント時は検索を実行しない
                return;
            }

            setSearchParams(inputValue);
            setIsSearched(true);
            const dataTypes: ISearchTarget[] = ["歩数", "心拍", "体温", "睡眠"];
            const searchParams: HealthcareSearchParams = {
                user: inputValue.user,
                minDate: inputValue.dateRangeValue?.start || "",
                maxDate: inputValue.dateRangeValue?.end || "",
                minValue: "",
                maxValue: "",
            };
            dataTypes.forEach((dataType) => {
                dispatch(
                    thunkGetSearchData(token, dataType, searchParams, 1, type)
                );
            });
        },
        [dispatch, token]
    );

    const handleViewModeChange = useCallback((mode: "data" | "app") => {
        setViewMode(mode);
        if (mode === "app") {
            setPeriodType("day");
            setSelectedDate(new Date());
        }
    }, []);

    const handlePeriodTypeChange = useCallback(
        (type: "day" | "week" | "month") => {
            setPeriodType(type);
        },
        []
    );

    const handleDateChange = useCallback((date: Date) => {
        setSelectedDate(date);
    }, []);

    const handlePageChange = useCallback(
        (page: number, dataType: ISearchTarget) => {
            if (searchParams) {
                const params: HealthcareSearchParams = {
                    user: searchParams.user,
                    minDate: searchParams.dateRangeValue?.start || "",
                    maxDate: searchParams.dateRangeValue?.end || "",
                    minValue: "",
                    maxValue: "",
                };
                dispatch(
                    thunkGetSearchData(
                        token,
                        dataType,
                        params,
                        page,
                        displayType
                    )
                );
            }
        },
        [dispatch, token, searchParams, displayType]
    );

    return {
        viewMode,
        setViewMode,
        searchParams,
        setSearchParams,
        displayType,
        setDisplayType,
        handleSearch,
        handleViewModeChange,
        handlePeriodTypeChange,
        handleDateChange,
        handlePageChange,
        selectedDate,
        periodType,
        isSearched,
        setIsSearched,
    };
};
