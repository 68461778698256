// navigation models

export interface IActiveId {
  id: string;
}

export enum NavigationActionTypes {
  NAVIGATION_ACTIVE = "navigation/active"
}

export interface INavigationActiveAction {
  type: NavigationActionTypes.NAVIGATION_ACTIVE;
  payload: IActiveId;
}

// local storage
const id = localStorage.getItem("activeId")
  ? String(localStorage.getItem("activeId"))
  : 'home';
export const initialNavigationState: IActiveId = {
  id
};
