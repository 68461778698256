import * as React from "react";
import { Link } from "react-router-dom";
import { FaUserCircle, FaWallet, FaEdit } from "react-icons/fa";
import {
    Row,
    Column,
    FlexBox,
    IconButton,
    MailButton,
} from "@kortvaluta/admin-twooca-react";
import Profile from "../../profile/Profile";
import { IEmployee } from "../../../../store/employee/models";
import { numberWithCommas } from "../../../screens/history/helpers";
import { IEnrollmentParams } from "../../../../store/enrollment/models";
import "./styles.scss";

interface TitleTableProps {
    isLoading: boolean;
    data?: Array<IEmployee>;
    title?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    requestEnrollment: (params: IEnrollmentParams) => void;
}

const TitleTable: React.FC<TitleTableProps> = (props) => {
    const { data, isLoading, requestEnrollment } = props;
    return (
        <Row>
            {data.map((item: IEmployee, index: number) => (
                <Column key={index.toString()} sm={6} md={6} lg={6}>
                    <div className="title-item">
                        <div className="title-item__content">
                            {item.face_image.url ? (
                                <Profile
                                    image={`${item.face_image.url}`}
                                    description={item.last_name}
                                />
                            ) : (
                                <div className="title-item__icon">
                                    <FaUserCircle />
                                </div>
                            )}

                            <FlexBox
                                direction="column"
                                align="start"
                                justify="end"
                            >
                                <b>{item.employee_code}</b>
                                <Link
                                    to={`/employees/detail/${item.user_uid}`}
                                    className="title-employee-link"
                                >
                                    {`${item.last_name} ${item.first_name}`}
                                </Link>
                                <FlexBox
                                    direction="row"
                                    align="center"
                                    justify="between"
                                >
                                    <Link
                                        to={`/employees/transaction-history/${item.user_uid}`}
                                    >
                                        <IconButton
                                            icon={<FaWallet />}
                                            color="success"
                                            plain={true}
                                        />
                                    </Link>
                                    <Link
                                        to={`/employees/edit/${item.user_uid}`}
                                    >
                                        <IconButton
                                            icon={<FaEdit />}
                                            color="primary"
                                            plain={true}
                                        />
                                    </Link>

                                    <div>
                                        <small className="table-balance-label">
                                            残り残高
                                        </small>
                                        <span className="table-balance">
                                            {typeof item.perk_balance !==
                                                "undefined" &&
                                                numberWithCommas(
                                                    item.perk_balance
                                                )}
                                        </span>
                                        <small>pt</small>
                                    </div>
                                </FlexBox>
                                <FlexBox align="center" justify="end">
                                    <MailButton
                                        text={"メール送信"}
                                        onClick={() => {
                                            requestEnrollment({
                                                email: item.email,
                                                user_uid: item.user_uid,
                                            });
                                        }}
                                        disabled={isLoading || item.agreement}
                                    />
                                </FlexBox>
                            </FlexBox>
                        </div>
                    </div>
                </Column>
            ))}
        </Row>
    );
};

export default TitleTable;
