import * as React from "react";

interface IImageProps {
    src: string;
    size: number;
    alt: string;
}

const Image: React.VFC<IImageProps> = ({ src, size, alt }) => {
    return (
        <img
            src={src}
            alt={alt}
            style={{
                width: `${size}px`,
            }}
        />
    );
};

export default Image;
