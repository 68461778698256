import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Page, Panel } from "@kortvaluta/admin-twooca-react";
import { RootState } from "../../../../store";
import Content from "../../../shared/layout/content/Content";
import PageBackground from "../../../shared/page/PageBackground";
import { ButtonGroup, Button } from "../../../shared/button/Button";
import SearchContainer from "./component/SearchContainer";
import DataView from "./component/DataView";
import AppView from "./component/AppView";
import { useHealthCareData } from "../../../../hooks/useHealthCareData";
import { thunkGetSearchUsers } from "../../../../store/healthcareSearch/thunk";
import { ISearchParams } from "../../../../store/healthcareSearch/models";

const HealthCareGraphScreen: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const token = useSelector((store: RootState) => store.session.token);
    const sidebarToggle = useSelector((store: RootState) => store.sidebar.sidebarToggle);
    const userOptions = useSelector((store: RootState) => store.userOptions);
    const healthcareSearchData = useSelector((store: RootState) => store.healthcareSearch);
    const historicalData = useSelector((store: RootState) => store.historicalData);

    const {
        viewMode,
        searchParams,
        setSearchParams,
        handleViewModeChange,
        handlePeriodTypeChange,
        handleDateChange,
        handlePageChange,
        handleSearch,
        selectedDate,
        periodType,
        isSearched,
        displayType,
        setDisplayType,
    } = useHealthCareData(token);

    useEffect(() => {
        dispatch(thunkGetSearchUsers(token));
    }, [dispatch]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId');
        if (userId && userOptions.length > 0) {
            const user = userOptions.find(option => option.value === userId);
            if (user && (!searchParams || searchParams.user.value !== userId)) {
                const newSearchParams: ISearchParams = {
                    user,
                    dateRangeValue: {
                        start: "",
                        end: ""
                    }
                };
                setSearchParams(newSearchParams);
            }
        }
    }, [location, userOptions, searchParams, setSearchParams]);

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <ButtonGroup>
                            <Button onClick={() => handleViewModeChange("data")} active={viewMode === "data"}>データビュー</Button>
                            <Button onClick={() => handleViewModeChange("app")} active={viewMode === "app"}>アプリビュー</Button>
                        </ButtonGroup>

                        {viewMode === "data" ? (
                            <>
                                <SearchContainer
                                    setSearchParams={setSearchParams}
                                    handleSearch={handleSearch}
                                    userOptions={userOptions}
                                    initialUserId={searchParams?.user?.value}
                                    displayType={displayType}
                                    setDisplayType={setDisplayType}
                                />
                                <DataView
                                    searchParams={searchParams}
                                    healthcareSearchData={healthcareSearchData}
                                    isSearched={isSearched}
                                    onPageChange={handlePageChange}
                                    displayType={displayType}
                                />
                            </>
                        ) : (
                            <AppView
                                userOptions={userOptions}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                selectedDate={selectedDate}
                                periodType={periodType}
                                handlePeriodTypeChange={handlePeriodTypeChange}
                                handleDateChange={handleDateChange}
                                navigate={navigate}
                                historicalData={historicalData}
                                initialUserId={searchParams?.user?.value}
                            />
                        )}
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default HealthCareGraphScreen;
