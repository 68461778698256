import * as Models from "./models";

export function categorySummaryReducer(
	state = Models.initialCategorySummaryState,
	action: Models.ICategorySummarySetAction
): Models.ICategorySummaryResponse {
	switch (action.type) {
		case Models.CategorySummaryActionTypes.CATEGORY_SUMMARY_SET:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
