import * as React from "react";
import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/index";
import { sidebarToggle } from "../../store/sidebar/actions";
import { errorReset } from "../../store/error/actions";
import { navigationActive } from "../../store/navigation/actions";
import {
    Container,
    Hamburger,
    Navbar,
    Sidebar,
    SidebarItem,
} from "@kortvaluta/admin-twooca-react";
import { FaSignOutAlt, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { IAdminInfo } from "../../store/admin/models";
import { ICompanyServiceState } from "../../store/company/models";
import { thunkLogout } from "../../store/session/thunk";
import BrandLogo from "../shared/logo/BrandLogo";
import Dropdown from "../shared/dropdown/Dropdown";
import { navItems, INavItem } from "./navItems";
import "./styles.scss";

interface NavigationProps {
    activeId: string;
    adminInfo: IAdminInfo;
    status: boolean;
    toggle: boolean;
    token: string;
    logout: (token: string) => void;
    handleResetError: () => void;
    handleSidebarToggle: (toggle: boolean) => void;
    setActiveId: (id: string) => void;
}

const Navigation: React.FC<NavigationProps> = (props) => {
    const {
        activeId,
        adminInfo,
        status,
        toggle,
        token,
        handleSidebarToggle,
        handleResetError,
        logout,
        setActiveId,
    } = props;

    const services: ICompanyServiceState = useSelector(
        (store: RootState) => store.services
    );

    const location = useLocation();
    const navigate = useNavigate();
    const [shouldRender, setShouldRender] = useState(true);
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    useEffect(() => {
        if (
            location.pathname.includes("login") ||
            location.pathname.includes("password") ||
            location.pathname.includes("punch")
        ) {
            setShouldRender(false);
        } else {
            setShouldRender(true);
        }
    }, [location]);

    const handleRouteChange = (item: INavItem) => {
        if (item.children && item.children.length > 0) {
            setExpandedItems(prev =>
                prev.includes(item.id)
                    ? prev.filter(id => id !== item.id)
                    : [...prev, item.id]
            );
        } else {
            setActiveId(item.id);
            navigate(item.route);
        }
    };

    const handleLogout = () => {
        logout(token);
    };

    const isValidService = (item: INavItem) => {
        if (item.service == undefined) {
            return true;
        }
        return services?.list.length && services.list.some((service) => service.name === item.service);
    }

    const renderNavItems = (items: INavItem[], level = 0) => {
        return items.map((item) => {
            if (!isValidService(item)) return null;

            const isExpanded = expandedItems.includes(item.id);
            const hasChildren = item.children && item.children.length > 0;

            return (
                <div key={item.id} className="sidebar-item-container">
                    <div
                        className="sidebar-item-wrapper" 
                        style={{ paddingLeft: `${level * 20}px` }}
                        onClick={() => handleRouteChange(item)}
                    >
                        <SidebarItem
                            isActive={activeId === item.id}
                            id={item.id}
                            color={item.color}
                            icon={item.icon}
                            text={item.text}
                        />
                        {hasChildren && (
                            <span className="sidebar-item-arrow">
                                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                            </span>
                        )}
                    </div>
                    {hasChildren && isExpanded && (
                        <div className="sidebar-item-children">
                            {renderNavItems(item.children, level + 1)}
                        </div>
                    )}
                </div>
            );
        });
    };

    if (!status || !shouldRender) return null;

    return (
        <Fragment>
            <Navbar top={true}>
                <Container fullWidth>
                    <div className="wrapper__navbar">
                        <div className="wrapper__navbar__hamburger">
                            <Hamburger
                                toggle={toggle}
                                onClick={() => {
                                    handleSidebarToggle(!toggle);
                                }}
                            />
                        </div>
                        <div className="wrapper__navbar__logo">
                            <BrandLogo
                                isWhite={false}
                                styles="wrapper__navbar__brand"
                            />
                        </div>
                        <div className="wrapper__navbar__logout">
                            <Dropdown
                                btnText={adminInfo.company_name}
                                items={[
                                    {
                                        icon: <FaSignOutAlt />,
                                        text: "ログアウト",
                                        action: handleLogout,
                                    },
                                ]}
                                name={adminInfo.company_admin_name}
                                email={adminInfo.company_admin_email}
                                resetError={handleResetError}
                            />
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Sidebar toggle={toggle}>
                {renderNavItems(navItems)}
            </Sidebar>
        </Fragment>
    );
};

const MapStateToProps = (store: RootState) => {
    return {
        activeId: store.navigation.id,
        adminInfo: store.adminInfo,
        status: store.session.status,
        toggle: store.sidebar.sidebarToggle,
        token: store.session.token,
    };
};

const MapDispatchToProps = (
    dispatch: ThunkDispatch<RootState, null, AnyAction>
) => {
    return {
        logout: (token: string) => {
            dispatch(thunkLogout(token));
        },
        handleSidebarToggle: (toggle: boolean) => {
            dispatch(sidebarToggle(toggle));
        },
        handleResetError: () => {
            dispatch(errorReset());
        },
        setActiveId: (id: string) => {
            dispatch(navigationActive({ id }));
        },
    };
};

export default connect(MapStateToProps, MapDispatchToProps)(Navigation);
