import * as Models from "./models";

export function searchDataSet(
    reslut: Models.ISearchResultState
): Models.ISearchResultSetAction {
    return {
        type: Models.SearchActionTypes.SET_SEARCH_DATA,
        payload: reslut,
    }
}

export function searchUserOptionsSet(
    data: Models.SearchUserOptionsState
): Models.SearchUserOptionSetAction {
    return {
        type: Models.SearchUserOptionActionTypes.SET_SEARCH_USER_OPTIONS,
        payload: data,
    }
}

export function historicalDataSet(
    data: Models.HistoricalDataState
): Models.HistoricalDataSetAction {
    return {
        type: Models.HistoricalDataSetActionTypes.SET_HISTORICAL_DATA,
        payload: data
    }
}
