export enum CompanyServiceActionTypes {
    COMPANY_SERVICE_SET = "COMPANY_SERVICE_SET",
}

export interface ICompanyService {
    name: string,
}

export interface ICompanyServiceState {
    list: Array<ICompanyService>
}

export interface ICompanyServiceAction {
    type: CompanyServiceActionTypes.COMPANY_SERVICE_SET;
    payload: ICompanyServiceState;
}

const services: string = localStorage.getItem("services")
    ? localStorage.getItem("services")
    : "[]";

export const initialCompanyServiceState: ICompanyServiceState = {
    list: JSON.parse(services),
};
