import React, { useEffect, useState } from "react";
import { Title } from "@kortvaluta/admin-twooca-react";
import {
    ISearchTarget,
    SleepSession,
} from "../../../../../store/healthcareSearch/models";
import {
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import Paginate from "../../../../shared/pagination/Paginate";

interface DataGraphProps {
    data: any[];
    showDataType: ISearchTarget;
    totalPages: number;
    currentPage: number;
    dateRange: { start: string; end: string } | null;
    onPageChange: (page: number) => void;
    isSearched: boolean;
    displayType: "record" | "summary";
}

const DataGraph: React.FC<DataGraphProps> = ({
    data,
    showDataType,
    totalPages,
    currentPage,
    onPageChange,
    isSearched,
    displayType,
}) => {
    const [formattedData, setFormattedData] = useState<any[]>([]);
    const [stats, setStats] = useState<{
        min: number | string;
        max: number | string;
        avg: number | string;
    }>({ min: 0, max: 0, avg: 0 });

    useEffect(() => {
        if (isSearched && data && data.length > 0) {
            const formatted = formatDataForGraph(data, showDataType);
            setFormattedData(formatted.reverse());
            setStats(calculateStats(formatted, showDataType));
        }
    }, [data, showDataType, isSearched]);

    const formatDataForGraph = (inputData: any[], dataType: ISearchTarget) => {
        if (dataType === "睡眠" && displayType === "record") {
            return processSleepData(inputData);
        } else if (dataType === "睡眠") {
            return inputData.map((item) => ({
                name: new Date(item.date).toLocaleDateString(),
                value: item.total_sleep_time,
                displayValue: formatMinutesToTime(item.total_sleep_time),
            }));
        } else {
            return inputData.map((item) => ({
                name: new Date(item.date).toLocaleDateString(),
                value: item.total || parseFloat(item.avg || item.value),
                min: item.min,
                max: item.max,
            }));
        }
    };

    const formatMinutesToTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours}時間${mins.toString().padStart(2, "0")}分`;
    };

    const calculateStats = (formattedData: any[], dataType: ISearchTarget) => {
        if (formattedData.length === 0) return { min: "-", max: "-", avg: "-" };
        const values = formattedData.map((item) => item.value);
        if (dataType === "睡眠") {
            const min = Math.min(...values);
            const max = Math.max(...values);
            const avg = Math.round(
                values.reduce((a, b) => a + b, 0) / values.length
            );
            return {
                min: formatMinutesToTime(min),
                max: formatMinutesToTime(max),
                avg: formatMinutesToTime(avg),
            };
        } else {
            const min = Math.min(...values)
            const max = Math.max(...values)
            return {
                min: dataType === '歩数' ? min : min.toFixed(2),
                max: dataType === '歩数' ? max : max.toFixed(2),
                avg: Number(
                    (values.reduce((a, b) => a + b, 0) / values.length).toFixed(
                        2
                    )
                ),
            };
        }
    };

    const formatYAxis = (value: number) => {
        return formatMinutesToTime(value);
    };

    const formatSleepYAxis = (value) => {
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        return `${hours}時間${minutes.toString().padStart(2, "0")}分`;
    };

    const processSleepData = (sleepData: SleepSession[]) => {
        return sleepData.map((record, id) => {
            const date = new Date(record.date);
            const startTime = new Date(record.sleep_start);
            const displayName = (record) => {
                if (record.light_sleep > 0) {
                    return "浅い";
                } else if (record.deep_sleep > 0) {
                    return "深い";
                } else {
                    return "覚醒";
                }
            };
            return {
                id: id,
                date: date.toLocaleDateString(),
                startTime: startTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
                lightSleep: record.light_sleep,
                deepSleep: record.deep_sleep,
                awake: record.clear_time,
                totalSleep: record.sleep_duration,
                displayDate: `${date.toLocaleDateString()} ${startTime.toLocaleTimeString(
                    [],
                    { hour: "2-digit", minute: "2-digit" }
                )}`,
                displayName: displayName(record),
            };
        });
    };

    const renderGraph = () => {
        if (formattedData.length === 0) {
            return null;
        }

        switch (showDataType) {
            case "睡眠":
                if (displayType === "record") {
                    return (
                        <BarChart data={formattedData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="displayDate"
                                angle={-50}
                                textAnchor="end"
                                height={100}
                                interval={0}
                                tick={{ fontSize: 10 }}
                            />
                            <YAxis
                                tickFormatter={formatYAxis}
                                label={{
                                    angle: -90,
                                    position: "insideLeft",
                                    style: {
                                        fontSize: 12,
                                        textAnchor: "middle",
                                    },
                                }}
                                ticks={[
                                    0, 60, 120, 180, 240, 300, 360, 420, 480,
                                ]}
                                tick={{ fontSize: 10 }}
                            />
                            <Tooltip
                                formatter={(value, displayName) => {
                                    return [
                                        formatSleepYAxis(value),
                                        displayName,
                                    ];
                                }}
                            />
                            <Legend />
                            <Bar
                                dataKey="lightSleep"
                                stackId="a"
                                fill="#82ca9d"
                                name="浅い"
                                maxBarSize={50}
                            />
                            <Bar
                                dataKey="deepSleep"
                                stackId="a"
                                fill="#8884d8"
                                name="深い"
                                maxBarSize={50}
                            />
                            <Bar
                                dataKey="awake"
                                stackId="a"
                                fill="#ffc658"
                                name="覚醒"
                                maxBarSize={50}
                            />
                        </BarChart>
                    );
                }
                return (
                    <BarChart data={formattedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                        <YAxis
                            tickFormatter={formatYAxis}
                            domain={[0, "dataMax + 60"]}
                            tick={{ fontSize: 10 }}
                        />
                        <Tooltip
                            formatter={(value: number) => [
                                formatMinutesToTime(value),
                                "睡眠時間",
                            ]}
                            labelFormatter={(label) => `日付: ${label}`}
                        />
                        <Legend />
                        <Bar dataKey="value" name="睡眠時間" fill="#8884d8" />
                    </BarChart>
                );
            case "歩数":
                return (
                    <BarChart data={formattedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" name="歩数" fill="#82ca9d" />
                    </BarChart>
                );
            case "心拍":
            case "体温":
            default:
                return (
                    <LineChart data={formattedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={["auto", "auto"]} />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="value"
                            name="平均"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                        <Line
                            type="monotone"
                            dataKey="min"
                            name="最小"
                            stroke="#82ca9d"
                        />
                        <Line
                            type="monotone"
                            dataKey="max"
                            name="最大"
                            stroke="#ffc658"
                        />
                    </LineChart>
                );
        }
    };

    if (!isSearched) {
        return null;
    }

    if (isSearched && (!data || data.length === 0)) {
        return <div>{showDataType}のデータがありません。</div>;
    }

    const renderStats = () => {
        if (showDataType === "睡眠") {
            if (displayType !== "record") {
                return (
                    <>
                        <p>期間中の最短睡眠時間: {stats.min}</p>
                        <p>期間中の最長睡眠時間: {stats.max}</p>
                        <p>期間中の平均睡眠時間: {stats.avg}</p>
                    </>
                );
            }
        } else {
            return (
                <>
                    <p>期間中の最小値: {stats.min}</p>
                    <p>期間中の最大値: {stats.max}</p>
                    <p>期間中の平均値: {stats.avg}</p>
                </>
            );
        }
    };

    return (
        <div style={{ marginBottom: "20px" }}>
            <Title title={`${showDataType}グラフ`} />
            {renderStats()}
            <ResponsiveContainer width="100%" height={300}>
                {renderGraph()}
            </ResponsiveContainer>
            <Paginate
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
        </div>
    );
};

export default DataGraph;
