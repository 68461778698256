import * as React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import {
    MailButton,
    Table,
    TableHead,
    TableBody,
    Td,
    Tr,
    Th,
    IconButton,
} from "@kortvaluta/admin-twooca-react";
import { IEmployee } from "../../../../store/employee/models";
import { FaWallet, FaEdit } from "react-icons/fa";
import { checkStatus, checkRetired } from "./helpers";
import { numberWithCommas } from "../../../screens/history/helpers";
import { IEnrollmentParams } from "../../../../store/enrollment/models";
import "./styles.scss";

interface ListTableProps {
    isLoading: boolean;
    data?: Array<IEmployee>;
    title?: string;
    sorted?: boolean;
    onSort?: (toggle: boolean) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    requestEnrollment: (params: IEnrollmentParams) => void;
}

const ListTable: React.FC<ListTableProps> = (props) => {
    const { data, isLoading, requestEnrollment, onSort, sorted } = props;
    const [toggle, setToggle] = useState(false);
    const [tableData, setTableData] = useState<Array<IEmployee>>([]);

    const isButtonActive = (agreement: boolean, status: string) => {
        if (isLoading) return true;
        if (status === "retired") return true;
        if (agreement === true) return true;
        return false;
    };

    useEffect(() => {
        setTableData(data);
    }, [data]);

    useEffect(() => {
        setToggle(sorted)
    }, [sorted])

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <Tr>
                        <Th
                            sortable={true}
                            onSort={() => {
                                onSort(toggle);
                            }}
                            toggle={toggle}
                        >
                            社員番号
                        </Th>
                        <Th>氏名</Th>
                        <Th>フリガナ</Th>
                        <Th>状態</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th>反社 C/K 完了</Th>
                        <Th>残り残高</Th>
                    </Tr>
                </TableHead>
                <TableBody>
                    {tableData.map((item: IEmployee, index: number) => (
                        <Tr key={index.toString()}>
                            <Td>{item.employee_code}</Td>
                            <Td>
                                <Link
                                    to={`/employees/detail/${item.user_uid}`}
                                    className="list-employee-link"
                                    aria-label="employee detail link"
                                >{`${item.last_name} ${item.first_name}`}</Link>
                            </Td>
                            <Td>{`${
                                item.last_name_furi === null
                                    ? ""
                                    : item.last_name_furi
                            } ${
                                item.first_name_furi === null
                                    ? ""
                                    : item.first_name_furi
                            }`}</Td>
                            <Td>
                                {checkRetired(item.retired_at) ? (
                                    <span className={`status status--retired`}>
                                        退職
                                    </span>
                                ) : (
                                    <span
                                        className={`status status--${item.status}`}
                                    >
                                        {checkStatus(item.status)}
                                    </span>
                                )}
                            </Td>
                            <Td>
                                <div className="table-cell">
                                    <Link
                                        to={`/employees/transaction-history/${item.user_uid}`}
                                        aria-label="employee transaction history"
                                    >
                                        <IconButton
                                            icon={<FaWallet />}
                                            color="success"
                                            plain={true}
                                        />
                                    </Link>
                                </div>
                            </Td>
                            <Td>
                                <div className="table-cell table-cell--edit">
                                    <Link
                                        to={`/employees/edit/${item.user_uid}`}
                                        aria-label="employee edit"
                                    >
                                        <IconButton
                                            icon={<FaEdit />}
                                            color="primary"
                                            plain={true}
                                        />
                                    </Link>
                                </div>
                            </Td>
                            <Td>
                                <div className="list-mail-btn">
                                    <MailButton
                                        text={"メール送信"}
                                        onClick={() => {
                                            requestEnrollment({
                                                email: item.email,
                                                user_uid: item.user_uid,
                                            });
                                        }}
                                        disabled={isButtonActive(
                                            item.agreement,
                                            item.status
                                        )}
                                    />
                                </div>
                            </Td>
                            <Td>
                                {typeof item.perk_balance !== "undefined" && (
                                    <Fragment>
                                        <span className="table-balance">
                                            {numberWithCommas(
                                                item.perk_balance
                                            )}
                                        </span>
                                        <small>pt</small>
                                    </Fragment>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    );
};

export default ListTable;
