export enum ContactActionTypes {
    CONTACT_SUCCESS_SET = "CONTACT_SUCCESS_SET",
    CONTACT_MESSAGE_SET = "CONTACT_MESSAGE_SET",
}

export interface IContactParams {
    message: string;
    company_department: string;
    name_furi: string;
    name: string;
    email: string;
    phone: string;
}

export interface IContactSuccessSetAction {
    type: ContactActionTypes.CONTACT_SUCCESS_SET;
    payload: {
        success: boolean;
    };
}

export interface IContactMessageSetAction {
    type: ContactActionTypes.CONTACT_MESSAGE_SET;
    payload: {
        message: string;
    };
}

export interface IContactState {
    success: boolean;
    message: string;
}

export const initialContactState: IContactState = {
    success: false,
    message: "",
};
