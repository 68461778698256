import {
    ActionButton,
    FileInput,
    FlexBox,
    Label,
    Page,
    Panel,
    Table,
    TableBody,
    Td,
    Title,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import useImage from "../../../hooks/useImage";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import { ICompanyService } from "../../../store/company/models";
import { IEmployeeUpdate } from "../../../store/employee/models";
import {
    thunkDeleteEmployee,
    thunkGetEmployee,
    thunkUpdateFaceImage,
} from "../../../store/employee/thunk";
import SpaceBox from "../../shared/box/space/SpaceBox";
import Content from "../../shared/layout/content/Content";
import Modal from "../../shared/modal/confirm/Modal";
import PageBackground from "../../shared/page/PageBackground";
import Profile from "../../shared/profile/Profile";

const EmployeeDetailScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const success: boolean = useSelector(
        (store: RootState) => store.employeesInfo.success
    );
    const employee: IEmployeeUpdate = useSelector(
        (store: RootState) => store.employeesInfo.employee
    );
    const services: ICompanyService[] = useSelector(
        (store: RootState) => store.services.list
    );
    const showStaticQRcode = services && services.some(s => s.name === 'QR');

    const location = useLocation();
    const navigate = useNavigate();
    const uid = location.pathname.substring(
        location.pathname.lastIndexOf("detail/") + 7
    );

    const qrRef = useRef(null);

    const qrSize = 200;
    const borderSize = 5;
    const borderColor = '#000';

    //local state
    const [modalToggle, setModalToggle] = useState(false);
    const [image, formData, fileLimit, onChangeImage, setInitImage] =
        useImage();

    //dispatch
    const dispatch = useDispatch();

    //GET employee
    const handleGetEmployee = () => {
        dispatch(thunkGetEmployee(token, uid));
    };

    //DELETE employee
    const handleDeleteEmployee = () => {
        dispatch(thunkDeleteEmployee(token, uid));
    };

    //PUT Image
    const handleUploadImage = () => {
        dispatch(thunkUpdateFaceImage(token, uid, formData));
    };

    const handleDownloadQRcode = () => {
        const qrCanvas = qrRef.current.querySelector('canvas');
        if (qrCanvas) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const totalSize = qrSize + borderSize * 2;

            canvas.width = totalSize;
            canvas.height = totalSize;

            // ボーダーを描画
            ctx.fillStyle = borderColor;
            ctx.fillRect(0, 0, totalSize, totalSize);

            // QRコードを描画
            ctx.drawImage(qrCanvas, borderSize, borderSize, qrSize, qrSize);

            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${employee.employee_code}_qrcode.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    //useEffect
    useEffect(() => {
        handleGetEmployee();
    }, []);

    useEffect(() => {
        if (success) {
            navigate(`/employees/list`);
        }
    }, [success]);

    //set active id
    useSetActiveId('employees');

    //set image
    setInitImage(employee);

    //method
    const handleToggleModal = () => {
        setModalToggle((prev) => !prev);
    };

    const getEmployeeStateus = (status: string): string => {
        switch (status) {
            case "paid_leave":
                return "休職";
            case "hired":
                return "在職";
            case "retired":
                return "退職";
            default:
                return "";
        }
    };

    const getEncodedCode = (code: string): string => {
        return window.btoa(code);
    }

    if (!employee) return null;
    return (
        <>
            <Modal
                open={modalToggle}
                toggleModal={handleToggleModal}
                submit={handleDeleteEmployee}
            />
            <PageBackground>
                <Page toggle={sidebarToggle}>
                    <Content>
                        <Panel>
                            <Title title="社員情報" />
                            <FlexBox justify="end">
                                <SpaceBox mr="md">
                                    <ActionButton
                                        text="前の画面に戻る"
                                        onClick={() => {
                                            navigate("/employees/list");
                                        }}
                                        submit={false}
                                    />
                                </SpaceBox>
                                <ActionButton
                                    text="削除"
                                    color="danger"
                                    onClick={handleToggleModal}
                                />
                            </FlexBox>
                            <Table>
                                <TableBody>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="社員番号"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>{employee.employee_code}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="氏名" bold={true} />
                                        </Td>
                                        <Td>
                                            {`${employee.last_name} ${employee.first_name}`}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="氏名(フリガナ)"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>
                                            {`${employee.last_name_furi} ${employee.first_name_furi}`}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="氏名(英)"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>
                                            {`${employee.last_name_en} ${employee.first_name_en}`}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="メールアドレス"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>{employee.email}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="性別" bold={true} />
                                        </Td>
                                        <Td>
                                            {employee.gender === "male"
                                                ? "男性"
                                                : "女性"}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="生年月日"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>{employee.birthday}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="郵便番号"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>{employee.postcode}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="住所 (市区町村番地)" bold={true} />
                                        </Td>
                                        <Td>{employee.address}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="住所 (ビル名・部屋番号)" bold={true} />
                                        </Td>
                                        <Td>{employee.address2}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="TEL" bold={true} />
                                        </Td>
                                        <Td>{employee.tel}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label text="顔写真" bold={true} />
                                        </Td>
                                        <Td>
                                            <FlexBox justify="center">
                                                <Profile
                                                    image={image}
                                                    description={
                                                        employee.last_name
                                                    }
                                                />
                                            </FlexBox>
                                            <SpaceBox my="md">
                                                <FileInput
                                                    text="ファイルを選択"
                                                    onChange={onChangeImage}
                                                />
                                                {fileLimit && (
                                                    <span className="employee-detail__item__limit">
                                                        ファイルサイズは200kb以下にしてください
                                                    </span>
                                                )}
                                            </SpaceBox>
                                            <FlexBox justify="center">
                                                <ActionButton
                                                    text="アップロード"
                                                    color="primary"
                                                    onClick={handleUploadImage}
                                                    disabled={Boolean(!image)}
                                                />
                                            </FlexBox>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="雇用状態"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>
                                            {getEmployeeStateus(
                                                employee.status
                                            )}
                                            <span>
                                                {employee.retired_at &&
                                                    ` (${employee.retired_at})`}
                                            </span>
                                        </Td>
                                    </Tr>
                                    {showStaticQRcode && (
                                    <Tr>
                                        <Td>
                                            <Label
                                                text="QRコード"
                                                bold={true}
                                            />
                                        </Td>
                                        <Td>
                                            <FlexBox justify="center" direction="column" align="center">
                                                <div ref={qrRef}>
                                                    <QRCodeCanvas
                                                        value={getEncodedCode(employee.user_uid)}
                                                        size={qrSize}
                                                        style={{ border: `${borderSize}px solid ${borderColor}` }}
                                                        includeMargin
                                                    />
                                                </div>
                                                <ActionButton
                                                    text="QRコードをダウンロードする"
                                                    color="primary"
                                                    onClick={handleDownloadQRcode}
                                                />
                                            </FlexBox>
                                        </Td>
                                    </Tr>
                                    )}
                                </TableBody>
                            </Table>
                        </Panel>
                    </Content>
                </Page>
            </PageBackground>
        </>
    );
};

export default EmployeeDetailScreen;
