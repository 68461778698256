export interface ICurrentPage {
    currentPage: number;
}

export enum PageActionTypes {
    SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
}
export interface ISetCurrentPageAction {
    type: PageActionTypes.SET_CURRENT_PAGE;
    payload: ICurrentPage;
}

// local storage
const currentPage = sessionStorage.getItem("current_page")
    ? sessionStorage.getItem("current_page")
    : 1;

export const initialCurrentPageState: ICurrentPage = {
    currentPage: Number(currentPage),
};
