import * as React from "react";
import { FaUserCircle } from "react-icons/fa";
import "./styles.scss";

interface ProfileProps {
    image: string;
    description: string;
}

const Profile: React.FC<ProfileProps> = (props) => {
    const { image } = props;
    return (
        <>
            {image ? (
                <div
                    style={{
                        backgroundImage: `url("${image}")`,
                    }}
                    className="profile"
                />
            ) : (
                <div className="profile__icon">
                    <FaUserCircle />
                </div>
            )}
        </>
    );
};

export default Profile;
