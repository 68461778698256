import axios from "axios";
import { healthDataUrl } from "../../constants";
import {
    ISearchTarget,
    ISearchResultState,
    SearchTarget,
    SearchUserOption,
} from "./models";

const getSearchData = async (
    token: string,
    target: ISearchTarget,
    params: {
        user: SearchUserOption;
        minDate: string;
        maxDate: string;
        minValue: string;
        maxValue: string;
    },
    page: number,
    viewMode?: "record" | "summary"
): Promise<{ token: string; data: ISearchResultState }> => {
    try {
        const searchTarget = SearchTarget[target];
        const res = await axios.get(`${healthDataUrl}/search`, {
            headers: { Authorization: `${token}` },
            params: {
                user: params.user,
                searchTarget,
                params,
                page,
                viewMode,
            },
        });
        return {
            token: res.headers.authorization,
            data: res.data.result,
        };
    } catch (e) {
        throw e;
    }
};

export default getSearchData;
