import { useSelector } from "react-redux";
import { RootState } from ".";
import { IAnnouncement } from "./announcements/models";

//create global state function so that we can reuse in each file.
//function name => get + global state name

export function getToken(): string {
    return useSelector((store: RootState) => store.session.token);
}

export function getSidebarToggle(): boolean {
    return useSelector((store: RootState) => store.sidebar.sidebarToggle);
}

export function getAnnouncement(): IAnnouncement {
    return useSelector((store: RootState) => store.announcements.item);
}
