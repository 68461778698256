import axios from "axios";
import { IAdminInfoResponse } from "./models";

async function getAdminUserInfo(token: string): Promise<IAdminInfoResponse> {
    // GET /api/v1/company/company_admin_users/user_infos
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/company_admin_users/user_infos`,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return {
            data: res.data,
            token: res.headers.authorization,
        };
    } catch (e) {
        throw e;
    }
}

export default getAdminUserInfo;
