import React, { useEffect, useState } from "react";
import { FlexBox, Label } from "@kortvaluta/admin-twooca-react";
import { toDate } from "../../../../../utils/dateUtils";
import { SearchUserOption } from "../../../../../store/healthcareSearch/models";
import ActionBtn from "../../../../shared/actionButton/ActionBtn";
import SearchDateInput from "../../../../shared/searchContainer/searchDateInput/SearchDateInput";
import SearchSelect from "../../../../shared/searchContainer/searchSelect";
import ErrorText from "../../../../shared/error/text/ErrorText";
import { useNavigate } from "react-router-dom";
import SelectPicker from "../../../../shared/selectPicker";

interface SearchContainerProps {
    userOptions: SearchUserOption[];
    setSearchParams: (params: any) => void;
    setDisplayType: (params: any) => void;
    handleSearch: (params: any, type: any) => void;
    initialUserId?: string;
    displayType?: any;
}

const SearchContainer: React.FC<SearchContainerProps> = ({
    userOptions,
    setSearchParams,
    handleSearch,
    setDisplayType,
    initialUserId,
    displayType,
}) => {
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState<{ start: string; end: string }>({
        start: "",
        end: "",
    });
    const [localUser, setLocalUser] = useState(initialUserId || "");
    const [localDisplayType, setLocalDisplayType] = useState(
        displayType || "record"
    );
    const [error, setError] = useState<string | null>(null);

    const handleDateData = (value: [Date | null, Date | null]) => {
        if (value[0] === null || value[1] === null) {
            setDateRange({ start: "", end: "" });
        } else {
            const startDate = toDate(value[0]);
            const endDate = toDate(value[1]);
            setDateRange({
                start: startDate,
                end: endDate,
            });
        }
    };

    useEffect(() => {
        if (initialUserId) {
            setLocalUser(initialUserId);
        }
    }, [initialUserId]);

    const handleUserChange = (selectedUser: SearchUserOption) => {
        setLocalUser(selectedUser.value);
    };

    const onSearchClick = () => {
        const user = userOptions.find((option) => option.value === localUser);
        if (!user) {
            setError("対象者を選択してください。");
            return;
        }

        setError(null);

        const searchParams = {
            user,
            dateRangeValue: dateRange,
        };
        navigate(`?userId=${localUser}`);
        setSearchParams(searchParams);
        setDisplayType(localDisplayType);
        handleSearch(searchParams, localDisplayType);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    margin: "20px 0",
                }}
            >
                <div style={{ flex: "1 1 200px" }}>
                    <SearchDateInput
                        isDateRange={true}
                        setDateRange={handleDateData}
                    />
                </div>
                <div style={{ flex: "1 1 200px" }}>
                    <Label text="表示データ形式" />
                    <SelectPicker
                        data={[
                            { label: "集計前", value: "record" },
                            { label: "集計後", value: "summary" },
                        ]}
                        value={localDisplayType}
                        onChange={setLocalDisplayType}
                        placeholder="表示方法を選択してください"
                    />
                </div>
                <div style={{ flex: "2 1 300px" }}>
                    <SearchSelect
                        options={userOptions}
                        label="氏名"
                        placeholder="氏名を選択してください"
                        onChange={handleUserChange}
                        selected={initialUserId}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                }}
            >
                <FlexBox justify="end">
                    <ActionBtn
                        text="検索する"
                        onClick={onSearchClick}
                        submit
                        color="primary"
                    />
                </FlexBox>
            </div>
            {error && (
                <div style={{ marginBottom: "20px" }}>
                    <ErrorText text={error} />
                </div>
            )}
        </>
    );
};

export default SearchContainer;
