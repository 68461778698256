import axios from "axios";
import { employeesUrl } from "../../constants";
import { IEmployeeUpdate } from "./models";

const getEmployee = async (
	token: string,
	uid: string
): Promise<{ token: string; data: IEmployeeUpdate }> => {
	try {
		const res = await axios.get(`${employeesUrl}/${uid}`, {
			headers: { Authorization: `${token}` },
		});
		return {
			token: res.headers.authorization,
			data: res.data,
		};
	} catch (e) {
		throw e;
	}
};

export default getEmployee;
