import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import {
    errorReset,
    errorSet,
    uploadErrorReset,
    uploadErrorSet,
} from "../error/actions";
import { RootState } from "../index";
import { sessionStatus } from "../session/actions";
import {
    employeeExampleCsvSet,
    employeeExampleZipSet,
    employeeInit,
    employeeSet,
    employeesSet,
    employeeSuccess,
} from "./actions";
import deleteEmployee from "./deleteEmployee";
import getEmployee from "./getEmployee";
import getEmployeeExampleCsv from "./getEmployeeExampleCsv";
import getEmployeeExampleZip from "./getEmployeeExampleZip";
import getEmployees from "./getEmployees";
import { IEmployeeParams, IEmployeesSearchParams } from "./models";
import updateEmployee from "./updateEmployee";
import updateEmployees from "./updateEmployees";
import updateFaceImage from "./updateFaceImage";

// employees get
// @param token: string
export const thunkGetEmployees =
    (
        token: string,
        page = 1,
        searchParams: IEmployeesSearchParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        //set csv and zip
        dispatch(employeeExampleCsvSet({ csv_file: null }));
        dispatch(employeeExampleZipSet({ zip_file: null }));
        dispatch(uploadErrorReset());
        try {
            const res = await getEmployees(token, page, searchParams);
            dispatch(employeesSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
            dispatch(thunkGetExampleCsv(res.token));
            dispatch(thunkGetExampleZip(res.token));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// employee get
// @param token: string
// @param uid: string
export const thunkGetEmployee =
    (
        token: string,
        uid: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeInit(false));
        try {
            const res = await getEmployee(token, uid);
            dispatch(employeeSet({ employee: res.data }));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
            dispatch(employeeInit(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeInit(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// employee create
// @param token: string
// @param body: IEmployeeParams
export const thunkCreateEmployee =
    (
        token: string,
        body: IEmployeeParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));

        try {
            const res = await updateEmployee(token, body, false, "");
            dispatch(employeeSet({ employee: res.data }));
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// employee delete
// @param token: string
// @param body: IEmployeeParams
// @param uid: string
// @formData: FormData
export const thunkUpdateEmployee =
    (
        token: string,
        body: IEmployeeParams,
        uid: string,
        formData?: FormData
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));

        try {
            if (formData) {
                await updateFaceImage(token, formData, uid);
            }
            const res = await updateEmployee(token, body, true, uid);
            dispatch(employeeSet({ employee: res.data }));
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// employee delete
// @param token: string
export const thunkDeleteEmployee =
    (
        token: string,
        uid: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));
        try {
            const res = await deleteEmployee(token, uid);
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// update employee face_image
// @param token: string
// @param uid: token
// @formData: FormData
export const thunkUpdateFaceImage =
    (
        token: string,
        uid: string,
        formData: FormData
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));
        try {
            const res = await updateFaceImage(token, formData, uid);
            dispatch(employeeSet({ employee: res.data }));
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// employee create
// @param token: string
// @param formData: formData
export const thunkCreateEmployees =
    (
        token: string,
        formData: FormData
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));
        dispatch(uploadErrorReset());
        try {
            await updateEmployees(token, formData);
            const res = await getEmployees(token, 1, null);
            dispatch(employeesSet(res.data));
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            const errorRes = {
                hasError: true,
                errorType: e.response.data.error.type,
                errorMessage: "アップロードエラー",
                errorMessageArr: e.response.data.error.message,
            };
            dispatch(uploadErrorSet(errorRes));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

export const thunkGetExampleCsv =
    (token: string): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(employeeExampleCsvSet({ csv_file: null }));
        // call API
        const csvRes = await getEmployeeExampleCsv(token);
        // set data;
        dispatch(employeeExampleCsvSet({ csv_file: csvRes }));
    };

export const thunkGetExampleZip =
    (token: string): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(employeeExampleZipSet({ zip_file: null }));
        // call API
        const zipRes = await getEmployeeExampleZip(token);
        dispatch(employeeExampleZipSet({ zip_file: zipRes }));
    };
