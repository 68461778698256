import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { FaUserCircle } from "react-icons/fa";

interface IItem {
    icon?: React.ReactElement;
    text?: string;
    action?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface DropdownProps {
    items?: Array<IItem>;
    btnText?: string;
    name?: string;
    email?: string;
    resetError?: () => void;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
    const { items, btnText, resetError, name, email } = props;
    const [open, setOpen] = useState(false);
    const node = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (node.current.contains(event.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);
    if (typeof items === "undefined") return null;
    return (
        <div className="dropdown" ref={node}>
            <button
                className="dropdown__btn"
                onClick={() => {
                    setOpen(!open);
                    resetError();
                }}
            >
                {btnText ? btnText : "ユーザーオプション"}
                <span className="caret" />
            </button>
            <ul
                className={`dropdown__menu ${
                    open ? "dropdown__menu--open" : ""
                }`}
            >
                {open && (
                    <div className="header">
                        <div className="header__icon">
                            <FaUserCircle />
                        </div>
                        <div className="header__content">
                            <div className="header__content__name">{name}</div>
                            <div className="header__content__email">
                                {email}
                            </div>
                        </div>
                    </div>
                )}
                {items.map((item, index) => {
                    return (
                        <li
                            className="dropdown__menu__item"
                            key={index.toString()}
                        >
                            <div
                                className="dropdown__menu__item__btn"
                                onClick={item.action}
                            >
                                {item.icon && (
                                    <span className="dropdown__menu__item__btn__icon">
                                        {item.icon}
                                    </span>
                                )}
                                {item.text}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Dropdown;
