import {
    ActionButton,
    Column,
    FlexBox,
    Important,
    Input,
    Label,
    Page,
    Panel,
    Row,
    Title,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../hooks/useForm";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import { IAdminInfo } from "../../../store/admin/models";
import { thunkGetAdminUserInfo } from "../../../store/admin/thunk";
import { contactSuccessSet } from "../../../store/contact/actions";
import { IContactParams } from "../../../store/contact/models";
import { thunkPostContact } from "../../../store/contact/thunk";
import TransitionAlert from "../../shared/alert/Alert";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ErrorText from "../../shared/error/text/ErrorText";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";
import Textarea from "../../shared/textarea/Textarea";

const ContactScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    const adminInfo: IAdminInfo = useSelector(
        (store: RootState) => store.adminInfo
    );

    const isLoading: boolean = useSelector((store: RootState) =>
        store.loadingBar.default === 1 ? true : false
    );

    const success: boolean = useSelector(
        (store: RootState) => store.contact.success
    );

    const message: string = useSelector(
        (store: RootState) => store.contact.message
    );

    //local state
    const [form, error, handleOnChange, handleValidate] =
        useForm<IContactParams>({
            message: "",
            company_department: "",
            name_furi: "",
            name: "",
            email: "",
            phone: "",
        });

    //dispatch
    const dispatch = useDispatch();

    const handleGetUserInfo = () => {
        dispatch(thunkGetAdminUserInfo(token));
    };

    const handlePostContact = (body: IContactParams) => {
        dispatch(thunkPostContact(token, body));
    };

    const handleResetSuccess = () => {
        dispatch(contactSuccessSet(false));
    };

    //useEffect
    useSetActiveId('');

    //GET user info
    useEffect(() => {
        handleGetUserInfo();
    }, []);

    //method
    const submit = () => {
        const validate = handleValidate();
        if (validate) {
            handlePostContact(form);
        }
    };

    return (
        <PageBackground>
            <TransitionAlert
                toggle={success}
                text={message}
                color="success"
                close={handleResetSuccess}
            />

            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <Title title="お問い合わせ" />
                        <SpaceBox px="lg">
                            <SpaceBox my="lg">
                                <Important text={"は必須項目となります"} />
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"会社名"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        {adminInfo.company_name}
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"所属部署"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText
                                            text={error.company_department}
                                        />
                                        <Input
                                            name="company_department"
                                            placeholder={"例) 経理部"}
                                            onChange={handleOnChange}
                                            hasError={Boolean(
                                                error.company_department
                                            )}
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"お名前"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.name} />
                                        <Input
                                            name="name"
                                            value={form.name}
                                            onChange={handleOnChange}
                                            hasError={Boolean(error.name)}
                                        />
                                        <span style={{ fontSize: "0.75rem" }}>
                                            姓と名の間はスペースを空けずご記入ください
                                        </span>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"お名前(フリガナ)"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.name_furi} />
                                        <Input
                                            name="name_furi"
                                            onChange={handleOnChange}
                                            hasError={Boolean(error.name_furi)}
                                        />
                                        <span style={{ fontSize: "0.75rem" }}>
                                            姓と名の間はスペースを空けずご記入ください
                                        </span>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"メールアドレス"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.email} />
                                        <Input
                                            name="email"
                                            value={form.email}
                                            onChange={handleOnChange}
                                            hasError={Boolean(error.email)}
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"電話番号"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.phone} />
                                        <Input
                                            name="phone"
                                            placeholder={"例) 08012345678"}
                                            type="tel"
                                            onChange={handleOnChange}
                                            hasError={Boolean(error.phone)}
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={4}>
                                        <Label
                                            text={"お問い合わせ内容"}
                                            bold={true}
                                            required={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.message} />
                                        <Textarea
                                            name="message"
                                            value={form.message}
                                            onChange={handleOnChange}
                                            hasError={Boolean(error.message)}
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <FlexBox justify="center" align="center">
                                <ActionButton
                                    text="送信する"
                                    color="primary"
                                    onClick={submit}
                                    disabled={isLoading}
                                />
                            </FlexBox>
                        </SpaceBox>
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default ContactScreen;
