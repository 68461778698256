import * as React from "react";
import { useEffect, useState } from "react";
import { days, months, years } from "./helpers";
import "./styles.scss";

interface BirthdaySelectProps {
    value?: string;
    name?: string;
    hasError?: boolean;
    onChange?: (val: string, key: string) => void;
}

const BirthdaySelect: React.FC<BirthdaySelectProps> = (props) => {
    const { value, name, hasError, onChange } = props;
    const [year, setYear] = useState<string>("");
    const [month, setMonth] = useState<string>("");
    const [day, setDay] = useState<string>("");

    useEffect(() => {
        const newMonth = month.length === 1 ? `0${month}` : month;
        const newDay = day.length === 1 ? `0${day}` : day;
        onChange(`${year}-${newMonth}-${newDay}`, "birthday");
    }, [year, month, day]);

    useEffect(() => {
        if (value) {
            const split = value.split("-");
            if (split.length === 3) {
                setYear(split[0]);
                setMonth(split[1]);
                setDay(split[2]);
            }
        }
    }, [value]);

    return (
        <div className="birthday">
            <div className="birthday__col">
                <select
                    name={name}
                    className={`birth__col__select ${
                        hasError && "birth__col__select--hasError"
                    }`}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                        setYear(e.currentTarget.value)
                    }
                    defaultValue={"DEFAULT"}
                >
                    <option value="DEFAULT" disabled>
                        {year ? `${year}年` : "年選択"}
                    </option>
                    {years().map((item, index) => (
                        <option
                            key={index.toString()}
                            className="birthday__col__select__option"
                            value={item.toString()}
                        >
                            {`${item}年`}
                        </option>
                    ))}
                </select>
            </div>
            <div className="birthday__col__month">
                <select
                    className={`birth__col__select ${
                        hasError && "birth__col__select--hasError"
                    }`}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                        setMonth(e.currentTarget.value);
                    }}
                    defaultValue="DEFAULT"
                >
                    <option value="DEFAULT" disabled>
                        {month ? `${month}月` : "月選択"}
                    </option>
                    {months.map((item, index) => (
                        <option
                            key={index.toString()}
                            className="birthday__col__select__option"
                            value={item.toString()}
                        >
                            {`${item}月`}
                        </option>
                    ))}
                </select>
            </div>
            <div className="birthday__col">
                <select
                    className={`birth__col__select ${
                        hasError && "birth__col__select--hasError"
                    }`}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                        setDay(e.currentTarget.value);
                    }}
                    defaultValue="DEFAULT"
                >
                    <option value="DEFAULT" disabled>
                        {day ? `${day}日` : "日選択"}
                    </option>
                    {days().map((item, index) => (
                        <option
                            key={index.toString()}
                            className="birthday__col__select__option"
                            value={item.toString()}
                        >
                            {`${item}日`}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default BirthdaySelect;
