export interface ISearchOptions {
    text: string;
    value: string;
}

export const searchOptions: Array<ISearchOptions> = [
    {
        text: "氏名",
        value: "user_profile_full_name_cont",
    },
    {
        text: "氏名(フリガナ)",
        value: "user_profile_full_name_furi_cont",
    },
    {
        text: "名",
        value: "user_profile_first_name_cont",
    },
    {
        text: "名(フリガナ)",
        value: "user_profile_first_name_furi_cont",
    },
    {
        text: "姓",
        value: "user_profile_last_name_cont",
    },
    {
        text: "姓(フリガナ)",
        value: "user_profile_last_name_furi_cont",
    },
    {
        text: "社員番号",
        value: "employee_info_employee_code_cont",
    },
    {
        text: "メールアドレス",
        value: "email_cont",
    },
];

export const searchEmployeeStatusOptions: Array<ISearchOptions> = [
    {
        text: "全状態",
        value: "",
    },
    {
        text: "雇用中",
        value: "hired",
    },
    {
        text: "休職中",
        value: "paid_leave",
    },
    {
        text: "退職",
        value: "retired",
    }
]

export const searchParamStatus = (text: string, value: string) => {
    if (value !== "employee_info_status_eq") return text;
    switch (text) {
        case "用":
            return "hiring";
        case "雇":
            return "hiring";
        case "雇用":
            return "hiring";
        case "雇用中":
            return "hiring";
        case "用中":
            return "hiring";
        case "中":
            return "hiring";
        case "休職":
            return "paid_leave";
        case "休":
            return "paid_leave";
        case "職":
            return "paid_leave";
        default:
            return text;
    }
};
