import axios from "axios";
import { IPunch, IPunchState } from "./models";

async function postPunch(
    token: string,
    body: IPunch
): Promise<IPunchState> {
    // POST /api/v1/company/punch
    try {
        const res = await axios.post(
            `${process.env.COMPANY_ADMIN_BASE}/punch`,
            body,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
}

export default postPunch;
