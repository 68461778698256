import { useState } from "react";

export default function useDateRange(): [
    Date,
    Date,
    (e: Date[]) => void,
    (dateRange: Date[]) => void
] {
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());

    const updateDate = (e: Date[]): void => {
        setFrom(e[0]);
        setTo(e[1]);
    };

    const setDate = (dateRange: Date[]): void => {
        setFrom(dateRange[0]);
        setTo(dateRange[1]);
    };

    return [from, to, updateDate, setDate];
}
