import axios from "axios";

const getEmployeeTransactionsCsv = async (
    token: string,
    uid: string
): Promise<string> => {
    // GET /api/v1/company/employees/{user_uid}/perk_transactions/csv
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/employees/${uid}/perk_transactions/csv`,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
};

export default getEmployeeTransactionsCsv;
