import axios from "axios";
import { employeesUrl } from "../../constants";
import { IEmployeeParams } from "./models";

const updateEmployee = async (
    token: string,
    body: IEmployeeParams,
    edit: boolean,
    uid: string
): Promise<{ token: string; data: IEmployeeParams }> => {
    try {
        const res = edit
            ? await axios.put(`${employeesUrl}/${uid}`, body, {
                  headers: { Authorization: token },
              })
            : await axios.post(employeesUrl, body, {
                  headers: { Authorization: token },
              });

        return {
            token: res.headers.authorization,
            data: res.data,
        };
    } catch (e) {
        throw e;
    }
};

export default updateEmployee;
