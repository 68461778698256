import * as React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { FaPlaneDeparture, FaUtensils } from "react-icons/fa";
import { GiSofa } from "react-icons/gi";
import {
    IBrandSummary,
    IBrandSummaryCategory,
} from "../../../store/brand/models";
import {
    ICategorySummary,
    ICategorySummaryResponse,
} from "../../../store/category/models";

export const searchOptions = [
    {
        text: "社員番号",
        value: "employee_info_employee_code_cont",
    },
    {
        text: "詳細",
        value: "description_cont",
    },
    {
        text: "サービス名",
        value: "provider_name_cont",
    },
];

export const iconSelector = (category: string): React.ReactElement => {
    switch (category) {
        case "food":
            return <FaUtensils size={20} />;
        case "travel":
            return <FaPlaneDeparture size={22} />;
        case "entertainment":
            return <BsFillCameraVideoFill size={22} />;
        case "lifestyle":
            return <GiSofa size={22} />;
        case "gift_card":
            return <AiOutlineGift size={22} />;
        default:
            return;
    }
};

export const categoryArrayFormatter = (
    categoryUsage: ICategorySummaryResponse
): Array<ICategorySummary> => {
    const result = [];
    if (!categoryUsage) return result;
    if (!Array.isArray(categoryUsage.categories)) return result;
    const percentArr = validationCategoryPercentage(categoryUsage);
    categoryUsage.categories.map((item, index) => {
        const itemObj = {
            category: item.category,
            usage: Math.round(percentArr[index] * 10) / 10,
        };
        result.push(itemObj);
    });
    return result;
};

export const colorSelector = (
    index: number
):
    | "primary"
    | "info"
    | "dark"
    | "light"
    | "warning"
    | "danger"
    | "success"
    | "secondary" => {
    switch (index) {
        case 0:
            return "primary";
        case 1:
            return "success";
        case 2:
            return "info";
        case 3:
            return "warning";
        default:
            return;
    }
};

export const brandArrayformatter = (
    brand: IBrandSummary
): Array<IBrandSummaryCategory> => {
    const result = [];
    if (!brand) return result;
    if (!Array.isArray(brand.categories)) return result;
    if (brand.categories.length > 0) {
        brand.categories.map((item) => {
            const itemObj = {
                brand: item.brand,
                usage: Math.round(item.usage * 10) / 10,
            };
            result.push(itemObj);
        });
    }
    return result;
};

const validationCategoryPercentage = (
    categoryUsage: ICategorySummaryResponse
) => {
    let sumVal = 0;
    const result = [];
    if (!categoryUsage) return result;
    if (!Array.isArray(categoryUsage.categories)) return result;
    categoryUsage.categories.map((item) => {
        const roundAmount = Math.round(item.usage * 10) / 10;
        //パーセントの合計値が100を超えてしまった場合、最大値から超過した値をマイナスする
        if (sumVal + roundAmount > 100) {
            const max = Math.round(categoryUsage.categories[0].usage * 10) / 10;
            const extraVal = Math.round((sumVal + roundAmount - 100) * 10) / 10;
            const fixedVal = max - extraVal;
            //最大値を修正した数値に置換
            result.splice(0, 1, fixedVal);
            //通常通りにArrayにPush
            result.push(roundAmount);
        } else {
            sumVal += roundAmount;
            result.push(Math.round(item.usage * 10) / 10);
        }
    });
    //修正したパーセントの配列を返す
    return result;
};
