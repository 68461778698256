import * as React from "react";
import "./styles.scss";

interface AnnotationProps {
    color?: "danger" | "warning" | "success";
    strong?: boolean;
    text: string;
}

const Annotation: React.FC<AnnotationProps> = ({
    color,
    strong,
    text,
}) => {
    const classes = color ? `text-${color}` : null;

    const TextElement = strong ? "strong" : "span";

    return (
        <p className={classes}>
            <TextElement>
                <small>{text}</small>
            </TextElement>
        </p>
    );
};

export default Annotation;
