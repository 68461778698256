import axios from "axios";
import { employeesUrl } from "../../constants";

const updateEmployees = async (
    token: string,
    formData: FormData
): Promise<void> => {
    try {
        const res = await axios.post(`${employeesUrl}/csv`, formData, {
            headers: { Authorization: token },
        });
    } catch (e) {
        throw e;
    }
};

export default updateEmployees;
