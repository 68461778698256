import * as React from "react";
import { numberWithCommas } from "../../screens/history/helpers";
import "./styles.scss";

interface AmountProps {
  value: number;
  kind?: string;
}

const Amount: React.FC<AmountProps> = (props) => {
  const { value, kind } = props;
  return (
    <div className={`amount amount--${kind && "value"}`}>
      <span
        className={`amount__value${
          value > 0 ? " amount__value--credit" : " amount__value--charge"
        }`}
      >
        {value < 0 ? numberWithCommas(value * -1) : numberWithCommas(value)}
        <span className="amount__value__symbol">pt</span>
      </span>
    </div>
  );
};

export default Amount;
