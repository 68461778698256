import {
    ActionButton,
    Column,
    DateSelect,
    FlexBox,
    Input,
    Label,
    Page,
    Panel,
    Row,
    Title,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRetiredDate } from "../../../helpers/dateFormatter";
import useForm from "../../../hooks/useForm";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import {
    IEmployeeParams,
    IEmployeeUpdate,
} from "../../../store/employee/models";
import { thunkCreateEmployee } from "../../../store/employee/thunk";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ErrorText from "../../shared/error/text/ErrorText";
import PostcodeInput from "../../shared/input/postcode/PostcodeInput";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";
import RadioGroup from "../../shared/radio/group/RadioGroup";
import Radio from "../../shared/radio/item/RadioItem";
import BirthdaySelect from "../../shared/select/birthday/BirthdaySelect";

const EmployeeNewScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const success: boolean = useSelector(
        (store: RootState) => store.employeesInfo.success
    );

    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    //local state
    const [
        form,
        error,
        updateFormInput,
        handleValidate,
        _,
        updateValInput,
        handleSetForm,
    ] = useForm<IEmployeeUpdate>({
        email: "",
        employee_code: "",
        last_name: "",
        last_name_furi: "",
        last_name_en: "",
        middle_name: "",
        middle_name_furi: "",
        middle_name_en: "",
        first_name: "",
        first_name_furi: "",
        first_name_en: "",
        gender: "male",
        birthday: "",
        postcode: "",
        address: "",
        address2: "",
        status: "hired",
        retired_at: "2019-1-1",
        tel: "",
        perk_eligible: true,
    });
    const [retired, setRetired] = useState<Date>(null);

    const navigate = useNavigate();

    //useEffect
    useSetActiveId('employees');

    useEffect(() => {
        if (success) {
            navigate(`/employees/list`);
        }
    }, [success]);

    useEffect(() => {
        const newObj = form;
        if (retired) {
            newObj.retired_at = getRetiredDate(retired);
        } else {
            newObj.retired_at = "";
        }
        handleSetForm(newObj);
    }, [form.status, retired]);

    //dispatch method
    const dispatch = useDispatch();

    //POST employee
    const handleCreateEmployee = (body: IEmployeeParams) => {
        dispatch(thunkCreateEmployee(token, body));
    };
    //method

    const submit = () => {
        const validate = handleValidate();
        if (validate) {
            handleCreateEmployee(form);
        }
    };

    const handleRetiredDate = (date: Date) => {
        setRetired(date);
    };

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <Title title="新規作成" />
                        <SpaceBox p="md">
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="社員番号" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText
                                                    text={error.employee_code}
                                                />
                                                <Input
                                                    type="text"
                                                    placeholder="社員番号"
                                                    name="employee_code"
                                                    hasError={Boolean(
                                                        error.employee_code
                                                    )}
                                                    onChange={updateFormInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100} mx="sm">
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※半角英数字で記載ください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="氏名" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox direction="column">
                                            <FlexBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={error.last_name}
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="姓"
                                                        name="last_name"
                                                        hasError={Boolean(
                                                            error.last_name
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox mx="sm" w={100}>
                                                    <Input
                                                        placeholder="ミドルネーム"
                                                        name="middle_name"
                                                        type="text"
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={error.first_name}
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="名"
                                                        name="first_name"
                                                        hasError={Boolean(
                                                            error.first_name
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                            </FlexBox>
                                            <SpaceBox w={100}>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (ミドルネームは省略可)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label
                                            text="氏名(フリガナ)"
                                            bold={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox direction="column">
                                            <FlexBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={
                                                            error.last_name_furi
                                                        }
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="姓"
                                                        name="last_name_furi"
                                                        hasError={Boolean(
                                                            error.last_name_furi
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox mx="sm" w={100}>
                                                    <Input
                                                        type="text"
                                                        placeholder="ミドルネーム"
                                                        name="middle_name_furi"
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={
                                                            error.first_name_furi
                                                        }
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="名"
                                                        name="first_name_furi"
                                                        hasError={Boolean(
                                                            error.first_name_furi
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                            </FlexBox>
                                            <SpaceBox w={100}>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (ミドルネームは省略可)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="氏名(英)" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox direction="column">
                                            <FlexBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={
                                                            error.last_name_en
                                                        }
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="姓"
                                                        name="last_name_en"
                                                        hasError={Boolean(
                                                            error.last_name_en
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox mx="sm" w={100}>
                                                    <Input
                                                        type="text"
                                                        placeholder="ミドルネーム"
                                                        name="middle_name_en"
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                                <SpaceBox w={100}>
                                                    <ErrorText
                                                        text={
                                                            error.first_name_en
                                                        }
                                                    />
                                                    <Input
                                                        type="text"
                                                        placeholder="名"
                                                        name="first_name_en"
                                                        hasError={Boolean(
                                                            error.first_name_en
                                                        )}
                                                        onChange={
                                                            updateFormInput
                                                        }
                                                    />
                                                </SpaceBox>
                                            </FlexBox>
                                            <SpaceBox w={100}>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (ミドルネームは省略可)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="TEL" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText text={error.tel} />
                                                <Input
                                                    type="text"
                                                    name="tel"
                                                    hasError={Boolean(
                                                        error.tel
                                                    )}
                                                    onChange={updateFormInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100} mx="sm">
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※半角英数字・ハイフンなしで記載ください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="Eメール" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText text={error.email} />
                                                <Input
                                                    type="text"
                                                    placeholder="user@example.com"
                                                    name="email"
                                                    hasError={Boolean(
                                                        error.email
                                                    )}
                                                    onChange={updateFormInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100} mx="sm">
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※半角英数字で記載ください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="郵便番号" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText
                                                    text={error.postcode}
                                                />
                                                <PostcodeInput
                                                    value={form.postcode}
                                                    name="postcode"
                                                    hasError={Boolean(
                                                        error.postcode
                                                    )}
                                                    onChange={updateValInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100} mx="sm">
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※半角英数字で記載ください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="住所 (市区町村番地)" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText
                                                    text={error.address}
                                                />
                                                <Input
                                                    type="text"
                                                    name="address"
                                                    hasError={Boolean(
                                                        error.address
                                                    )}
                                                    onChange={updateFormInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100}>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※英数字は全角で記載してください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="住所 (ビル名・部屋番号)" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <FlexBox
                                            align="center"
                                            direction="column"
                                        >
                                            <SpaceBox w={100}>
                                                <ErrorText
                                                    text={error.address2}
                                                />
                                                <Input
                                                    type="text"
                                                    name="address2"
                                                    hasError={Boolean(
                                                        error.address2
                                                    )}
                                                    onChange={updateFormInput}
                                                />
                                            </SpaceBox>
                                            <SpaceBox w={100}>
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    (※英数字は全角で記載してください)
                                                </span>
                                            </SpaceBox>
                                        </FlexBox>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="lg">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="生年月日" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText text={error.birthday} />
                                        <BirthdaySelect
                                            hasError={Boolean(error.birthday)}
                                            onChange={updateValInput}
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="xl">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="性別" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <RadioGroup>
                                            <Radio
                                                value="male"
                                                onChange={updateFormInput}
                                                label="男性"
                                                name="gender"
                                                selected={form.gender}
                                            />
                                            <Radio
                                                value="female"
                                                onChange={updateFormInput}
                                                name="gender"
                                                label="女性"
                                                selected={form.gender}
                                            />
                                        </RadioGroup>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="xl">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="雇用状態" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <RadioGroup>
                                            <Radio
                                                value="hired"
                                                selected={form.status}
                                                name="status"
                                                label="在職"
                                                onChange={updateFormInput}
                                            />
                                            <Radio
                                                value="paid_leave"
                                                selected={form.status}
                                                name="status"
                                                label="休職"
                                                onChange={updateFormInput}
                                            />
                                            <Radio
                                                value="retired"
                                                selected={form.status}
                                                name="status"
                                                label="退職"
                                                onChange={updateFormInput}
                                            />
                                        </RadioGroup>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="xl">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label text="退職日" bold={true} />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <ErrorText
                                            text={error.retired_at}
                                        />
                                        <DateSelect
                                            value={retired}
                                            onChange={handleRetiredDate}
                                            oneTap
                                        />
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox my="xl">
                                <Row>
                                    <Column xs={12} md={3}>
                                        <Label
                                            text="福利厚生ポイント"
                                            bold={true}
                                        />
                                    </Column>
                                    <Column xs={12} md={8}>
                                        <RadioGroup>
                                            <Radio
                                                value={"true"}
                                                name="perk_eligible"
                                                isBool={true}
                                                selected={`${form.perk_eligible}`}
                                                label="対象"
                                                onBoolChange={updateValInput}
                                            />
                                            <Radio
                                                value={"false"}
                                                name="perk_eligible"
                                                isBool={true}
                                                selected={`${form.perk_eligible}`}
                                                label="未対象"
                                                onBoolChange={updateValInput}
                                            />
                                        </RadioGroup>
                                    </Column>
                                </Row>
                            </SpaceBox>
                            <SpaceBox mt="xl">
                                <FlexBox justify="center" align="center">
                                    {Object.keys(error).length ? (
                                        <ErrorText
                                            text="入力内容をご確認ください"
                                        />
                                    ) : null}
                                    <ActionButton
                                        text="新規登録する"
                                        color="primary"
                                        onClick={submit}
                                    />
                                </FlexBox>
                            </SpaceBox>
                        </SpaceBox>
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default EmployeeNewScreen;
