import axios from "axios";
import { healthDataUrl } from "../../constants";
import { SearchUserOptionsState } from "./models";

const getSearchUsers = async (
	token: string,
): Promise<{ token: string; data: SearchUserOptionsState}> => {
	try {
		const res = await axios.get(`${healthDataUrl}/users`, {
			headers: { Authorization: `${token}` },
		});
		return {
			token: res.headers.authorization,
			data: res.data
		};
	} catch (e) {
		throw e;
	}
};

export default getSearchUsers;
