import axios from "axios";
import Qs from "qs";
import { productTransactionsUrl } from "../../constants";
import {
	IMonthlyParams,
	IEmployeesTransactionsParams,
	IProductTransactions,
} from "./models";

const getProductTransactions = async (
	token: string,
	page: number,
	months: IMonthlyParams,
	searchParams: IEmployeesTransactionsParams
): Promise<{ token: string; data: IProductTransactions }> => {
	try {
		const res = await axios.get(productTransactionsUrl, {
			headers: { Authorization: `${token}` },
			params: {
				page,
				q: {
					...months,
					...searchParams,
				},
			},
			paramsSerializer: function (params) {
				return Qs.stringify(params, { arrayFormat: "brackets" });
			},
		});
		return { token: res.headers.authorization, data: res.data };
	} catch (e) {
		throw e;
	}
};

export default getProductTransactions;
