import {
    Column,
    FlexBox,
    IconButton,
    Page,
    Paginate,
    Panel,
    Row,
    SearchFilter,
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    formatCreatedAt,
    formatMonthsParams,
} from "../../../helpers/dateFormatter";
import useInput from "../../../hooks/useInput";
import useSelect from "../../../hooks/useSelect";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import {
    IDateParams,
    IEmployeeTransaction,
    IMonthlyParams,
    initialDateParams,
} from "../../../store/transactions/models";
import {
    thunkGetEmployeeTransactions,
    thunkGetEmployeeTransactionsCsv,
} from "../../../store/transactions/thunk";
import Amount from "../../shared/amount/Amount";
import EmptyBox from "../../shared/box/empty/EmptyBox";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ClearButton from "../../shared/button/clear/ClearButton";
import Content from "../../shared/layout/content/Content";
import Monthly from "../../shared/monthly/Monthly";
import PageBackground from "../../shared/page/PageBackground";
import { compare, compareAmount, numberWithCommas } from "../history/helpers";
import { searchOptions } from "./helpers";

const EmployeeHistoryScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const totalPages: number = useSelector(
        (store: RootState) => store.transactions.employee.total_pages
    );
    const balance: number = useSelector(
        (store: RootState) => store.transactions.employee.perk_balance
    );
    const transactions: IEmployeeTransaction[] = useSelector(
        (store: RootState) => store.transactions.employee.perk_transactions
    );

    //local state
    const [dateParams, setDateParams] =
        useState<IDateParams>(initialDateParams);
    const [data, setData] = useState<IEmployeeTransaction[] | null>(null);
    const [selected, setSelected, resetSelected] =
        useSelect("provider_name_cont");
    const [input, setInput, resetInput] = useInput("");
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dateSort, setDateSort] = useState(false);
    const [amountSort, setAmountSort] = useState(false);

    const location = useLocation();
    const uid = location.pathname.substring(
        location.pathname.lastIndexOf("history/") + 8
    );

    //dispatch
    const dispatch = useDispatch();

    //GET transactions
    const handleGetTransactions = (
        months: IMonthlyParams,
        searchParams = {}
    ) => {
        dispatch(
            thunkGetEmployeeTransactions(
                token,
                uid,
                currentPage,
                months,
                searchParams
            )
        );
    };

    //GET transaction csv
    const handleGetTransactionCsv = () => {
        dispatch(thunkGetEmployeeTransactionsCsv(token, uid));
    };

    //useEffect

    useSetActiveId('employees');

    useEffect(() => {
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        dispatch(
            thunkGetEmployeeTransactions(
                token,
                uid,
                currentPage,
                monthlyParams,
                {}
            )
        );
    }, []);

    useEffect(() => {
        if (currentPage > 1) {
            const monthlyParams: IMonthlyParams = formatMonthsParams(
                month,
                year
            );
            dispatch(
                thunkGetEmployeeTransactions(
                    token,
                    uid,
                    currentPage,
                    monthlyParams,
                    {}
                )
            );
        }
    }, [currentPage]);

    useEffect(() => {
        if (transactions) {
            setData(transactions);
        }
    }, [transactions]);

    //method
    const handleOnSort = (e) => {
        e.preventDefault();
        const params = {
            [selected]: input,
        };
        handleGetTransactions(
            formatMonthsParams(dateParams.month, dateParams.year),
            params
        );
    };

    const handleSetDateRange = (monthNum: number, yearNum: number) => {
        setMonth(monthNum);
        setYear(yearNum);
        const monthlyParams: IMonthlyParams = formatMonthsParams(
            monthNum,
            yearNum
        );
        setDateParams({ month, year });
        dispatch(
            thunkGetEmployeeTransactions(
                token,
                uid,
                currentPage,
                monthlyParams,
                {}
            )
        );
    };

    const handleResetFilter = () => {
        resetSelected("provider_name_cont");
        resetInput("");
        setDateParams(initialDateParams);
        setCurrentPage(1);
    };

    if (!data) return null;
    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <SpaceBox my="md">
                        <Row>
                            <Column xs={12} md={6}>
                                <Monthly
                                    month={month}
                                    year={year}
                                    setDateRange={handleSetDateRange}
                                />
                            </Column>
                            <Column xs={12} md={6}>
                                <FlexBox
                                    direction="row"
                                    justify="center"
                                    align="center"
                                >
                                    <SearchFilter
                                        options={searchOptions}
                                        selectValue={selected}
                                        inputValue={input}
                                        selectOnChange={setSelected}
                                        inputOnChange={setInput}
                                        onSubmit={handleOnSort}
                                        searchSubmit={handleOnSort}
                                    />
                                    <SpaceBox mx="sm">
                                        <IconButton
                                            icon={<FaDownload />}
                                            color="info"
                                            size="md"
                                            onClick={handleGetTransactionCsv}
                                        />
                                    </SpaceBox>
                                </FlexBox>
                            </Column>
                        </Row>
                    </SpaceBox>
                    <Panel>
                        <FlexBox direction="row" justify="between">
                            <div>
                                <label>残り残高</label>
                                <span>
                                    {typeof balance !== "undefined" &&
                                        numberWithCommas(balance)}
                                </span>
                                <small>pt</small>
                            </div>
                            <ClearButton onClick={handleResetFilter} />
                        </FlexBox>
                        {data.length > 0 ? (
                            <Table title="利用明細">
                                <TableHead>
                                    <Tr>
                                        <Th
                                            sortable={true}
                                            onSort={() => {
                                                setData(
                                                    data.sort((a, b) => {
                                                        return compare(
                                                            a,
                                                            b,
                                                            dateSort,
                                                            "created_at"
                                                        );
                                                    })
                                                );
                                                setDateSort(!dateSort);
                                            }}
                                            toggle={dateSort}
                                        >
                                            利用日時
                                        </Th>
                                        <Th>サービス名</Th>
                                        <Th>会社名</Th>
                                        <Th
                                            sortable={true}
                                            onSort={() => {
                                                setData(
                                                    data.sort((a, b) => {
                                                        return compareAmount(
                                                            a,
                                                            b,
                                                            amountSort,
                                                            "amount"
                                                        );
                                                    })
                                                );
                                                setAmountSort(!amountSort);
                                            }}
                                            toggle={amountSort}
                                        >
                                            ポイント
                                        </Th>
                                    </Tr>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <Tr key={index.toString()}>
                                            <Td>
                                                {formatCreatedAt(
                                                    item.created_at
                                                )}
                                            </Td>
                                            <Td>{item.description}</Td>
                                            <Td>{item.provider_name}</Td>
                                            <Td>
                                                <Amount
                                                    value={item.amount}
                                                    kind={item.kind}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <SpaceBox my="lg">
                                <EmptyBox text="利用履歴は現在ありません。" />
                            </SpaceBox>
                        )}
                        {totalPages > 1 && (
                            <Paginate
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />
                        )}
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default EmployeeHistoryScreen;
