import * as Models from "./models";

export function employeeReducer(
    state = Models.initialEmployeesState,
    action:
        | Models.IEmployeesSetAction
        | Models.IEmployeeSetAction
        | Models.IEmployeeUpdateSetAction
        | Models.IEmployeeUpdateSuccessAction
        | Models.IEmployeeInitAction
        | Models.IEmployeeExampleCsvSetAction
        | Models.IEmployeeExampleZipSetAction
        | Models.IEmployeeUpdateFormDataSetAction
): Models.IEmployeesState {
    switch (action.type) {
        case Models.EmployeesActionTypes.EMPLOYEES_SET:
            return {
                ...state,
                employeeItems: { ...action.payload },
            };
        case Models.EmployeesActionTypes.EMPLOYEE_SET:
            return {
                ...state,
                ...action.payload,
            };
        case Models.EmployeesActionTypes.EMPLOYEE_UPDATE_SET:
            return {
                ...state,
                ...action.payload,
            };
        case Models.EmployeesActionTypes.EMPLOYEE_UPDATE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case Models.EmployeesActionTypes.EMPLOYEE_INIT:
            return {
                ...state,
                ...action.payload,
            };
        case Models.EmployeesActionTypes.EMPLOYEE_EXAMPLE_CSV_SET:
            return {
                ...state,
                example: {
                    ...state.example,
                    ...action.payload,
                },
            };
        case Models.EmployeesActionTypes.EMPLOYEE_EXAMPLE_ZIP_SET:
            return {
                ...state,
                example: {
                    ...state.example,
                    ...action.payload,
                },
            };
        case Models.EmployeesActionTypes.EMPLOYEE_UPDATE_IMAGE_SET:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
