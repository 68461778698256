export enum CategorySummaryActionTypes {
	CATEGORY_SUMMARY_SET = "CATEGORY_SUMMARY_SET",
}

export interface ICategorySummary {
	category: string;
	usage: number;
}

export interface ICategorySummaryResponse {
	categories: Array<ICategorySummary>;
}

export interface ICategorySummarySetAction {
	type: CategorySummaryActionTypes.CATEGORY_SUMMARY_SET;
	payload: ICategorySummaryResponse;
}

export const initialCategorySummaryState: ICategorySummaryResponse = {
	categories: [],
};
