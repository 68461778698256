import * as Models from "./models";

export function productTransactionsSet(
    transactions: Models.IProductTransactions
): Models.IProductTransactionAction {
    return {
        type: Models.TransactionActionTypes.PRODUCT_TRANSACTIONS_SET,
        payload: transactions,
    };
}

export function employeesTransactionsSet(
    transactions: Models.IEmployeeTransactions
): Models.IEmployeesTransactionsAction {
    return {
        type: Models.TransactionActionTypes.EMPLOYEES_TRANSACTIONS_SET,
        payload: transactions,
    };
}

export function employeeTransactionsSet(
    transactions: Models.IEmployeeTransactions
): Models.IEmployeeTransactionsAction {
    return {
        type: Models.TransactionActionTypes.EMPLOYEE_TRANSACTIONS_SET,
        payload: transactions,
    };
}

export function employeeTransactionsCsvSet(
    csv: string | null
): Models.IEmployeeTransactionsCsvAction {
    return {
        type: Models.TransactionActionTypes.EMPLOYEE_TRANSACTIONS_CSV_SET,
        payload: { csv },
    };
}
