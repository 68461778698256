// Action Types
export enum BrandSummaryActionTypes {
	BRAND_SUMMARY_SET = "BRAND_SUMMARY_SET",
}

export interface IBrandSummaryCategory {
	brand: string;
	usage: number;
}

export interface IBrandSummary {
	categories: Array<IBrandSummaryCategory>;
}

export interface IBrandSummarySetAction {
	type: BrandSummaryActionTypes.BRAND_SUMMARY_SET;
	payload: IBrandSummary;
}

export const initialBrandSummaryState: IBrandSummary = {
	categories: [],
};
