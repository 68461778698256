import * as React from "react";

import { Column, Row } from "@kortvaluta/admin-twooca-react";

import SubTitle from "../../title/subTitle/SubTitle";
import SearchInput from "./searchInput/SearchInput";
import SearchOptionInput from "./searchOptionInput/SearchOptionInput";
import "./styles.scss";

interface ISearchBoxProps {
    title: string;
    label?: string;
    labelAverage?: string;
    rangeValue?: { start: string; end: string };
    setRangeValue?: (value: { start: string; end: string }) => void;
    rangeAverageValue?: { start: string; end: string };
    setRangeAverageValue?: (value: { start: string; end: string }) => void;
    setSelectedSleepType?: (value: string) => void;
    setSelectedSleepTypeValue?: (value: number) => void;
    maxLength: number;
    styleStart?: boolean;
    styleEnd?: boolean;
    styleStartAverage?: boolean;
    styleEndAverage?: boolean;
    searchTypeSelected?: string;
    isSleep?: boolean;
    selectedMode?: "record" | "summary";
}

const SearchBox: React.FC<ISearchBoxProps> = ({
    title,
    label,
    labelAverage,
    rangeValue,
    rangeAverageValue,
    setRangeValue,
    setRangeAverageValue,
    maxLength,
    styleStart,
    styleEnd,
    styleStartAverage,
    styleEndAverage,
    isSleep = false,
    selectedMode,
}) => {
    return (
        <Row>
            <div className="search-box">
                <Column sm={12}>
                    <SubTitle title={title} />
                </Column>
                {selectedMode == "record" && (
                    <Column sm={12}>
                        <SearchInput
                            label={label}
                            maxLength={maxLength}
                            rangeValue={rangeValue}
                            setRangeValue={setRangeValue}
                            styleStart={styleStart}
                            styleEnd={styleEnd}
                            isSleep={isSleep ? isSleep : false}
                        />
                    </Column>
                )}
                {selectedMode == "summary" && (
                    <Column sm={12}>
                        <SearchInput
                            label={labelAverage}
                            maxLength={maxLength}
                            rangeValue={rangeAverageValue}
                            setRangeValue={setRangeAverageValue}
                            styleStart={styleStartAverage}
                            styleEnd={styleEndAverage}
                            isSleep={isSleep ? isSleep : false}
                        />
                    </Column>
                )}
            </div>
        </Row>
    );
};

export default SearchBox;
