import axios from "axios";
import { passwordUrl } from "../../constants";
import { IPasswordReset } from "./models";

const resetPassword = async (body: IPasswordReset): Promise<void> => {
    try {
        const res = await axios.put(passwordUrl, body);
    } catch (e) {
        throw e;
    }
};

export default resetPassword;
