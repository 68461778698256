import * as React from "react";
import { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./styles.scss";

interface MonthlyProps {
    month: number;
    year: number;
    setDateRange: (month: number, year: number) => void;
}

const Monthly: React.FC<MonthlyProps> = (props) => {
    const { month, year, setDateRange } = props;

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (year === currentYear && month === currentMonth) {
            setDisabled(true);
        } else setDisabled(false);
    }, [month, year]);

    return (
        <div className="monthly">
            <div
                className="monthly__left"
                onClick={() => {
                    if (month === 1) {
                        setDateRange(12, year - 1);
                    } else {
                        setDateRange(month - 1, year);
                    }
                }}
            >
                <FaAngleLeft />
            </div>
            <div className="monthly__date">
                <span className="monthly__date__item">{year}</span>
                <span className="monthly__date__item">年</span>
                <span className="monthly__date__item">{month}</span>
                <span className="monthly__date__item">月</span>
            </div>
            <div
                className={
                    disabled
                        ? "monthly__right monthly__right--disabled"
                        : "monthly__right"
                }
                onClick={() => {
                    if (!disabled) {
                        if (month === 12) {
                            setDateRange(1, year + 1);
                        } else {
                            setDateRange(month + 1, year);
                        }
                    }
                }}
            >
                <FaAngleRight />
            </div>
        </div>
    );
};

export default Monthly;
