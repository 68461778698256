import * as Models from "./models";

export function errorReducer(
    state = Models.intialErrorsState,
    action:
        | Models.IErrorSetAction
        | Models.IErrorResetAction
        | Models.IUploadErrorSetAction
        | Models.IUploadErrorResetAction
): Models.IErrors {
    switch (action.type) {
        case Models.ErrorActionTypes.ERROR_SET:
            return {
                ...state,
                error: {
                    ...action.payload,
                },
            };
        case Models.ErrorActionTypes.ERROR_RESET:
            return {
                ...state,
                error: {
                    ...action.payload,
                },
            };
        case Models.ErrorActionTypes.UPLOAD_ERROR_SET:
            return {
                ...state,
                uploadError: {
                    ...action.payload,
                },
            };
        case Models.ErrorActionTypes.UPLOAD_ERROR_RESET:
            return {
                ...state,
                uploadError: {
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
