import {
    ActionButton,
    FlexBox,
    Input,
    Label,
    Page,
    Panel,
    Title,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import { RootState } from "../../../store";
import { IPasswordRequest } from "../../../store/session/models";
import { thunkPasswordRequest } from "../../../store/session/thunk";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ErrorText from "../../shared/error/text/ErrorText";
import BrandLogo from "../../shared/logo/BrandLogo";
import PageBackground from "../../shared/page/PageBackground";

const PasswordRequestScreen: React.FC = () => {
    //global state
    const success: boolean = useSelector(
        (store: RootState) => store.employeesInfo.success
    );

    //local state
    const [form, error, handleOnChange] = useForm<{
        email: string;
    }>({ email: "" });

    //navigate
    const navigate = useNavigate();

    //dispatch
    const dispatch = useDispatch();

    const handlePasswordRequest = (body: IPasswordRequest) => {
        dispatch(thunkPasswordRequest(body));
    };

    //method
    const submit = () => {
        if (form.email) {
            const body: IPasswordRequest = {
                company_admin_user: {
                    email: form.email,
                },
            };
            handlePasswordRequest(body);
        }
    };

    return (
        <PageBackground>
            <Page>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "75px",
                        paddingTop: "15px",
                    }}
                >
                    <BrandLogo />
                </div>
                <Panel>
                    <Title title="パスワードを再設定する" />
                    <ol>
                        <li
                            style={{ padding: "0.5rem 0", fontSize: "0.75rem" }}
                        >
                            システムへログインするために使用するメールアドレスを入力
                        </li>
                        <li
                            style={{ padding: "0.5rem 0", fontSize: "0.75rem" }}
                        >
                            入力したメールアドレスにリンクが送信されるので、そのリンクをクリック
                        </li>
                        <li
                            style={{ padding: "0.5rem 0", fontSize: "0.75rem" }}
                        >
                            リンクをクリックすると、新しいパスワードを入力するための画面が表示されるので、新しいパスワードを入力
                        </li>
                        <li
                            style={{ padding: "0.5rem 0", fontSize: "0.75rem" }}
                        >
                            ログイン
                        </li>
                        <li
                            style={{ padding: "0.5rem 0", fontSize: "0.75rem" }}
                        >
                            パスワードを再設定後は、通常通り、ログインできます。
                        </li>
                    </ol>
                    {success && (
                        <FlexBox justify="center" align="center">
                            <SpaceBox px="md">
                                <div style={{ color: "#7ac943" }}>
                                    <FaCheckCircle />
                                    メールが正常に送信されました
                                </div>
                            </SpaceBox>
                        </FlexBox>
                    )}
                    <FlexBox justify="center">
                        <SpaceBox my="lg" w={60}>
                            <SpaceBox my="md">
                                <Label
                                    required={true}
                                    bold={true}
                                    text="メールアドレスを入力してください"
                                />
                            </SpaceBox>
                            <ErrorText text={error.email} />
                            <Input
                                hasError={false}
                                placeholder="メールアドレス"
                                autoComplete="username"
                                onChange={handleOnChange}
                                value={form.email}
                                type="email"
                                name="email"
                                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                onEnter={true}
                            />
                        </SpaceBox>
                    </FlexBox>
                    <SpaceBox my="lg">
                        <FlexBox justify="center">
                            <SpaceBox mx="md">
                                <ActionButton
                                    text="メールをリクエストする"
                                    color="primary"
                                    onClick={submit}
                                    submit={true}
                                />
                            </SpaceBox>
                            <SpaceBox mx="md">
                                <ActionButton
                                    text="前の画面に戻る"
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                    submit={false}
                                />
                            </SpaceBox>
                        </FlexBox>
                    </SpaceBox>
                </Panel>
            </Page>
        </PageBackground>
    );
};

export default PasswordRequestScreen;
