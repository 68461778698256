import axios from "axios";
import { ICompanyServiceState } from "./models";

async function getBusinessServices(token: string): Promise<ICompanyServiceState> {
    // GET /api/v1/company/company_services
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/business_services`,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return {
            list: res.data,
        };
    } catch (e) {
        throw e;
    }
}

export default getBusinessServices;
