import axios from "axios";
import { IAnnouncementsParams } from "./models";

async function postAnnouncement(
    token: string,
    body: IAnnouncementsParams
): Promise<void> {
    // POST /api/v1/company/company_announcements
    try {
        const res = await axios.post(
            `${process.env.COMPANY_ADMIN_BASE}/company_announcements`,
            body,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
}

export default postAnnouncement;
