import * as Models from "./models";

export function requestEnrollmentReducer(
	state = Models.initialEnrollmentState,
	action:
		| Models.IBulkEnrollmentRequestSuccessSetAction
		| Models.IEnrollmentRequestSuccessSetAction
): Models.IRequestEnrollmentState {
	switch (action.type) {
		case Models.EnrollmentRequestActionTypes.ENROLLMENT_REQUEST_SUCCESS_SET:
			return {
				...state,
				success: action.payload,
			};
		case Models.EnrollmentRequestActionTypes
			.BULK_ENROLLMENT_REQUEST_SUCCESS_SET:
			return {
				...state,
				bulkSuccess: action.payload,
			};
		default:
			return state;
	}
}
