import * as Models from "./models";

export function employeesSet(
    employees: Models.IEmployees
): Models.IEmployeesSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEES_SET,
        payload: employees,
    };
}

export function employeeSet(
    employee: Models.IEmployeeEdit
): Models.IEmployeeSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_SET,
        payload: employee,
    };
}

export function employeeUpdateSet(
    employeeUpdate: Models.IEmployeeUpdate
): Models.IEmployeeUpdateSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_UPDATE_SET,
        payload: employeeUpdate,
    };
}

export function employeeSuccess(
    success: boolean
): Models.IEmployeeUpdateSuccessAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_UPDATE_SUCCESS,
        payload: { success },
    };
}

export function employeeInit(init: boolean): Models.IEmployeeInitAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_INIT,
        payload: { init },
    };
}

export function employeeExampleCsvSet(
    employeeCsv: Models.IEmployeeExampleCsv
): Models.IEmployeeExampleCsvSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_EXAMPLE_CSV_SET,
        payload: employeeCsv,
    };
}

export function employeeExampleZipSet(
    employeeZip: Models.IEmployeeExampleZip
): Models.IEmployeeExampleZipSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_EXAMPLE_ZIP_SET,
        payload: employeeZip,
    };
}
export function employeeFormDataUpdateSet(
    updatedImageData: Models.IEmployeeUpdatedImageData
): Models.IEmployeeUpdateFormDataSetAction {
    return {
        type: Models.EmployeesActionTypes.EMPLOYEE_UPDATE_IMAGE_SET,
        payload: { updatedImageData },
    };
}
