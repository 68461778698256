import { useState } from "react";

export default function useSelect(
    initVal: string
): [
    string,
    (e: React.ChangeEvent<HTMLSelectElement> | string) => void,
    (val: string) => void
] {
    const [select, setSelect] = useState(initVal);

    const updateSelect = (e: React.ChangeEvent<HTMLSelectElement> | string): void => {
        if (typeof e === 'string') {
            setSelect(e);
        } else if (e && e.currentTarget) {
            setSelect(e.currentTarget.value);
        }
    };

    const resetSelect = (val: string): void => {
        setSelect(val);
    };

    return [select, updateSelect, resetSelect];
}
