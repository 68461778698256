export const checkStatus = (status: string): string => {
    switch (status) {
        case "hired":
            return "雇用中";
        case "paid_leave":
            return "休職";
        default:
            return "退職";
    }
};
export const checkRetired = (date: string) => {
    if (!date) return false;
    if (date.length < 1) return false;
    const retiredAt = date ? new Date(date) : null;
    return retiredAt && retiredAt <= new Date();
};
