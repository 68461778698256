import * as React from "react";
import ReactPaginate from "react-paginate";

import "./styles.scss";

export interface IPaginateProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Paginate: React.FC<IPaginateProps> = (props) => {
  const { currentPage, totalPages, onPageChange } = props;
  return (
    <div>
      <ReactPaginate
        forcePage={currentPage - 1}
        pageCount={totalPages}
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        breakLabel={". . ."}
        containerClassName="paginate"
        pageClassName="paginate__item"
        activeClassName="paginate__item--active"
        previousLabel="前へ"
        nextLabel="次へ"
        pageLinkClassName="paginate__item__link"
        previousLinkClassName="paginate__previous"
        nextLinkClassName="paginate__next"
        onPageChange={(data) => {
          const pageNumber = data.selected + 1;
          onPageChange(pageNumber);
        }}
        initialPage={currentPage - 1}
        disableInitialCallback={true}
      />
    </div>
  );
};

export default Paginate;
