import axios from "axios";
import Qs from "qs";
import { employeesTransactionsUrl } from "../../constants";
import {
    IEmployeesTransactionRes,
    IEmployeesTransactionsParams,
    IMonthlyParams,
} from "./models";

const getEmployeesTransactions = async (
    token: string,
    page: number,
    months: IMonthlyParams,
    searchParams?: IEmployeesTransactionsParams
): Promise<IEmployeesTransactionRes> => {
    try {
        const res = await axios.get(employeesTransactionsUrl, {
            headers: { Authorization: `${token}` },
            params: {
                page,
                q: {
                    ...months,
                    ...searchParams,
                },
            },
            paramsSerializer: function (params) {
                return Qs.stringify(params, { arrayFormat: "brackets" });
            },
        });
        return {
            token: res.headers.authorization,
            data: res.data,
        };
    } catch (e) {
        throw e;
    }
};

export default getEmployeesTransactions;
