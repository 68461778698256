import * as Models from "./models";

export function navigationActive(
  navigation: Models.IActiveId
): Models.INavigationActiveAction {
  const id = navigation.id.toString();
  localStorage.setItem("activeId", id);
  return {
    type: Models.NavigationActionTypes.NAVIGATION_ACTIVE,
    payload: navigation
  };
}
