import axios from "axios";
import Qs from "qs";
import { IAnnouncementSearch, IAnnouncementsResponse } from "./models";

async function getAnnouncements(
    token: string,
    page = 1,
    params: IAnnouncementSearch
): Promise<IAnnouncementsResponse> {
    // GET /api/v1/company/company_announcements

    const res = await axios
        .get(`${process.env.COMPANY_ADMIN_BASE}/company_announcements`, {
            headers: { Authorization: `${token}` },
            params: {
                page,
                q: {
                    ...params,
                },
            },
            paramsSerializer: function (params) {
                return Qs.stringify(params, { arrayFormat: "brackets" });
            },
        })
        .catch((e) => {
            throw e;
        });

    return {
        token: res.headers.authorization,
        data: res.data,
    };
}

export default getAnnouncements;
