import { IEmployeeUpdate } from "./models";

export const employee: IEmployeeUpdate = {
    email: "",
    user_uid: "",
    employee_code: "",
    last_name: "",
    last_name_furi: "",
    last_name_en: "",
    middle_name: "",
    middle_name_furi: "",
    middle_name_en: "",
    first_name: "",
    first_name_furi: "",
    first_name_en: "",
    gender: "",
    birthday: "",
    postcode: "",
    address: "",
    address2: "",
    retired_at: "",
    status: "",
    tel: "",
    perk_eligible: true,
};
