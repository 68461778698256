import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Page, Panel, TableBody, TableHead, Td, Th, Tr } from "@kortvaluta/admin-twooca-react";

import { RootState } from "../../../../store";
import ActionBtn from "../../../shared/actionButton/ActionBtn";
import PageBackground from "../../../shared/page/PageBackground";
import SubTitle from "../../../shared/title/subTitle/SubTitle";
import Title from "../../../shared/title/Title";
import "./styles.scss";
import Table from "../../../shared/table/Table";
import { thunkGetRingUser } from "../../../../store/healthcare/thunk";
import { IRingUser } from "../../../../store/healthcare/models";

const RingSearchScreen: React.FC = () => {
  const dispatch = useDispatch();

  const token: string = useSelector((store: RootState) => store.session.token);

  const sidebarToggle: boolean = useSelector(
    (store: RootState) => store.sidebar.sidebarToggle
  );
  const users: IRingUser[] = useSelector(
    (store: RootState) => store.healthcare.userSearch.users
  );

  const [email, setEmail] = useState("");
  const [mac, setMac] = useState("");
  const [mac1, setMac1] = useState("");
  const [mac2, setMac2] = useState("");
  const [errorMail, setErrorMail] = useState(Boolean);
  const [errorMac, setErrorMac] = useState(Boolean);
  const [errorMac1, setErrorMac1] = useState(Boolean);
  const [errorMac2, setErrorMac2] = useState(Boolean);
  const [showResult, setShowResult] = useState(Boolean);

  const handleMailButtonClick = () => {
    if (email) {
      setErrorMail(false);
      submit('email', email);
    } else {
      setErrorMail(true);
    }
  };

  const handleMacButtonClick = () => {
    const macPattern = new RegExp("[0-9a-fA-F]{2}");
    let hasError = false
    if (!macPattern.test(mac)) {
      setErrorMac(true);
      hasError = true
    } else {
      setErrorMac(false);
    }
    if (!macPattern.test(mac1)) {
      setErrorMac1(true);
      hasError = true
    } else {
      setErrorMac1(false);
    }
    if (!macPattern.test(mac2)) {
      setErrorMac2(true);
      hasError = true
    } else {
      setErrorMac2(false);
    }
    if (!hasError) {
      submit('mac_address', `${mac_address_prefix}${mac}:${mac1}:${mac2}`);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const submit = async (type: string, data: string) => {
    dispatch(thunkGetRingUser(token, type, data));
    setShowResult(true);
  }

  const mac_address_prefix = "D5:15:D8:";

  return (
    <PageBackground>
      <Page toggle={sidebarToggle}>
        <div className="ring-search">
          <Panel>
            <Title title="リング検索" />
            <div className="ring-search__mac">
              <SubTitle title="MAC アドレスから検索" />
              <div className="ring-search__mac__form">
                <div className="ring-search__mac__form__label">
                  MAC アドレス
                </div>
                <div className="ring-search__mac__form__label">
                  {mac_address_prefix}
                  <input
                    type="text"
                    className="ring-search__mac__form__input"
                    style={{
                      borderColor: errorMac ? "red" : "",
                    }}
                    pattern="[0-9a-fA-F]{2}"
                    maxLength={2}
                    onChange={(event) => {
                      setMac(event.target.value);
                    }}
                  />
                  :
                  <input
                    type="text"
                    className="ring-search__mac__form__input"
                    style={{
                      borderColor: errorMac1 ? "red" : "",
                    }}
                    pattern="[0-9a-fA-F]{2}"
                    maxLength={2}
                    onChange={(event) => {
                      setMac1(event.target.value);
                    }}
                  />
                  :
                  <input
                    type="text"
                    className="ring-search__mac__form__input"
                    style={{
                      borderColor: errorMac2 ? "red" : "",
                    }}
                    pattern="[0-9a-fA-F]{2}"
                    maxLength={2}
                    onChange={(event) => {
                      setMac2(event.target.value);
                    }}
                  />
                </div>
                <div>
                  <ActionBtn text="検索する" onClick={handleMacButtonClick} submit color="primary"/>
                </div>
              </div>
            </div>
            <div className="ring-search__mac">
              <SubTitle title="TwooCa アカウントから検索" />
              <div className="ring-search__mac__form">
                <div className="ring-search__mac__form__label">
                  メールアドレス
                </div>
                <div className="ring-search__mac__form__label">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    style={{ borderColor: errorMail ? "red" : "" }}
                    onChange={handleEmailChange}
                  />
                </div>
                <div>
                  <ActionBtn text="検索する" onClick={handleMailButtonClick} submit color="primary"/>
                </div>
              </div>
            </div>
          </Panel>
          {showResult ? (
            <Panel>
              <Table>
                <TableHead>
                  <Tr>
                    <Th>TwooCa アカウント</Th>
                    <Th>氏名</Th>
                    <Th>リングMacアドレス</Th>
                  </Tr>
                </TableHead>
                <TableBody>
                  {users.map((u, index) => (
                    <Tr key={index.toString()}>
                      <Td>{u.email}</Td>
                      <Td>{u.name}</Td>
                      <Td>{u.mac_address}</Td>
                    </Tr>
                  ))}
                </TableBody>
              </Table>
            </Panel>
          ) : (
            <Panel>
              <div className="emptyTable">No result found</div>
            </Panel>
          )}
        </div>
      </Page>
    </PageBackground>
  );
};
export default RingSearchScreen;
