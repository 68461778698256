import * as Models from "./models";

export function brandSummaryReducer(
	state = Models.initialBrandSummaryState,
	action: Models.IBrandSummarySetAction
): Models.IBrandSummary {
	switch (action.type) {
		case Models.BrandSummaryActionTypes.BRAND_SUMMARY_SET:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
