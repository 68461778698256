import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import {
    errorReset,
    errorSet,
} from "../error/actions";
import { RootState } from "../index";
import { sessionStatus } from "../session/actions";
import { IEmployeesSearchParams } from "../../store/employee/models";
import { healthDataDashboardSet, ringUserSet, ringUsersSet } from "./actions";
import getHealthDataDashboard from "./getHealthDataDashboard";
import getRingUser from "./getRingUser";
import getRingUsers from "./getRingUsers";

// employees get
// @param token: string
export const thunkGetHealthDataDashboard =
    (
        token: string,
        page = 1,
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        try {
            const res = await getHealthDataDashboard(token, page);
            dispatch(healthDataDashboardSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

export const thunkGetRingUser =
    (
        token: string,
        type: string,
        data: string,
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        try {
            const res = await getRingUser(token, type, data);
            dispatch(ringUserSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

export const thunkGetRingUsers =
    (
        token: string,
        page: number,
        filter: IEmployeesSearchParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        try {
            const res = await getRingUsers(token, page, filter);
            dispatch(ringUsersSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };
