import {
    ActionButton,
    Column,
    DateRange,
    FlexBox,
    Input,
    Label,
    Page,
    Panel,
    Row,
    Title,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCreatedAt } from "../../../helpers/dateFormatter";
import useDateRange from "../../../hooks/useDateRange";
import useForm from "../../../hooks/useForm";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import { announcementSet } from "../../../store/announcements/actions";
import {
    IAnnouncement,
    IAnnouncementsParams,
} from "../../../store/announcements/models";
import {
    thunkGetAnnouncement,
    thunkPostAnnouncement,
    thunkPutAnnouncement,
} from "../../../store/announcements/thunk";
import {
    getAnnouncement,
    getSidebarToggle,
    getToken,
} from "../../../store/state";
import Annotation from "../../shared/annotation/Annotation";
import Checkbox from "../../shared/box/check/Checkbox";
import Box from "../../shared/box/space/SpaceBox";
import ErrorText from "../../shared/error/text/ErrorText";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";
import Textarea from "../../shared/textarea/Textarea";

const AnnouncementScreen: React.VFC = () => {
    //global store
    const token: string = getToken();
    const sidebarToggle: boolean = getSidebarToggle();
    const announcement: IAnnouncement = getAnnouncement();
    const success: boolean = useSelector(
        (store: RootState) => store.announcements.success
    );

    //dispatch method
    const dispatch = useDispatch();

    //GET
    const handleGetAnnouncement = () => {
        dispatch(thunkGetAnnouncement(token, id));
    };

    //PUT
    const handlePutAnnouncement = (body: IAnnouncementsParams) => {
        dispatch(thunkPutAnnouncement(token, id, body));
    };

    //POST
    const handlePostAnnouncement = (body: IAnnouncementsParams) => {
        dispatch(thunkPostAnnouncement(token, body));
    };

    //RESET
    const handleResetannouncement = () => {
        dispatch(announcementSet(null));
    };

    //local state
    const [data, error, onChange, handleValidate, handleSetInitValue] =
        useForm<IAnnouncementsParams>({
            title: "",
            message: "",
            is_draft: true,
            released_at: "",
            expired_at: "",
        });
    const [dateFrom, dateTo, updateDate, setDate] = useDateRange();
    const [isDraft, setIsDraft] = useState(true);

    //router method
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    //useEffect
    useSetActiveId('announcements');

    //set original value if edit page
    useEffect(() => {
        if (!location.pathname.includes("new") && id) handleGetAnnouncement();
        //delete announcement global state
        return () => {
            handleResetannouncement();
        };
    }, []);

    useEffect(() => {
        if (announcement && id) {
            handleSetInitValue({
                title: announcement.title,
                message: announcement.message,
                is_draft: announcement.status === "下書き",
                released_at: announcement.released_at,
                expired_at: announcement.updated_at,
            });
            setIsDraft(announcement.status === "下書き");
            if (announcement.released_at) {
                setDate([
                    new Date(announcement.released_at),
                    new Date(announcement.expired_at),
                ]);
            }
        }
    }, [announcement]);

    useEffect(() => {
        if (success) {
            navigate("/announcements");
        }
    }, [success]);

    //submit method
    const submit = (): void => {
        const validate = handleValidate();
        if (validate) {
            const newObj: IAnnouncementsParams = {
                title: data.title,
                message: data.message,
                is_draft: isDraft,
                released_at: dateFrom.toLocaleDateString("ja-JP"),
                expired_at: dateTo.toLocaleDateString("ja-JP"),
            };
            //if edit page run PUT mehtod, else if new page run POST method
            if (id) {
                handlePutAnnouncement(newObj);
            } else {
                handlePostAnnouncement(newObj);
            }
        }
    };

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <Title title=" お知らせ登録/編集" />
                        <Box px="md">
                            <>
                                <Box mt="lg">
                                    <Row>
                                        <Column xs={12} md={2}>
                                            <Label
                                                text={"タイトル"}
                                                bold={true}
                                                required={true}
                                            />
                                        </Column>
                                        <Column xs={12} md={10}>
                                            <ErrorText text={error.title} />
                                            <Input
                                                name="title"
                                                onChange={onChange}
                                                value={data.title}
                                                hasError={error.title && true}
                                            />
                                        </Column>
                                    </Row>
                                </Box>
                                <Box mt="lg">
                                    <Row>
                                        <Column xs={12} md={2}>
                                            <Label
                                                text={"本文"}
                                                bold={true}
                                                required={true}
                                            />
                                        </Column>
                                        <Column xs={12} md={10}>
                                            <ErrorText text={error.message} />
                                            <Textarea
                                                name="message"
                                                value={data.message}
                                                hasError={error.message && true}
                                                onChange={onChange}
                                            />
                                        </Column>
                                    </Row>
                                </Box>
                                <Box mt="lg">
                                    <Row>
                                        <Column xs={12} md={2}>
                                            <Label
                                                text={"公開期間"}
                                                bold={true}
                                                required={true}
                                            />
                                        </Column>
                                        <Column xs={12} md={10}>
                                            <DateRange
                                                value={[dateFrom, dateTo]}
                                                onChange={updateDate}
                                            />
                                            <Annotation
                                                color="danger"
                                                text="終了日付には、最終配信日の翌日を設定してください。"
                                                strong
                                            />
                                        </Column>
                                    </Row>
                                </Box>
                                <Box mt="lg">
                                    <Row>
                                        <Column xs={12} md={2}>
                                            <Label
                                                text={"下書きにする"}
                                                bold={true}
                                                required={true}
                                            />
                                        </Column>
                                        <Column xs={12} md={10}>
                                            <Checkbox
                                                checked={isDraft}
                                                size="md"
                                                onClick={() => {
                                                    setIsDraft((prev) => !prev);
                                                }}
                                            />
                                        </Column>
                                    </Row>
                                </Box>
                                {id ? (
                                    <Box mt="lg">
                                        <Row>
                                            <Column xs={12} md={2}>
                                                <Label
                                                    text={"最終更新時間"}
                                                    bold={true}
                                                />
                                            </Column>
                                            <Column xs={12} md={10}>
                                                <p>
                                                    {formatCreatedAt(
                                                        announcement.updated_at
                                                    )}
                                                </p>
                                            </Column>
                                        </Row>
                                    </Box>
                                ) : null}
                                <FlexBox justify="center">
                                    <Box mt="lg">
                                        <ActionButton
                                            text={id ? "更新する" : "登録する"}
                                            color="primary"
                                            submit={true}
                                            onClick={submit}
                                        />
                                    </Box>
                                </FlexBox>
                            </>
                        </Box>
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default AnnouncementScreen;
