export enum PunchActionTypes {
    PUNCH_SUCCESS = "PUNCH_SUCCESS",
}

type punchType = 'in' | 'out' | null;

export interface IPunch {
    qrCode: string;
    type: punchType;
}

export interface IPunchSuccessAction {
    type: PunchActionTypes.PUNCH_SUCCESS;
    payload: boolean;
}

export interface IPunchState {
    status: boolean | null;
}

export const initialPunchState: IPunchState = {
    status: null,
};
