import React, { useEffect, useState } from "react";
import Monthly from "../monthly/Monthly";
import { Column, SearchFilter } from "@kortvaluta/admin-twooca-react";

const SearchHistory = ({
    month,
    year,
    handleSetDateRange,
    searchOptions,
    input,
    selected,
    handleOnSort,
    setSelected,
    setInput,
}) => {
    return (
        <>
            <Column xs={12} md={6}>
                <Monthly
                    month={month}
                    year={year}
                    setDateRange={handleSetDateRange}
                />
            </Column>
            <Column xs={12} md={6}>
                <SearchFilter
                    options={searchOptions}
                    inputValue={input}
                    selectValue={selected}
                    onSubmit={handleOnSort}
                    searchSubmit={handleOnSort}
                    selectOnChange={setSelected}
                    inputOnChange={setInput}
                />
            </Column>
        </>
    );
};

export default SearchHistory;
