import React, { useState } from "react";
import dayjs from "dayjs";
import { getRetiredDate } from "../helpers/dateFormatter";

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export default function useForm<T>(
    initValues: T
): [
    T,
    ErrorRecord<T>,
    (
        e: React.ChangeEvent<
            HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement
        >
    ) => void,
    () => boolean,
    (initValue: T) => void,
    (val: string | boolean, key: string) => void,
    (form: T) => void,
    (date: Date, key: string) => void
] {
    const [data, setData] = useState<T>(initValues);
    const [error, setErrors] = useState<ErrorRecord<T>>({});

    const handleOnChange = (
        e: React.ChangeEvent<
            HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement
        >
    ): void => {
        const key = e.currentTarget.name;
        const val = e.currentTarget.value;
        setData({
            ...data,
            [key]: val,
        });
    };

    const handleValidate = (): boolean => {
        let valid = true;
        const newErrors: ErrorRecord<T> = {};

        for (const key in validations) {
            const value = data[key];
            const validation = validations[key];
            const pattern = validation?.pattern;
            const custom = validation?.custom;

            if (value !== undefined) {
                if (validation?.required?.value && !value) {
                    valid = false;
                    newErrors[key] = validation?.required?.message;
                } else if (pattern?.value && !RegExp(pattern.value).test(value)) {
                    valid = false;
                    newErrors[key] = pattern.message;
                } else if (custom?.isValid && custom.isValid(value)) {
                    valid = false;
                    newErrors[key] = custom.message;
                }
            }
        }

        const retiredAtErrorMessage = getRetiredAtErrorMessage();
        if (retiredAtErrorMessage) {
            valid = false;
            newErrors['retired_at'] = retiredAtErrorMessage;
        }

        setErrors(valid ? {} : newErrors);
        return valid;
    };

    const handleSetInitValue = (initValue: T): void => {
        setData(initValue);
    };

    //input handler for birthday and postcode
    const handleValueInput = (val: string | boolean, key: string): void => {
        setData({
            ...data,
            [key]: val,
        });
    };

    const handleDateInput = (date: Date, key: string) => {
        setData({
            ...data,
            [key]: getRetiredDate(date),
        });
    };

    const handleSetForm = (form: T) => {
        setData(form);
    };

    const validateBirthday = (val: string): boolean => {
        const split = val.split("-");
        let result = false;
        for (let i = 0; i < split.length; i++) {
            if (split[0].length === 0) {
                result = true;
            }
        }
        return result;
    };

    const validatePostcode = (val: string): boolean => {
        let result = false;
        if (val.length !== 7) {
            result = true;
        }
        return result;
    };

    const validateKanaName = (val: string): boolean => {
        let result = false;
        if (
            !RegExp(/^[ァ-ヶー]+$/).test(val) &&
            !RegExp(/^[ｦ-ﾟ]*$/).test(val)
        ) {
            result = true;
        }
        return result;
    };

    const getRetiredAtErrorMessage = (): string => {
        const retiredAt = data['retired_at'];
        const status = data['status'];
        if (!retiredAt) return "";

        if (status === "hired") {
            if (dayjs(retiredAt).isSame(dayjs().format('YYYY/MM/DD')) || dayjs(retiredAt).isAfter(dayjs())) {
                return "";
            } else {
                return "雇用状態が在職の場合、退職日は未来日付のみ設定可能です";
            }
        } else if (status === "retired") {
            if (!dayjs(retiredAt).isSame(dayjs().format('YYYY/MM/DD')) && dayjs(retiredAt).isBefore(dayjs())) {
                return "";
            } else {
                return "雇用状態が退職の場合、退職日は過去日付のみ設定可能です";
            }
        }

        return "";
    }

    //validation for error
    const validations = {
        title: {
            required: {
                value: true,
                message: "タイトルを入力してください",
            },
        },
        message: {
            required: {
                value: true,
                message: "本文を入力してください",
            },
        },
        employee_code: {
            required: {
                value: true,
                message: "社員番号を入力してください",
            },
        },
        last_name: {
            required: {
                value: true,
                message: "姓を入力してください",
            },
        },
        first_name: {
            required: {
                value: true,
                message: "名を入力してください",
            },
        },
        last_name_furi: {
            required: {
                value: true,
                message: "姓(フリガナ)を入力してください",
            },
            custom: {
                isValid: validateKanaName,
                message: "カタカナで入力してください",
            },
        },
        first_name_furi: {
            required: {
                value: true,
                message: "名(フリガナ)を入力してください",
            },
            custom: {
                isValid: validateKanaName,
                message: "カタカナで入力してください",
            },
        },
        last_name_en: {
            required: {
                value: true,
                message: "姓(英)を入力してください",
            },
            pattern: {
                value: /^[A-Za-z0-9]*$/,
                message: "半角英字で入力してください",
            },
        },
        first_name_en: {
            required: {
                value: true,
                message: "名(英)を入力してください",
            },
            pattern: {
                isValid: /^[A-Za-z0-9]*$/,
                message: "半角英字で入力してください",
            },
        },
        tel: {
            required: {
                value: true,
                message: "電話番号を入力してください",
            },
            pattern: {
                value: /^[0-9-]{10,13}$/i,
                message: "正しい電話番号を入力してください",
            },
        },
        phone: {
            required: {
                value: true,
                message: "電話番号を入力してください",
            },
            pattern: {
                value: /^[0-9-]{10,13}$/i,
                message: "正しい電話番号を入力してください",
            },
        },
        postcode: {
            required: {
                value: true,
                message: "郵便番号を入力してください",
            },
            custom: {
                isValid: validatePostcode,
                message: "郵便番号は7桁の半角数字を入力してください",
            },
            pattern: {
                value: /^[0-9]+$/,
                message: "半角数字で入力してください",
            },
        },
        address: {
            required: {
                value: true,
                message: "住所を入力してください",
            },
        },
        address2: {
            required: {
                value: false,
                message: "住所を入力してください",
            },
        },
        birthday: {
            custom: {
                isValid: validateBirthday,
                message: "生年月日を入力してください",
            },
        },
        name: {
            required: {
                value: true,
                message: "お名前を入力してください",
            },
        },
        company_department: {
            required: {
                value: true,
                message: "所属部署を入力してください",
            },
        },
        name_furi: {
            required: {
                value: true,
                message: "お名前(フリガナ)を入力してください",
            },
            custom: {
                isValid: validateKanaName,
                message: "カタカナで入力してください",
            },
        },
    };

    return [
        data,
        error,
        handleOnChange,
        handleValidate,
        handleSetInitValue,
        handleValueInput,
        handleSetForm,
        handleDateInput,
    ];
}
