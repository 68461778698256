import axios from "axios";
import { healthDataUrl } from "../../constants";
import {
    SearchUserOption,
    HistoricalDataType,
    HistoricalData,
} from "./models";

const getHistoricalData = async (
    token: string,
    params: {
        user: SearchUserOption;
        date: Date;
        type: HistoricalDataType;
    },
): Promise<{ token: string; data: HistoricalData }> => {
    try {
        const res = await axios.get(`${healthDataUrl}/historical_data`, {
            headers: { Authorization: `${token}` },
            params,
        });
        return {
            token: res.headers.authorization,
            data: res.data.result,
        };
    } catch (e) {
        throw e;
    }
};

export default getHistoricalData;
