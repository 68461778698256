import * as Models from "./models";

export function errorSet(error: Models.IError): Models.IErrorSetAction {
    return {
        type: Models.ErrorActionTypes.ERROR_SET,
        payload: error,
    };
}

export function errorReset(): Models.IErrorResetAction {
    return {
        type: Models.ErrorActionTypes.ERROR_RESET,
        payload: {
            hasError: false,
            errorType: "",
            errorMessage: "",
            errorMessageArr: [],
        },
    };
}

export function uploadErrorSet(
    error: Models.IError
): Models.IUploadErrorSetAction {
    return {
        type: Models.ErrorActionTypes.UPLOAD_ERROR_SET,
        payload: error,
    };
}

export function uploadErrorReset(): Models.IUploadErrorResetAction {
    return {
        type: Models.ErrorActionTypes.UPLOAD_ERROR_RESET,
        payload: {
            hasError: false,
            errorType: "",
            errorMessage: "",
            errorMessageArr: [],
        },
    };
}
