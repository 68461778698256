// 検索ターゲットの型を定義
export type ISearchTarget = "心拍" | "体温" | "歩数" | "睡眠";

export enum SearchTarget {
    "心拍" = "heartRate",
    "体温" = "bodyTemperature",
    "歩数" = "stepCount",
    "睡眠" = "sleepSession",
}

export interface IInputRange {
    (
        heartRange: RangeDetail,
        averageHeartRange: RangeDetail,
        stepCountRange: RangeDetail,
        averageStepCountRange: RangeDetail,
        temperatureRange: RangeDetail,
        averageTemperatureRange: RangeDetail,
        sleepTimeRange: RangeDetail,
        averageSleepTimeRange: RangeDetail,
        sleepTypeValue: number
    ): void;
}

// 検索パラメータの型を定義
export interface ISearchParams {
    user: SearchUserOption;
    dateRangeValue?: RangeDetail;
    heartRange?: RangeDetail;
    averageHeartRange?: RangeDetail;
    stepCountRange?: RangeDetail;
    averageStepCountRange?: RangeDetail;
    temperatureRange?: RangeDetail;
    averageTemperatureRange?: RangeDetail;
    sleepTimeRange?: RangeDetail;
    averageSleepTimeRange?: RangeDetail;
}

// 検索アクションの型を定義
export interface ISearchAction {
    target: ISearchTarget;
    userOptions: SearchUserOption[];
    viewMode?: string;
    selectMode?: (viewMode: string) => void;
    setSearchParams: React.Dispatch<React.SetStateAction<ISearchParams>>;
    handleSearch: (params?: ISearchParams, page?: number) => void;
}

// 検索レンジの型を定義
export interface SearchRange {
    heartRateRange?: RangeDetail;
    averageHeartRateRange?: RangeDetail;
    stepCountRange?: RangeDetail;
    averageStepCountRange?: RangeDetail;
    temperatureRange?: RangeDetail;
    averageTemperatureRange?: RangeDetail;
    sleepTimeRange?: RangeDetail;
}

// レンジの詳細な型を定義
interface RangeDetail {
    start: string;
    end: string;
}

// 検索アクションタイプのenumを定義
export enum SearchActionTypes {
    SET_SEARCH_DATA = "healthcare/search_data_set",
}

// 検索ターゲットのenumを定義
export enum SearchTargetEnum {
    HEART_RATE = "heartRate",
    BODY_TEMPERATURE = "bodyTemperature",
    STEP_COUNT = "stepCount",
    SLEEP_SESSION = "sleepSession",
}

// ユーザーの型を定義
interface User {
    user_uid: string;
    name: string;
}

export interface UserHealthDatum extends User {
    date?: string;
    value?: number;
    avg?: string;
    sum?: string | number;
}

// 心拍データの型を定義
export interface HeartRate extends UserHealthDatum {
    avg: string;
}

// 体温データの型を定義
export interface BodyTemperature extends UserHealthDatum {
    avg: string;
}

// 歩数データの型を定義
export interface StepCount extends UserHealthDatum {
    sum: string;
}

// 睡眠データの型を定義
export interface SleepSession extends User {
    sleepType: string;
    date?: string;
    start_date?: string;
    end_date?: string;
    sleep_start?: string;
    sleep_end?: string;
    deep_sleep?: number;
    light_sleep?: number;
    clear_time?: number;
    sleep_duration?: number;
    sum: number;
    avg: string;
    deep?: number[];
    light?: number[];
    clear?: number[];
}

// 検索結果の型を定義
export interface ISearchResult {
    heartRate: {
        data: HeartRate[];
        total_pages: number;
        limit_value: number;
    };
    bodyTemperature: {
        data: BodyTemperature[];
        total_pages: number;
        limit_value: number;
    };
    stepCount: {
        data: StepCount[];
        total_pages: number;
        limit_value: number;
    };
    sleepSession: {
        data: SleepSession[];
        total_pages: number;
        limit_value: number;
    };
}

// 検索結果のアクションの型を定義
export interface ISearchResultSetAction {
    type: SearchActionTypes.SET_SEARCH_DATA;
    payload: ISearchResultState;
}

// 検索結果のステートの型を定義
export type ISearchResultState = ISearchResult;

// 初期の検索結果のステートを定義
export const initialHealthCare: ISearchResultState = {
    heartRate: {
        data: [],
        total_pages: 0,
        limit_value: 25,
    },
    bodyTemperature: {
        data: [],
        total_pages: 0,
        limit_value: 25,
    },
    stepCount: {
        data: [],
        total_pages: 0,
        limit_value: 25,
    },
    sleepSession: {
        data: [],
        total_pages: 0,
        limit_value: 25,
    },
};

export enum SearchUserOptionActionTypes {
    SET_SEARCH_USER_OPTIONS = "SET_SEARCH_USER_OPTIONS",
}

export interface SearchUserOptionSetAction {
    type: SearchUserOptionActionTypes.SET_SEARCH_USER_OPTIONS;
    payload: SearchUserOptionsState;
}
export interface SearchUserOption {
    value: string;
    label: string;
    name: string;
}

export type SearchUserOptionsState = SearchUserOption[];

export const initialSearchUserOptions: SearchUserOptionsState = [
    { value: "", label: "", name: "" },
];

export type HistoricalDataType = 'day' | 'week' | 'month';

export interface HistoricalData {
    start: string;
    end: string;
    heartRate: {
        average: number;
        minimum: number;
        maximum: number;
        chart_min_y: number;
        chart_max_y: number;
        chart_min_rough_indication: number;
        chart_max_rough_indication: number;
        chart_spots: {
            x_label: string;
            y_value: number | null;

        }
    }
    bodyTemperature: {
        average: number;
        minimum: number;
        maximum: number;
        chart_min_y: number;
        chart_max_y: number;
        chart_min_rough_indication: number;
        chart_max_rough_indication: number;
        chart_spots: {
            x_label: string;
            y_value: number | null;

        }
    }
    sleep: {
        total: number;
        average: number;
        light_sleep_percentage: number;
        deep_sleep_percentage: number;
        chart_min_y: 0;
        chart_max_y: number;
        chart_spots: {
            x_label: string;
            y_value: number | null;

        }
    }
    steps: {
        total_steps: number;
        total_distance: number;
        total_calories_burned: number;
        chart_min_y: 0;
        chart_max_y: number;
        chart_min_rough_indication: number;
        chart_max_rough_indication: number;
        chart_spots: {
            x_label: string;
            y_value: number | null;

        }
    }
}

export type HistoricalDataState = HistoricalData;

export enum HistoricalDataSetActionTypes {
    SET_HISTORICAL_DATA = 'SET_HISTORICAL_DATA'
}

export interface HistoricalDataSetAction {
    type: HistoricalDataSetActionTypes.SET_HISTORICAL_DATA;
    payload: HistoricalDataState;
}

export const initialHistoricalData: HistoricalData = {
    start: '',
    end: '',
    heartRate: {
        average: 0,
        minimum: 0,
        maximum: 0,
        chart_min_y: 0,
        chart_max_y: 0,
        chart_min_rough_indication: 0,
        chart_max_rough_indication: 0,
        chart_spots: {
            x_label: '',
            y_value: null,
        }
    },
    bodyTemperature: {
        average: 0,
        minimum: 0,
        maximum: 0,
        chart_min_y: 0,
        chart_max_y: 0,
        chart_min_rough_indication: 0,
        chart_max_rough_indication: 0,
        chart_spots: {
            x_label: '',
            y_value: null,
        }
    },
    sleep: {
        total: 0,
        average: 0,
        light_sleep_percentage: 0,
        deep_sleep_percentage: 0,
        chart_min_y: 0,
        chart_max_y: 0,
        chart_spots: {
            x_label: '',
            y_value: null,

        }
    },
    steps: {
        total_steps: 0,
        total_distance: 0,
        total_calories_burned: 0,
        chart_min_y: 0,
        chart_max_y: 0,
        chart_min_rough_indication: 0,
        chart_max_rough_indication: 0,
        chart_spots: {
            x_label: '',
            y_value: null,
        }
    }
}
