import axios from "axios";

async function deleteAnnouncement(token: string, id: string): Promise<void> {
	// DELETE /api/v1/company/company_announcements/{id}
	try {
		const res = await axios.delete(
			`${process.env.COMPANY_ADMIN_BASE}/company_announcements/${id}`,
			{
				headers: { Authorization: `${token}` },
			}
		);
		return res.data;
	} catch (e) {
		throw e;
	}
}

export default deleteAnnouncement;
