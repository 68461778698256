import * as React from "react";
import "./styles.scss";

interface TableProps {
  title?: string;
}

const Table: React.FC<TableProps> = (props) => {
  const { title, children } = props;
  return (
    <div className="table">
      {title && (
        <div className="table__heading">
          <div className="table__heading__title">{title}</div>
        </div>
      )}
      <div className="table__container">
        <table className="table__container__set">{children}</table>
      </div>
    </div>
  );
};

export default Table;
