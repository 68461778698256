import * as React from "react";
import { FaExclamationCircle, FaUser } from "react-icons/fa";
import { ActionButton } from "@kortvaluta/admin-twooca-react";
import "./styles.scss";

interface UploadAlertModalProps {
    title: string;
    open: boolean;
    errorMessageArr?: string[];
    toggleModal: (event: React.MouseEvent<HTMLElement>) => void;
}

const UploadAlertModal: React.FC<UploadAlertModalProps> = (props) => {
    const { title, open, errorMessageArr, toggleModal } = props;

    return (
        <div className={`alert_modal ${open ? "alert_modal--open" : ""}`}>
            <div
                className={`alert_modal__container ${
                    open ? "alert_modal__container--open" : ""
                }`}
            >
                <div className="alert_modal__container__content__circle"> </div>
                <div className="alert_modal__container__content__icon">
                    <FaExclamationCircle color="#ff1d25" />
                </div>
                <div className="alert_modal__container__header">
                    <div className="alert_modal__container__header__message">
                        {title}
                    </div>
                </div>
                <div className="alert_modal__container__content">
                    <div className="alert_modal__container__content__employee-number">
                        {errorMessageArr.length > 0 ? (
                            <>
                                <div className="alert_modal__container__content__employee-number__header">
                                    <div className="alert_modal__container__content__employee-number__header__icon">
                                        <FaUser />
                                    </div>
                                    <div className="alert_modal__container__content__employee-number__header__text">
                                        {`エラー（全${errorMessageArr.length}件）`}
                                    </div>
                                </div>
                                <div className="alert_modal__container__content__employee-number__items">
                                    {errorMessageArr.map((data, index) => {
                                        return (
                                            <li
                                                className="alert_modal__container__content__employee-number__item"
                                                key={index.toString()}
                                            >
                                                <span>{index}:&nbsp;</span>
                                                {data}
                                            </li>
                                        );
                                    })}
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="alert_modal__container__footer">
                        <div className="alert_modal__container__footer__btn">
                            <ActionButton
                                text="閉じる"
                                color="danger"
                                onClick={toggleModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadAlertModal;
