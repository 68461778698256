import axios from "axios";
import Qs from "qs";
import { IMonthlyParams } from "../transactions/models";
import { ICategorySummaryResponse } from "./models";

const getCategorySummary = async (
	token: string,
	months: IMonthlyParams
): Promise<ICategorySummaryResponse> => {
	try {
		// GET /api/v1/company/category_summary
		const res = await axios.get(
			`${process.env.COMPANY_ADMIN_BASE}/category_summary`,
			{
				headers: { Authorization: `${token}` },
				params: {
					q: {
						...months,
					},
				},
				paramsSerializer: function (params) {
					return Qs.stringify(params, { arrayFormat: "brackets" });
				},
			}
		);
		return res.data;
	} catch (e) {
		throw e;
	}
};

export default getCategorySummary;
