import axios from "axios";
import { IEmployeeExampleCsv } from "./models";

const getEmployeeExampleCsv = async (token: string): Promise<string> => {
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/employees/download_example_csv`,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
};

export default getEmployeeExampleCsv;
