import * as React from "react";
import "./styles.scss";

interface IErrorTextProps {
    text: string;
}

const ErrorText: React.FC<IErrorTextProps> = ({ text }) => {
    return <p className="error-text">{text}</p>;
};

export default ErrorText;
