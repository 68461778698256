import axios from "axios";
import { passwordUrl } from "../../constants";
import { IPasswordRequest } from "./models";

const requestPassword = async (
    body: IPasswordRequest
): Promise<IPasswordRequest> => {
    try {
        const res = await axios.post(passwordUrl, body);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export default requestPassword;
