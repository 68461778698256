import * as React from "react";
import "./styles.scss";

interface IHeaderTitleProps {
    title: string;
}

const HeaderTitle: React.VFC<IHeaderTitleProps> = ({ title }) => {
    return (
        <div className="header">
            <div className="header__title">{title}</div>
        </div>
    );
};

export default HeaderTitle;
