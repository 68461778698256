import axios from "axios";
import { IEmployeeExampleZip } from "./models";

const getEmployeeExampleZip = async (token: string): Promise<string> => {
	try {
		const res = await axios.get(
			`${process.env.COMPANY_ADMIN_BASE}/employees/download_example_zip`,
			{
				headers: { Authorization: `${token}` },
			}
		);
		return res.data;
	} catch (e) {
		throw e;
	}
};

export default getEmployeeExampleZip;
