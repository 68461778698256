import axios from "axios";
import { IContactParams } from "./models";

async function postKvContact(
	token: string,
	body: IContactParams
): Promise<{ message: string }> {
	// POST /api/v1/company/kv_contacts
	try {
		const res = await axios.post(
			`${process.env.COMPANY_ADMIN_BASE}/kv_contacts`,
			body,
			{
				headers: { Authorization: `${token}` },
			}
		);
		return res.data;
	} catch (e) {
		throw e;
	}
}

export default postKvContact;
