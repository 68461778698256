export enum TransactionActionTypes {
    PRODUCT_TRANSACTIONS_SET = "transaction/product-transactions",
    EMPLOYEES_TRANSACTIONS_SET = "transaction/employees-transactions",
    EMPLOYEE_TRANSACTIONS_SET = "transaction/employee-transactions",
    EMPLOYEE_TRANSACTIONS_CSV_SET = "transaction/employee-transactions-csv",
}

export interface IProductTransaction {
    amount: number;
    provider_name: string;
    description: string;
    count: number;
}

export interface IProductTransactions {
    perk_transactions: Array<IProductTransaction>;
    total_pages: number;
    limit_value: number;
}

export interface IEmployeesTransaction {
    employee_code: string;
    uid: string;
    amount: 0;
    kind: string;
    provider_name: string;
    description: string;
    created_at: string;
}

export interface IEmployeesTransactions {
    perk_transactions: Array<IEmployeesTransaction>;
    total_pages: number;
    limit_value: number;
}

export interface IEmployeeTransaction {
    employee_code: string;
    uid: string;
    amount: 0;
    kind: string;
    provider_name: string;
    description: string;
    created_at: string;
}
export interface IEmployeeTransactions {
    perk_transactions: Array<IEmployeeTransaction>;
    perk_balance?: number;
    total_pages: number;
    limit_value: number;
}

export interface IEmployeesTransactionRes {
    token: string;
    data: IEmployeeTransactions;
}

export interface IMonthlyParams {
    created_at_gteq: string;
    created_at_lteq: string;
}

export interface IDateParams {
    year: number;
    month: number;
}

export interface IEmployeesTransactionsParams {
    employee_info_employee_code_cont?: string;
    provider_name_cont?: string;
    description_cont?: string;
}

export interface IEmployeeTransactionsParams {
    provider_name_cont?: string;
    description_cont?: string;
}

export interface IProductTransactionsParams {
    provider_name_cont?: string;
    description_cont?: string;
}

export interface IProductTransactionAction {
    type: TransactionActionTypes.PRODUCT_TRANSACTIONS_SET;
    payload: IProductTransactions;
}

export interface IEmployeesTransactionsAction {
    type: TransactionActionTypes.EMPLOYEES_TRANSACTIONS_SET;
    payload: IEmployeeTransactions;
}

export interface IEmployeeTransactionsAction {
    type: TransactionActionTypes.EMPLOYEE_TRANSACTIONS_SET;
    payload: IEmployeeTransactions;
}

export interface IEmployeeTransactionsCsvAction {
    type: TransactionActionTypes.EMPLOYEE_TRANSACTIONS_CSV_SET;
    payload: {
        csv: string | null;
    };
}

export interface ITransactionState {
    products: {
        perk_transactions: Array<IProductTransaction>;
        total_pages: number;
        limit_value: number;
    };
    employees: IEmployeeTransactions;
    employee: IEmployeeTransactions;
    csv: string | null;
}

//initial value

export const initialDateParams: IDateParams = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
};

export const initialTransactionState: ITransactionState = {
    products: {
        perk_transactions: [],
        total_pages: 1,
        limit_value: 25,
    },
    employees: {
        perk_transactions: [],
        total_pages: 1,
        limit_value: 25,
    },
    employee: {
        perk_transactions: [],
        total_pages: 1,
        limit_value: 25,
    },
    csv: null,
};
