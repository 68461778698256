import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";

interface Option {
    label: string;
    value: string;
}

interface SelectPickerProps {
    data: Option[];
    value: string;
    placeholder: string;
    onChange: (value: any) => void;
    style?: React.CSSProperties;
}

const SelectPicker: React.FC<SelectPickerProps> = ({
    data,
    value,
    onChange,
    style,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleSelect = (selectedValue: string) => {
        onChange(selectedValue);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={ref} className="selectPicker" style={style}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="selectPickerHeader"
            >
                {data.find((option) => option.value === value)?.label ||
                    placeholder}
            </div>
            {isOpen && (
                <ul className="selectPickerOptions">
                    {data.map((option) => (
                        <li
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                            className="selectPickerOption"
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectPicker;
