export function compare(a: any, b: any, reverse: any, key: string) {
  // Use toUpperCase() to ignore character casing
  const bandA = a[key].toString().toUpperCase();
  const bandB = b[key].toString().toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  if (reverse) return comparison * -1;
  return comparison;
}

export function compareAmount(a: any, b: any, reverse: any, key: string) {
  // Use toUpperCase() to ignore character casing
  const bandA = a[key];
  const bandB = b[key];

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  if (reverse) return comparison * -1;
  return comparison;
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const searchOptions = [
  {
    text: "サービス名",
    value: "provider_name_cont",
  },
  {
    text: "詳細",
    value: "description_cont",
  },
];
