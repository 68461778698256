import * as React from "react";
import "./styles.scss";

interface RadioProps {
    value: string;
    selected: string;
    label: string;
    name?: string;
    isBool?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBoolChange?: (val: boolean, key: string) => void;
}

const Radio: React.FC<RadioProps> = ({
    value,
    selected,
    label,
    name,
    isBool,
    onChange,
    onBoolChange,
}) => {
    const checked = value === selected ? true : false;
    return (
        <div className="radio">
            <label className="custom-radio-btn">
                <input
                    type="radio"
                    name={name}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        isBool
                            ? onBoolChange(
                                  e.currentTarget.value === "true",
                                  name
                              )
                            : onChange(e);
                    }}
                />
                <span
                    className={`checkmark ${
                        checked ? "checkmark--active" : ""
                    }`}
                ></span>
            </label>
            <label className="custom-radio-btn__label">{label}</label>
        </div>
    );
};

export default Radio;
