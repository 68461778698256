import axios from "axios";
import { IAnnouncementsParams } from "./models";

async function putAnnouncement(
    token: string,
    id: string,
    body: IAnnouncementsParams
): Promise<void> {
    // PUT /api/v1/company/company_announcements
    try {
        const res = await axios.put(
            `${process.env.COMPANY_ADMIN_BASE}/company_announcements/${id}`,
            body,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return res.data;
    } catch (e) {
        throw e;
    }
}

export default putAnnouncement;
