import * as Models from "./models";

export function notificationSet(
    notification: Models.INotification
): Models.INotificationSet {
    localStorage.setItem("notifyMsg", notification.kv_notification);
    const show = notification.show ? "true" : "false";
    localStorage.setItem("notifyShow", show);
    return {
        type: Models.NotificationActionTypes.NOTIFICATION_SET,
        payload: notification,
    };
}

export function notificationHideSet(
    show: boolean
): Models.INotificationHideSet {
    const value = show ? "true" : "false";
    localStorage.setItem("notifyShow", value);
    return {
        type: Models.NotificationActionTypes.NOTIFICATION_HIDE_SET,
        payload: show,
    };
}
