import axios from "axios";
import Qs from "qs";
import { employeeTransactionsUrl } from "../../constants";
import {
	IMonthlyParams,
	IEmployeesTransactionsParams,
	IEmployeesTransactionRes,
} from "./models";

const getEmployeeTransactions = async (
	token: string,
	uid: string,
	page: number,
	months: IMonthlyParams,
	searchParams: IEmployeesTransactionsParams
): Promise<IEmployeesTransactionRes> => {
	try {
		const res = await axios.get(
			`${employeeTransactionsUrl}/${uid}/perk_transactions`,
			{
				headers: { Authorization: `${token}` },
				params: {
					page,
					q: {
						...months,
						...searchParams,
					},
				},
				paramsSerializer: function (params) {
					return Qs.stringify(params, { arrayFormat: "brackets" });
				},
			}
		);
		return {
			token: res.headers.authorization,
			data: res.data,
		};
	} catch (e) {
		throw e;
	}
};

export default getEmployeeTransactions;
