import * as Models from "./models";

export function companyServiceSet(
	service: Models.ICompanyServiceState
): Models.ICompanyServiceAction {
	localStorage.setItem("services", JSON.stringify(service.list));
	return {
		type: Models.CompanyServiceActionTypes.COMPANY_SERVICE_SET,
		payload: service,
	};
}
