import * as Models from "./models";

export function navigationReducer(
  state = Models.initialNavigationState,
  action: Models.INavigationActiveAction
): Models.IActiveId {
  switch (action.type) {
    case Models.NavigationActionTypes.NAVIGATION_ACTIVE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
