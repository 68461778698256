import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { FaTimes, FaQuestion } from "react-icons/fa";
import {
    ActionButton,
    Alert,
    FileInput,
    Label,
} from "@kortvaluta/admin-twooca-react";
import { CSSTransition } from "react-transition-group";
import { IEmployeeExampleState } from "../../../store/employee/models";
import "./styles.scss";
import { downloadAsFile } from "../../screens/employeesHistory/helpers";

interface UploadModalProps {
    isLoading: boolean;
    open: boolean;
    example: IEmployeeExampleState;
    toggleModal: (event: React.MouseEvent<HTMLElement>) => void;
    submit: (uploadedFile: File) => void;
}

const UploadModal: React.FC<UploadModalProps> = (props) => {
    const { isLoading, open, example, toggleModal, submit } = props;
    const node = useRef<HTMLDivElement>(null);
    const [uploadedFile, setUploadedFile] = useState<null | File>(null);
    const [filename, setFilename] = useState("");
    const [toggleFile, setToggleFile] = useState(false);
    const [inputError, setInputError] = useState(false);

    const handleClickOutside = (event: any) => {
        if (node.current.contains(event.target)) {
            // inside click
            return;
        }
        // outside click
        toggleModal(event);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    useEffect(() => {
        // check if file is null
        if (uploadedFile) {
            setFilename(uploadedFile.name);
            setToggleFile(true);
        } else setFilename("");
    }, [uploadedFile]);

    return (
        <div className={`upload-modal ${open ? "modal--open" : ""}`}>
            <div
                className={`upload-modal__container ${
                    open ? "upload-modal__container--open" : ""
                }`}
                ref={node}
            >
                <div className="upload-modal__container__header">
                    <button
                        className="upload-modal__container__close"
                        onClick={toggleModal}
                        aria-label="close"
                    >
                        <FaTimes />
                    </button>
                </div>
                <div className="upload-modal__container__content">
                    <div className="upload-modal__container__content__icon">
                        <FaQuestion />
                    </div>
                    <div className="upload-modal__container__content__message">
                        <span>＊</span>
                        CSVファイルが正しい書式であることを確認してください。
                    </div>
                    <div className="upload-modal__container__content__file-input">
                        <FileInput
                            text="アップロードするファイルを選択してください。"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (event.currentTarget.files.length > 0) {
                                    setUploadedFile(
                                        event.currentTarget.files[0]
                                    );
                                }
                            }}
                        />
                    </div>
                    <CSSTransition
                        in={toggleFile}
                        timeout={200}
                        unmountOnExit
                        classNames="login__form__alert"
                    >
                        <Label text={filename} />
                    </CSSTransition>
                    <CSSTransition
                        in={inputError}
                        timeout={200}
                        unmountOnExit
                        classNames="login__form__alert"
                    >
                        <Alert
                            text="CSVファイルを選択してください"
                            color="danger"
                            close={() => setInputError(false)}
                        />
                    </CSSTransition>
                </div>
                <div className="upload-modal__container__footer">
                    <div className="upload-modal__container__footer__btn">
                        <ActionButton
                            text="サンプルの書式をダウンロード"
                            color="secondary"
                            disabled={isLoading}
                            onClick={() => {
                                if (example.csv_file) {
                                    downloadAsFile(
                                        example.csv_file,
                                        "サンプルファイル"
                                    );
                                }
                            }}
                        />
                    </div>
                    <div className="upload-modal__container__footer__spacer" />

                    <div className="upload-modal__container__footer__btn">
                        <ActionButton
                            text="アップロードする"
                            color="info"
                            disabled={isLoading}
                            onClick={() => {
                                if (uploadedFile) {
                                    if (
                                        uploadedFile.type === "text/csv" ||
                                        uploadedFile.type ===
                                            "application/octet-stream" ||
                                        uploadedFile.type ===
                                            "application/vnd.ms-excel"
                                    ) {
                                        submit(uploadedFile);
                                        toggleModal(null);
                                    } else setInputError(true);
                                } else {
                                    setInputError(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadModal;
