import {
    FlexBox,
    Page,
    Paginate,
    Panel,
    Row,
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Title,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMonthsParams } from "../../../helpers/dateFormatter";
import useInput from "../../../hooks/useInput";
import useSelect from "../../../hooks/useSelect";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import {
    IDateParams,
    IMonthlyParams,
    initialDateParams,
    IProductTransaction,
} from "../../../store/transactions/models";
import { thunkGetProductTransactions } from "../../../store/transactions/thunk";
import Amount from "../../shared/amount/Amount";
import EmptyBox from "../../shared/box/empty/EmptyBox";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ClearButton from "../../shared/button/clear/ClearButton";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";
import { compare, compareAmount, searchOptions } from "./helpers";
import SearchHistory from "../../shared/search/SearchHistory";
import useWindowSize from "../../../hooks/useWindowSize";

const HistoryScreen: React.FC = () => {
    //global store
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const transactions: IProductTransaction[] = useSelector(
        (store: RootState) => store.transactions.products.perk_transactions
    );
    const totalPages: number = useSelector(
        (store: RootState) => store.transactions.products.total_pages
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const size = useWindowSize();

    //local state
    const [dateParams, setDateParams] =
        useState<IDateParams>(initialDateParams);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [data, setData] = useState<IProductTransaction[]>(transactions);
    const [selected, setSelected, resetSelect] =
        useSelect("provider_name_cont");
    const [input, setInput, resetInput] = useInput("");
    const [countSort, setCountSort] = useState(false);
    const [amountSort, setAmountSort] = useState(false);

    //dispatch
    const dispatch = useDispatch();

    //GET transactions
    const handleGetTransactions = (searchParams = {}) => {
        dispatch(
            thunkGetProductTransactions(
                token,
                currentPage,
                formatMonthsParams(dateParams.month, dateParams.year),
                searchParams
            )
        );
    };

    //useEffect
    useSetActiveId('products-history');

    useEffect(() => {
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        dispatch(
            thunkGetProductTransactions(token, currentPage, monthlyParams, {})
        );
    }, []);

    useEffect(() => {
        if (currentPage > 1) handleGetTransactions();
    }, [currentPage]);

    useEffect(() => {
        if (transactions) setData(transactions);
    }, [transactions]);

    //method
    const handleSetDateRange = (monthNum: number, yearNum: number) => {
        setMonth(monthNum);
        setYear(yearNum);
        const monthlyParams: IMonthlyParams = formatMonthsParams(
            monthNum,
            yearNum
        );
        dispatch(
            thunkGetProductTransactions(token, currentPage, monthlyParams, {})
        );
    };

    const handleOnSort = (e) => {
        e.preventDefault();
        const params = {
            [selected]: input,
        };
        handleGetTransactions(params);
    };

    const handleResetFilter = () => {
        resetInput("");
        resetSelect("provider_name_cont");
        setCurrentPage(1);
        setDateParams(initialDateParams);
    };

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <SpaceBox my="md">
                        {size.width >= 780 ? (
                            <Row>
                                <SearchHistory
                                    month={month}
                                    year={year}
                                    handleSetDateRange={handleSetDateRange}
                                    input={input}
                                    selected={selected}
                                    handleOnSort={handleOnSort}
                                    setSelected={setSelected}
                                    setInput={setInput}
                                    searchOptions={searchOptions}
                                />
                            </Row>
                        ) : (
                            <SearchHistory
                                month={month}
                                year={year}
                                handleSetDateRange={handleSetDateRange}
                                input={input}
                                selected={selected}
                                handleOnSort={handleOnSort}
                                setSelected={setSelected}
                                setInput={setInput}
                                searchOptions={searchOptions}
                            />
                        )}
                    </SpaceBox>
                    <Panel>
                        <FlexBox justify="end">
                            <ClearButton onClick={handleResetFilter} />
                        </FlexBox>
                        {data && data.length ? (
                            <Table title="商品の利用明細">
                                <TableHead>
                                    <Tr>
                                        <Th>サービス名</Th>
                                        <Th>会社名</Th>
                                        <Th
                                            sortable={true}
                                            onSort={() => {
                                                setData(
                                                    data.sort((a, b) => {
                                                        return compare(
                                                            a,
                                                            b,
                                                            countSort,
                                                            "count"
                                                        );
                                                    })
                                                );
                                                setCountSort(!countSort);
                                            }}
                                            toggle={countSort}
                                        >
                                            利用数
                                        </Th>
                                        <Th
                                            sortable={true}
                                            onSort={() => {
                                                setData(
                                                    data.sort((a, b) => {
                                                        return compareAmount(
                                                            a,
                                                            b,
                                                            amountSort,
                                                            "amount"
                                                        );
                                                    })
                                                );
                                                setAmountSort(!amountSort);
                                            }}
                                            toggle={amountSort}
                                        >
                                            金額 (pt)
                                        </Th>
                                    </Tr>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <Tr key={index.toString()}>
                                            <Td>{item.description}</Td>
                                            <Td>{item.provider_name}</Td>
                                            <Td>{item.count}</Td>
                                            <Td>
                                                <Amount value={item.amount} />
                                            </Td>
                                        </Tr>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <SpaceBox px="md">
                                <Title title="商品の利用明細" />
                                <SpaceBox my="md">
                                    <EmptyBox text="現在利用履歴はありません。" />
                                </SpaceBox>
                            </SpaceBox>
                        )}
                        {totalPages > 1 && (
                            <Paginate
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            />
                        )}
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default HistoryScreen;
