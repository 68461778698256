import { AnyAction } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { errorSet, errorReset } from "../error/actions";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import { IContactParams } from "./models";
import postKvContact from "./postKvContacts";
import { contactMessageSet, contactSuccessSet } from "./actions";

export const thunkPostContact =
    (
        token: string,
        body: IContactParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(contactSuccessSet(false));
        // clear error
        dispatch(errorReset());
        try {
            // call API
            const contactRes: { message: string } = await postKvContact(
                token,
                body
            );
            dispatch(contactMessageSet(contactRes.message));
            dispatch(contactSuccessSet(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(contactSuccessSet(false));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };
