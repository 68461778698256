import React from "react";
import { DatePicker as RSuiteDatePicker } from "rsuite";
import { isAfter, isSameDay, startOfDay } from "date-fns";
import "./styles.scss";

interface DatePickerProps {
    value: Date;
    onChange: (date: Date) => void;
    format?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
    value,
    onChange,
    format = "YYYY年MM月DD日",
}) => {
    const disableFutureDates = (date: Date) => {
        const today = startOfDay(new Date());
        return !isSameDay(date, today) && isAfter(date, today);
    };

    return (
        <RSuiteDatePicker
            value={value}
            onChange={onChange}
            format={format}
            disabledDate={disableFutureDates}
            placeholder="日付を選択"
            cleanable={false}
            locale={{
                sunday: "日",
                monday: "月",
                tuesday: "火",
                wednesday: "水",
                thursday: "木",
                friday: "金",
                saturday: "土",
                ok: "確認",
                today: "今日",
                yesterday: "昨日",
            }}
            oneTap
            isoWeek
            block
        />
    );
};

export default DatePicker;
