import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import Paginate from "../../../../shared/pagination/Paginate";
import {
    ISearchTarget,
    UserHealthDatum,
} from "../../../../../store/healthcareSearch/models";
import { formatDate, formatDuration, healthDataHeaders } from "../helpers";

interface DataTableProps {
    data: UserHealthDatum[];
    showDataType: ISearchTarget;
    totalPages: number;
    currentPage: number;
    viewMode: "record" | "summary";
    onPageChange: (page: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
    data,
    showDataType,
    totalPages,
    currentPage,
    viewMode,
    onPageChange,
}) => {
    const [formattedData, setFormattedData] = useState<any[]>([]);

    useEffect(() => {
        const formatted = formatData(data);
        setFormattedData(formatted);
    }, [data, viewMode, showDataType]);

    const formatData = (data: any[]): any[] => {
        return data.map((item) => ({
            ...item,
            date: formatDate(
                item.date,
                viewMode === "record" && showDataType !== "睡眠"
            ),
            sleep_start: item.sleep_start
                ? formatDate(item.sleep_start, true, true)
                : undefined,
            sleep_end: item.sleep_end
                ? formatDate(item.sleep_end, true, true)
                : undefined,
            total_sleep_time: item.total_sleep_time
                ? formatDuration(item.total_sleep_time)
                : undefined,
            sleep_duration: item.sleep_duration
                ? formatDuration(item.sleep_duration)
                : undefined,
            deep_sleep: item.deep_sleep
                ? formatDuration(item.deep_sleep)
                : undefined,
            light_sleep: item.light_sleep
                ? formatDuration(item.light_sleep)
                : undefined,
            clear_time: item.clear_time
                ? formatDuration(item.clear_time)
                : undefined,
        }));
    };

    const renderTableHeader = (): JSX.Element => (
        <Tr>
            {healthDataHeaders[showDataType][viewMode].map((header, index) => (
                <Th key={index}>{header}</Th>
            ))}
        </Tr>
    );

    const renderTableBody = (): JSX.Element => (
        <>
            {formattedData.map((item, index) => (
                <Tr key={index}>
                    <Td>{item.name}</Td>
                    <Td>{item.date}</Td>
                    {viewMode === "record" && showDataType === "睡眠" ? (
                        <>
                            <Td>{item.sleep_start}</Td>
                            <Td>{item.sleep_end}</Td>
                            <Td>{item.deep_sleep}</Td>
                            <Td>{item.light_sleep}</Td>
                            <Td>{item.clear_time}</Td>
                            <Td>{item.sleep_duration}</Td>
                        </>
                    ) : viewMode === "summary" && showDataType === "睡眠" ? (
                        <>
                            <Td>{item.sleep_start}</Td>
                            <Td>{item.sleep_end}</Td>
                            <Td>{item.total_sleep_time}</Td>
                        </>
                    ) : viewMode === "record" ? (
                        <Td>{item.value}</Td>
                    ) : (
                        <>
                            <Td>{item.min}</Td>
                            <Td>{item.max}</Td>
                            <Td>{Number(item.avg).toFixed(1)}</Td>
                            {showDataType === "歩数" && <Td>{item.total}</Td>}
                        </>
                    )}
                </Tr>
            ))}
        </>
    );

    return (
        <>
            <div className="table-scroll">
                <Table>
                    <TableHead>{renderTableHeader()}</TableHead>
                    <TableBody>{renderTableBody()}</TableBody>
                </Table>
            </div>
            {totalPages > 1 && (
                <Paginate
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            )}
        </>
    );
};

export default DataTable;
