export const downloadAsFile = (csv: string, filename: string) => {
    const utf8Array = new TextEncoder().encode(csv);
    const blob = new Blob([utf8Array], { type: 'text/csv;charset=utf-8;' });
    const a = document.createElement("a");
    a.download = `${filename}.csv`;
    a.href = URL.createObjectURL(blob);
    a.textContent = "download";
    document.body.append(a);
    a.click();
};

export const searchOptions = [
    {
        text: "サービス名",
        value: "provider_name_cont",
    },
    {
        text: "詳細",
        value: "description_cont",
    },
];
