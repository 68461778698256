import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/index";
import { IError } from "../../../store/error/models";
import { errorReset, errorSet } from "../../../store/error/actions";
import { thunkPasswordReset } from "../../../store/session/thunk";
import { IPasswordReset } from "../../../store/session/models";
import PasswordResetView from "./PasswordResetView";

interface PasswordResetContainerProps {
    success: boolean;
    setError: (error: IError) => void;
    resetError: () => void;
    handlePasswordReset: (body: IPasswordReset) => void;
}

const PasswordResetContainer: React.FC<PasswordResetContainerProps> = (
    props
) => {
    const { success, setError, resetError, handlePasswordReset } = props;
    const { mailToken } = useParams();
    const [token, setToken] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    useEffect(() => {
        if (mailToken) setToken(mailToken);
    }, [setToken, token]);

    useEffect(() => {
        resetError();
    }, [resetError]);

    // input onchange
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetError();
        if (event.target.name === "new-password") {
            setNewPass(event.currentTarget.value);
        } else {
            setConfirmPass(event.currentTarget.value);
        }
    };
    // submit
    const submit = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // 入力が空かどうか調べる
        if (newPass.length > 0 && confirmPass.length > 0) {
            resetError();
            const body = {
                company_admin_user: {
                    reset_password_token: token,
                    password: newPass,
                    password_confirmation: confirmPass,
                },
            };
            handlePasswordReset(body);
        } else {
            // 入力が空の場合
            setError({
                hasError: true,
                errorType: "入力",
                errorMessage: "入力内容に誤りがあります",
            });
        }
    };
    return (
        <PasswordResetView
            newPass={newPass}
            confirmPass={confirmPass}
            success={success}
            onChange={onChange}
            submit={submit}
        />
    );
};

const MapStateToProps = (store: RootState) => {
    return {
        success: store.employeesInfo.success,
    };
};
const MapDispatchToProps = (
    dispatch: ThunkDispatch<RootState, null, AnyAction>
) => {
    return {
        setError: (error: IError) => {
            dispatch(errorSet(error));
        },
        resetError: () => {
            dispatch(errorReset());
        },
        handlePasswordReset: (body: IPasswordReset) => {
            dispatch(thunkPasswordReset(body));
        },
    };
};

export default connect(
    MapStateToProps,
    MapDispatchToProps
)(PasswordResetContainer);
