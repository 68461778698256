import {
    ActionButton,
    Column,
    FlexBox,
    IconButton,
    SearchFilter,
} from "@kortvaluta/admin-twooca-react";
import React from "react";
import { FaCloudUploadAlt, FaUserPlus } from "react-icons/fa";
import SpaceBox from "../box/space/SpaceBox";
import { Link } from "react-router-dom";
import { searchOptions, searchEmployeeStatusOptions } from "../../screens/management/helpers";

const SearchColumn = ({
    toggle,
    setToggle,
    inputVal,
    selected,
    handleSubmit,
    updateSelected,
    updateInput,
    setModalOpen,
    updateAdditionalSelected,
    additionalSelected,
}) => {
    return (
        <>
            <Column xl={5}>
                <FlexBox>
                    <SpaceBox w={100}>
                        <ActionButton
                            text="リスト表示"
                            color={toggle ? "primary" : "light"}
                            onClick={() => {
                                setToggle(true);
                            }}
                        />
                    </SpaceBox>
                    <SpaceBox w={100}>
                        <ActionButton
                            text="タイル表示"
                            color={toggle ? "light" : "primary"}
                            onClick={() => {
                                setToggle(false);
                            }}
                        />
                    </SpaceBox>
                </FlexBox>
            </Column>
            <Column xl={7}>
                <FlexBox direction="row" align="center">
                    <SearchFilter
                        options={searchOptions}
                        inputValue={inputVal}
                        selectValue={selected}
                        onSubmit={handleSubmit}
                        searchSubmit={handleSubmit}
                        selectOnChange={updateSelected}
                        inputOnChange={updateInput}
                        additional={{
                            selectValue: additionalSelected,
                            selectOnChange: updateAdditionalSelected,
                            options: searchEmployeeStatusOptions
                        }}
                    />
                    <SpaceBox mx="sm">
                        <Link to="/employees/new" aria-label="add new user">
                            <IconButton
                                icon={<FaUserPlus />}
                                color="warning"
                                size="md"
                            />
                        </Link>
                    </SpaceBox>
                    <SpaceBox mx="sm">
                        <IconButton
                            icon={<FaCloudUploadAlt aria-label="upload file" />}
                            color="info"
                            size="md"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        />
                    </SpaceBox>
                </FlexBox>
            </Column>
        </>
    );
};

export default SearchColumn;
