import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../store/index";
import { IError } from "../store/error/models";
import { errorReset } from "../store/error/actions";
import { sessionStatus } from "../store/session/actions";
import Routes from "./routes/Routes";
import Loading from "./shared/loading/Loading";
import LoadingBar from "react-redux-loading-bar";

interface AppProps {
    status: boolean;
    error: IError;
    isLoading: boolean;
    handleResetError: () => void;
    handleSession: () => void;
}

const App: React.FC<AppProps> = (props) => {
    // session status
    const { status, error, isLoading, handleResetError, handleSession } = props;

    useEffect(() => {
        if (error.errorType === "401") {
            handleSession();
        }
    }, [error.errorType]);

    return (
        <div className="wrapper">
            <div className="wrapper__loading">
                <LoadingBar className="loading-bar" />
            </div>
            <Loading isLoading={isLoading} />
            <Routes
                status={status}
                title={""}
                isAlert={error.hasError}
                errorMessage={error.errorMessage}
                closeAlert={handleResetError}
            />
        </div>
    );
};

const MapStateToProps = (store: RootState) => {
    return {
        status: store.session.status,
        error: store.errors.error,
        isLoading: store.loadingBar.default === 1 ? true : false,
    };
};
const MapDispatchToProps = (
    dispatch: ThunkDispatch<RootState, null, AnyAction>
) => {
    return {
        handleResetError: () => {
            dispatch(errorReset());
        },
        handleSession: () => {
            dispatch(sessionStatus({ token: "", status: false }));
        },
    };
};

export default connect(MapStateToProps, MapDispatchToProps)(App);
