import * as React from "react";
import "./styles.scss";
interface ActionButtonProps {
  text?: string;
  icon?: React.ReactNode;
  color?: "darkBlue" | "primary";
  outline?: boolean;
  submit?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ActionBtn: React.FC<ActionButtonProps> = (props) => {
  const { text, icon, color, outline, submit, disabled, onClick } = props;
  const setClassName = () => {
    let className = "action-button";
    if (outline)
      className = className.concat(
        ` action-button--outline-${color ? color : "default"}`
      );
    if (color) className = className.concat(` action-button--${color}`);
    if (disabled) className = className.concat(` disable`);
    return className;
  };
  if (submit) {
    return (
      <button type="submit" className={setClassName()} onClick={onClick}>
        {text}
      </button>
    );
  }
  return (
    <button className={setClassName()} onClick={onClick} disabled={disabled}>
      <div className="action-button--icon">{icon}</div>
      <div className="action-button--text">{text}</div>
    </button>
  );
};

export default ActionBtn;
