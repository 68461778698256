import {
    ActionButton,
    FlexBox,
    Label,
    Page,
    Panel,
    Table,
    TableBody,
    Td,
    Title,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormatter } from "../../../helpers/dateFormatter";
import { RootState } from "../../../store";
import {
    IEmployeeUpdate,
    IEmployeeUpdatedImageData,
} from "../../../store/employee/models";
import { thunkUpdateEmployee } from "../../../store/employee/thunk";
import { checkFormValue } from "../../shared/forms/employee/helpers";
import PageBackground from "../../shared/page/PageBackground";
import Profile from "../../shared/profile/Profile";
import { checkStatus } from "../../shared/tables/list/helpers";
import "./styles.scss";

const EditPreviewScreen: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    //global state
    const employee: IEmployeeUpdate = useSelector(
        (store: RootState) => store.employeesInfo.employee
    );
    const updatedFaceImage: IEmployeeUpdatedImageData = useSelector(
        (store: RootState) => store.employeesInfo.updatedImageData
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );

    const success: boolean = useSelector(
        (store: RootState) => store.employeesInfo.success
    );
    const [previewEmployee, setPreviewEmployee] =
        useState<IEmployeeUpdate | null>(null);

    useEffect(() => {
        if (employee) {
            const data = {};
            Object.keys(employee).filter((item) => {
                if (item !== "user_uid" && item !== "face_image") {
                    data[item] = employee[item];
                }
            });
            setPreviewEmployee(employee);
        }
    }, [employee]);

    useEffect(() => {
        if (success) navigate("/employees/list");
    }, [success]);

    const handleUpdateEmployee = (): void => {
        dispatch(
            thunkUpdateEmployee(
                token,
                previewEmployee,
                employee.user_uid,
                updatedFaceImage.formData
            )
        );
    };

    if (!previewEmployee) return null;
    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <div className="edit-preview">
                    <Panel>
                        <Title title="入力情報" />
                        <Table>
                            <TableBody>
                                <Tr>
                                    <Td>
                                        <Label text="社員番号" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {checkFormValue(
                                                previewEmployee.employee_code
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="氏名" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${previewEmployee.last_name} ${previewEmployee.first_name}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="氏名(英)" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${checkFormValue(
                                                previewEmployee.last_name_en
                                            )} ${checkFormValue(
                                                previewEmployee.first_name_en
                                            )}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="フリガナ" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${checkFormValue(
                                                previewEmployee.last_name_furi
                                            )} ${checkFormValue(
                                                previewEmployee.first_name_furi
                                            )}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label
                                            text="メールアドレス"
                                            bold={true}
                                        />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {checkFormValue(
                                                previewEmployee.email
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="性別" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {checkFormValue(
                                                previewEmployee.gender
                                            ) &&
                                            checkFormValue(
                                                previewEmployee.gender
                                            ) === "female"
                                                ? "女性"
                                                : "男性"}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="生年月日" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {checkFormValue(
                                                previewEmployee.birthday
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="郵便番号" bold={true} />
                                    </Td>
                                    <Td>
                                        <span className="employee-form__group__item__postcode" />
                                        <div className="employee-detail__item">
                                            {checkFormValue(
                                                previewEmployee.postcode
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="住所 (市区町村番地)" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${checkFormValue(
                                                previewEmployee.address
                                            )}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="住所 (ビル名・部屋番号)" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${checkFormValue(
                                                previewEmployee.address2
                                            )}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="TEL" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {`${checkFormValue(
                                                previewEmployee.tel
                                            )}`}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="顔写真" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {updatedFaceImage &&
                                            updatedFaceImage.image ? (
                                                <Profile
                                                    image={`${updatedFaceImage.image}`}
                                                    description={
                                                        previewEmployee.last_name
                                                    }
                                                />
                                            ) : (
                                                <div className="title-item__icon">
                                                    <FaUserCircle />
                                                </div>
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        <Label text="状態" bold={true} />
                                    </Td>
                                    <Td>
                                        <div className="employee-detail__item">
                                            {employee.retired_at
                                                ? new Date(
                                                      employee.retired_at
                                                  ) <= new Date()
                                                    ? "退職"
                                                    : checkStatus(
                                                          employee.status
                                                      )
                                                : checkStatus(employee.status)}
                                        </div>
                                    </Td>
                                </Tr>
                                {employee.retired_at ? (
                                    <Tr>
                                        <Td>
                                            <Label text="退職" bold={true} />
                                        </Td>
                                        <Td>
                                            <div className="employee-detail__item">
                                                {dateFormatter(
                                                    employee.retired_at,
                                                    true
                                                )}
                                            </div>
                                        </Td>
                                    </Tr>
                                ) : null}
                            </TableBody>
                        </Table>
                        <FlexBox direction="row" justify="center">
                            <div className="edit-preview__footer-btn">
                                <ActionButton
                                    text="前の画面に戻る"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate(
                                            `/employees/edit/${employee.user_uid}`,
                                            { state: location }
                                        );
                                    }}
                                    submit={false}
                                />
                            </div>
                            <div className="edit-preview__footer-btn">
                                <ActionButton
                                    text="社員情報を更新する"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleUpdateEmployee();
                                    }}
                                    color="primary"
                                    submit={false}
                                />
                            </div>
                        </FlexBox>
                    </Panel>
                </div>
            </Page>
        </PageBackground>
    );
};

export default EditPreviewScreen;
