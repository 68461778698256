import axios from "axios";
import { IAnnouncementResponse } from "./models";

async function getAnnouncement(
    token: string,
    id: string
): Promise<IAnnouncementResponse> {
    // GET /api/v1/company/company_announcements/{id}
    const res = await axios
        .get(`${process.env.COMPANY_ADMIN_BASE}/company_announcements/${id}`, {
            headers: { Authorization: `${token}` },
        })
        .catch((e) => {
            throw e;
        });

    return {
        token: res.headers.authorization,
        data: res.data,
    };
}

export default getAnnouncement;
