import React from "react";
import "./styles.scss";

interface ClearButtonProps {
    onClick: () => void;
}

const ClearButton: React.VFC<ClearButtonProps> = ({ onClick }) => {
    return (
        <button className="clear-btn" type="button" onClick={onClick}>
            検索クリア
        </button>
    );
};

export default ClearButton;
