import { ISearchTarget } from "../../../../store/healthcareSearch/models";

export interface ISearchOptions {
    text: string;
    value: string;
}

export interface SummaryProps {
    name: string;
    date: string;
    min: number;
    max: number;
    avg: string;
    total?: string | number;
}

export interface SummarySleepProps {
    name: string;
    date: string;
    min?: { hour: string; minute: string };
    max?: { hour: string; minute: string };
    avg?: { hour: string; minute: string };
    total?: { hour: string; minute: string };
}

export interface RecordProps {
    date: string;
    id?: number;
    name: string;
    user_id: number;
    user_uid: string;
    value: number;
}

export interface RecordSleepProps {
    clear: number[];
    deep: number[];
    light: number[];
    end_date: string;
    end_dates: string;
    id?: number;
    name: string;
    start_date: string;
    start_dates: string;
    user_id: number;
    user_uid: string;
}

export function fixedSleepRecordArray(
    deep: number[],
    light: number[],
    clear: number[],
    type: "deep" | "light" | "clear" | "total"
) {
    if (type == "total" && deep && light && clear) {
        const total = deep
            .concat(light, clear)
            .reduce((sum, num) => sum + num, 0);
        return Math.floor(total / 60) + "時間" + Math.floor(total % 60) + "分";
    } else if (type == "deep" && deep) {
        const fixedDeep = deep.reduce((sum, num) => sum + num, 0);
        return fixedDeep > 59
            ? Math.floor(fixedDeep / 60) +
                  "時間" +
                  Math.floor(fixedDeep % 60) +
                  "分"
            : fixedDeep + "分";
    } else if (type == "light" && light) {
        const fixedLight = light.reduce((sum, num) => sum + num, 0);
        return fixedLight > 59
            ? Math.floor(fixedLight / 60) +
                  "時間" +
                  Math.floor(fixedLight % 60) +
                  "分"
            : fixedLight + "分";
    } else if (type == "clear" && clear) {
        const fixedClear = clear.reduce((sum, num) => sum + num, 0);
        return fixedClear > 59
            ? Math.floor(fixedClear / 60) +
                  "時間" +
                  Math.floor(fixedClear % 60) +
                  "分"
            : fixedClear + "分";
    }
}

export const searchSleepType: Array<ISearchOptions> = [
    {
        text: "---",
        value: "0",
    },
    {
        text: "全て",
        value: "1",
    },
    {
        text: "浅い睡眠",
        value: "2",
    },
    {
        text: "深い睡眠",
        value: "3",
    },
    {
        text: "睡眠中覚醒",
        value: "4",
    },
];

export const formatDuration = (minutes: number | null): string => {
    if (!minutes) return null;
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}時間${mins}分`;
};

export const formatDate = (
    date: string,
    includeTime: boolean = false,
    isTimeOnly: boolean = false
): string => {
    const d = new Date(date);
    return d.toLocaleString("ja-JP", {
        year: isTimeOnly ? undefined : "numeric",
        month: isTimeOnly ? undefined : "2-digit",
        day: isTimeOnly ? undefined : "2-digit",
        hour: includeTime ? "2-digit" : undefined,
        minute: includeTime ? "2-digit" : undefined,
        hourCycle: "h23",
    });
};

const convertToCSV = (data: any[], headers: string[]): string => {
    const csvRows = [];
    csvRows.push(headers.join(","));

    for (const row of data) {
        const values = headers.map((header) => {
            let value = row[header];
            // オブジェクトや配列の場合は適切な文字列に変換
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    value = value.join('; ');
                } else if ('hour' in value && 'minute' in value) {
                    value = `${value.hour}時間${value.minute}分`;
                } else {
                    value = JSON.stringify(value);
                }
            }
            // undefinedやnullの場合は空文字列に
            value = value ?? "";
            const escaped = ("" + value).replace(/"/g, '""');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
};

export const downloadCSV = (
    data: any[],
    dataType: ISearchTarget,
    viewMode: "record" | "summary"
) => {
    const headers = healthDataHeaders[dataType][viewMode];
    // データのキーをヘッダーに合わせて変換
    const formattedData = data.map(item => {
        const newItem: any = {};
        headers.forEach((header, index) => {
            switch(header) {
                case "氏名":
                    newItem[header] = item.name;
                    break;
                case "日付":
                    newItem[header] = formatDate(item.date, viewMode === "record" && dataType !== "睡眠");
                    break;
                case "心拍数":
                case "体温 (度)":
                case "歩数":
                    newItem[header] = item.value;
                    break;
                case "最小値":
                    newItem[header] = item.min;
                    break;
                case "最大値":
                    newItem[header] = item.max;
                    break;
                case "平均心拍数":
                case "平均体温 (度)":
                case "平均値":
                    newItem[header] = Number(item.avg).toFixed(1);
                    break;
                case "累計歩数":
                    newItem[header] = item.total;
                    break;
                case "合計睡眠時間":
                    newItem[header] = formatDuration(item.total_sleep_time);
                    break;
                case "入眠時間":
                    newItem[header] = formatDate(item.sleep_start, true, true);
                    break;
                case "睡眠開始":
                    newItem[header] = formatDate(item.sleep_start, true, true);
                    break;
                case "起床時間":
                    newItem[header] = formatDate(item.sleep_end, true, true);
                    break;
                case "深い睡眠":
                    newItem[header] = formatDuration(item.deep_sleep);
                    break;
                case "浅い睡眠":
                    newItem[header] = formatDuration(item.light_sleep);
                    break;
                case "睡眠時覚醒":
                    newItem[header] = formatDuration(item.clear_time);
                    break;
                case "睡眠時間":
                    newItem[header] = formatDuration(item.sleep_duration);
                    break;
                default:
                    newItem[header] = item[Object.keys(item)[index]] || "";
            }
        });
        return newItem;
    });

    const csvContent = convertToCSV(formattedData, headers);
    const BOM = "\uFEFF";
    const blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${dataType}_${viewMode}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const healthDataHeaders: Record<
    ISearchTarget,
    Record<"record" | "summary", string[]>
> = {
    心拍: {
        record: ["氏名", "日付", "心拍数"],
        summary: ["氏名", "日付", "最小値", "最大値", "平均心拍数"],
    },
    体温: {
        record: ["氏名", "日付", "体温 (度)"],
        summary: ["氏名", "日付", "最小値", "最大値", "平均体温 (度)"],
    },
    歩数: {
        record: ["氏名", "日付", "歩数"],
        summary: ["氏名", "日付", "最小値", "最大値", "平均値", "累計歩数"],
    },
    睡眠: {
        record: [
            "氏名",
            "日付",
            "入眠時間",
            "起床時間",
            "深い睡眠",
            "浅い睡眠",
            "睡眠時覚醒",
            "睡眠時間",
        ],
        summary: ["氏名", "日付", "睡眠開始", "起床時間", "合計睡眠時間"],
    },
};
