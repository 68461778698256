import {
    FlexBox,
    IconButton,
    Page,
    Paginate,
    SearchFilter,
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Title,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { dateFormatter } from "../../../helpers/dateFormatter";
import useInput from "../../../hooks/useInput";
import useSelect from "../../../hooks/useSelect";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import {
    AnnoucementStatus,
    IAnnouncement,
    IAnnouncements,
    IAnnouncementSearch,
} from "../../../store/announcements/models";
import {
    thunkDeleteAnnouncement,
    thunkGetAnnouncements,
} from "../../../store/announcements/thunk";
import EmptyBox from "../../shared/box/empty/EmptyBox";
import Box from "../../shared/box/space/SpaceBox";
import ClearButton from "../../shared/button/clear/ClearButton";
import Content from "../../shared/layout/content/Content";
import Modal from "../../shared/modal/confirm/Modal";
import PageBackground from "../../shared/page/PageBackground";
import TabPanel from "../../shared/tab/panel/TabPanel";
import { searchOptions } from "./helper";

interface ISelectParams {
    title_cont: string;
    message_cont: string;
}

const AnnouncementsScreen: React.FC = () => {
    //global state
    const token = useSelector((store: RootState) => store.session.token);
    const sidebarToggle = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const annoucements: IAnnouncements = useSelector(
        (store: RootState) => store.announcements.list
    );

    //local state
    const [inputValue, setInputValue, setDefault] = useInput("");
    const [selected, updateSelected, resetSelect] = useSelect("title_cont");
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteId, setDeleteId] = useState("");
    const [sortedArr, setSortedArr] = useState<IAnnouncement[] | null>(null);
    const [toggle, setToggle] = useState(false);
    const [activeIdx, setActiveIdx] = useState(0);

    //dispatch
    const dispatch = useDispatch();

    //navigate
    const navigate = useNavigate();

    //GET all annoucements
    const handleGetAnnouncements = useCallback(
        (page: number, params?: IAnnouncementSearch) => {
            dispatch(thunkGetAnnouncements(token, page, params));
        },
        []
    );

    //DELETE annoucement
    const handleDeleteAnnoucements = (id: string) => {
        dispatch(thunkDeleteAnnouncement(token, id));
        handleGetAnnouncements(1);
    };

    //SORT
    const handleSortAnnoucements = (
        e: React.FormEvent<HTMLFormElement> | React.MouseEvent
    ) => {
        e.preventDefault();
        const key = selected as keyof ISelectParams;
        const param: IAnnouncementSearch = {
            [key]: inputValue,
            status: status[activeIdx],
        };
        handleGetAnnouncements(1, param);
    };

    //useEffect
    //set active id
    useSetActiveId('announcements');

    //get all annoucements
    useEffect(() => {
        handleGetAnnouncements(1, { status: status[activeIdx] });
    }, [activeIdx]);

    //set the annoucement id that are being on public now.
    useEffect(() => {
        if (annoucements && annoucements.company_announcements.length > 0) {
            setSortedArr(annoucements.company_announcements);
        } else {
            setSortedArr(null);
        }
    }, [annoucements]);

    //method
    const setAnnouceDate = (item: IAnnouncement, is_start: boolean): string => {
        return dateFormatter(is_start ? item.released_at : item.expired_at);
    };

    const clearOptions = (): void => {
        setDefault("");
        resetSelect("title_cont");
        handleGetAnnouncements(1, { status: status[activeIdx] });
    };

    //variables
    const tabItems = ["すべて", "公開中", "公開前", "公開済", "下書き"];
    const status: Array<AnnoucementStatus> = [
        null,
        "only_released",
        "only_before_release",
        "only_expired",
        "only_draft",
    ];

    return (
        <>
            <Modal
                open={toggle}
                toggleModal={() => {
                    setToggle(!toggle);
                }}
                submit={() => {
                    if (deleteId) {
                        handleDeleteAnnoucements(deleteId);
                        setToggle(!toggle);
                    }
                }}
            />
            <PageBackground>
                <Page toggle={sidebarToggle}>
                    <Content>
                        <Box my="lg">
                            <FlexBox justify="end">
                                <Box mx="md">
                                    <FlexBox>
                                        <Box mr="md">
                                            <SearchFilter
                                                options={searchOptions}
                                                inputValue={inputValue}
                                                selectValue={selected}
                                                inputOnChange={setInputValue}
                                                selectOnChange={updateSelected}
                                                onSubmit={
                                                    handleSortAnnoucements
                                                }
                                                searchSubmit={
                                                    handleSortAnnoucements
                                                }
                                            />
                                        </Box>
                                        <IconButton
                                            icon={<FaPlus />}
                                            color="info"
                                            size="md"
                                            onClick={() =>
                                                navigate("/announcements/new")
                                            }
                                        />
                                    </FlexBox>
                                </Box>
                            </FlexBox>
                        </Box>
                        <TabPanel
                            items={tabItems}
                            activeIdx={activeIdx}
                            onClick={setActiveIdx}
                        >
                            <FlexBox justify="end">
                                <ClearButton onClick={clearOptions} />
                            </FlexBox>
                            <Title title="お知らせ配信" />
                            {annoucements.company_announcements.length > 0 ? (
                                <Table>
                                    <TableHead>
                                        <Tr>
                                            <Th>更新日</Th>
                                            <Th>タイトル</Th>
                                            <Th>配信内容</Th>
                                            <Th>ステータス</Th>
                                            <Th>配信開始日</Th>
                                            <Th>配信終了日</Th>
                                        </Tr>
                                    </TableHead>
                                    <TableBody>
                                        {sortedArr &&
                                            sortedArr.map(
                                                (item: IAnnouncement) => (
                                                    <Tr key={item.id}>
                                                        <Td>
                                                            {dateFormatter(
                                                                item.updated_at
                                                            )}
                                                        </Td>
                                                        <Td>{item.title}</Td>
                                                        <Td>{item.message}</Td>
                                                        <Td>{item.status}</Td>
                                                        <Td>
                                                            {setAnnouceDate(
                                                                item,
                                                                true
                                                            )}
                                                        </Td>
                                                        <Td>
                                                            {setAnnouceDate(
                                                                item,
                                                                false
                                                            )}
                                                        </Td>
                                                        <Td>
                                                            <Link
                                                                to={`/announcements/edit/${item.id}`}
                                                                aria-label="edit announcements"
                                                            >
                                                                <IconButton
                                                                    icon={
                                                                        <FaEdit />
                                                                    }
                                                                    color="primary"
                                                                    plain={true}
                                                                />
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <IconButton
                                                                icon={
                                                                    <FaTrashAlt />
                                                                }
                                                                color="danger"
                                                                plain={true}
                                                                onClick={() => {
                                                                    setToggle(
                                                                        true
                                                                    );
                                                                    setDeleteId(
                                                                        item.id
                                                                    );
                                                                }}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            ) : (
                                <EmptyBox
                                    text={"現在登録されている配信はありません"}
                                />
                            )}
                            {annoucements.total_pages > 1 && (
                                <Paginate
                                    totalPages={annoucements.total_pages}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                />
                            )}
                        </TabPanel>
                    </Content>
                </Page>
            </PageBackground>
        </>
    );
};

export default AnnouncementsScreen;
