// session models
export interface ISession {
	token: string;
	status: boolean;
}
export interface ISessionUser {
	name: string;
	email: string;
}
export interface IPasswordReset {
	company_admin_user: {
		reset_password_token: string;
		password: string;
		password_confirmation: string;
	};
}
export interface IPasswordRequest {
	company_admin_user: {
		email: string;
	};
}
export enum SessionActionTypes {
	SESSION_STATUS = "session/status",
	SESSION_USER = "session/user",
}

export interface ISessionStatusAction {
	type: SessionActionTypes.SESSION_STATUS;
	payload: ISession;
}
export interface ISessionUserAction {
	type: SessionActionTypes.SESSION_USER;
	payload: ISessionUser;
}
export interface ISessionState {
	name: string;
	email: string;
	token: string;
	status: boolean;
}
// local storage
const status = sessionStorage.getItem("status")
	? sessionStorage.getItem("status") === "true"
		? true
		: false
	: false;
const token = sessionStorage.getItem("token")
	? sessionStorage.getItem("token")
	: "";
export const initialSessionState: ISessionState = {
	name: "",
	email: "",
	token,
	status,
};
