import axios from "axios";
import { IUsageSummary } from "./models";
import Qs from "qs";
import { IMonthlyParams } from "../transactions/models";

async function getUsageSummary(
	token: string,
	page: number,
	months: IMonthlyParams
): Promise<IUsageSummary> {
	// GET /api/v1/company/usage_summaries
	try {
		const res = await axios.get(
			`${process.env.COMPANY_ADMIN_BASE}/usage_summary`,
			{
				headers: { Authorization: `${token}` },
				params: {
					page,
					q: {
						...months,
					},
				},
				paramsSerializer: function (params) {
					return Qs.stringify(params, { arrayFormat: "brackets" });
				},
			}
		);
		return res.data;
	} catch (e) {
		throw e;
	}
}

export default getUsageSummary;
