import axios from "axios";
import Qs from "qs";
import { IEmployees, IEmployeesSearchParams } from "./models";
import { employeesUrl } from "../../constants";

const getEmployees = async (
	token: string,
	page = 1,
	searchParams: IEmployeesSearchParams
): Promise<{ token: string; data: IEmployees }> => {
	try {
		const res = await axios.get(employeesUrl, {
			headers: { Authorization: `${token}` },
			params: {
				page,
				q: {
					...searchParams,
				},
			},
			paramsSerializer: function (params) {
				return Qs.stringify(params, { arrayFormat: "brackets" });
			},
		});
		return {
			token: res.headers.authorization,
			data: res.data,
		};
	} catch (e) {
		throw e;
	}
};

export default getEmployees;
