import { AnyAction } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import { sessionStatus } from "./actions";
import { employeeSuccess } from "../employee/actions";
import { errorSet, errorReset } from "../error/actions";
import { IPasswordReset, IPasswordRequest } from "./models";
import login from "./login";
import logout from "./logout";
import refresh from "./refresh";
import resetPassword from "./resetPassword";
import requestPassword from "./requestPassword";
import { axiosError } from "../../helpers/axiosError";
import { adminInfoSet } from "../admin/actions";
import getAdminUserInfo from "../admin/getAdminUserInfo";
import { IAdminInfoResponse } from "../admin/models";
import { companyServiceSet } from "../company/actions";
import getBusinessServices from "../company/getBusinessServices";
import { ICompanyServiceState } from "../company/models";

// session login
// @param email: string
// @param password: string
export const thunkLogin =
    (
        email: string,
        password: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(errorReset());
        try {
            const token = await login(email, password);
            dispatch(sessionStatus({ status: true, token }));
            // call API
            const adminUsuerInfoRes: IAdminInfoResponse =
                await getAdminUserInfo(token);
            // set admin user info
            dispatch(adminInfoSet(adminUsuerInfoRes.data));

            // call API
            const companyServices: ICompanyServiceState =
                await getBusinessServices(token);
            // set company services
            dispatch(companyServiceSet(companyServices));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// @param token: string
export const thunkLogout =
    (token: string): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(errorReset());
        try {
            await logout(token);
            dispatch(sessionStatus({ status: false, token: "" }));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// IPasswordRequest
/* {
  company_admin_user: {
    email: string;
  };
} */
export const thunkPasswordRequest =
    (body: IPasswordRequest): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));
        dispatch(errorReset());
        try {
            await requestPassword(body);
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// IPasswordReset
/* {
  company_admin_user: {
    reset_password_token: string;
    password: string;
    password_confirmation: string;
  };
} */
export const thunkPasswordReset =
    (body: IPasswordReset): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeSuccess(false));
        try {
            await resetPassword(body);
            dispatch(sessionStatus({ status: false, token: "" }));
            dispatch(employeeSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            dispatch(employeeSuccess(false));
        } finally {
            dispatch(hideLoading());
        }
    };

// refresh session
// @param token: string
export const thunkRefresh =
    (token: string): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        try {
            const tokenResult = await refresh(token);
            dispatch(sessionStatus({ status: true, token: tokenResult }));
        } catch (e) {
            dispatch(sessionStatus({ status: false, token: "" }));
        } finally {
            dispatch(hideLoading());
        }
    };
