// store error
export enum ErrorActionTypes {
    ERROR_SET = "error/set",
    ERROR_RESET = "error/reset",
    UPLOAD_ERROR_SET = "upload_error/set",
    UPLOAD_ERROR_RESET = "upload_error/reset",
}

// {"error":{"type":"invalid_request_error","message":["社員番号はすでに存在します","社員番号はすでに存在します",null,null,null,null,null,null,null,null,null,null,null,null,null,"社員番号はすでに存在します"]}}

export interface IError {
    hasError: boolean;
    errorType: string;
    errorMessage: string;
    errorMessageArr?: string[];
}
export interface IErrors {
    error: IError;
    uploadError: IError;
}

export interface IErrorSetAction {
    type: ErrorActionTypes.ERROR_SET;
    payload: IError;
}

export interface IErrorResetAction {
    type: ErrorActionTypes.ERROR_RESET;
    payload: IError;
}

export interface IUploadErrorSetAction {
    type: ErrorActionTypes.UPLOAD_ERROR_SET;
    payload: IError;
}

export interface IUploadErrorResetAction {
    type: ErrorActionTypes.UPLOAD_ERROR_RESET;
    payload: IError;
}

export const initialErrorState: IError = {
    hasError: false,
    errorType: "",
    errorMessage: "",
};

export const initialUploadErrorState: IError = {
    hasError: false,
    errorType: "",
    errorMessage: "",
    errorMessageArr: [],
};

export const intialErrorsState: IErrors = {
    error: initialErrorState,
    uploadError: initialUploadErrorState,
};
