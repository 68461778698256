import * as Models from "./models";

export function contactReducer(
	state = Models.initialContactState,
	action: Models.IContactSuccessSetAction | Models.IContactMessageSetAction
): Models.IContactState {
	switch (action.type) {
		case Models.ContactActionTypes.CONTACT_SUCCESS_SET:
			return {
				...state,
				...action.payload,
			};
		case Models.ContactActionTypes.CONTACT_MESSAGE_SET:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
