import * as Models from "./models";

export function companyServiceReducer(
  state = Models.initialCompanyServiceState,
  action: Models.ICompanyServiceAction
): Models.ICompanyServiceState {
  switch (action.type) {
    case Models.CompanyServiceActionTypes.COMPANY_SERVICE_SET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
