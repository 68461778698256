import * as React from "react";
import { useEffect, useState } from "react";
import { FaCoins, FaUserAlt, FaUsers } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
    thunkGetDashboardTransactions,
    thunkGetEmployeesTransactions,
} from "../../../store/transactions/thunk";
import { notificationHideSet } from "../../../store/notification/actions";
import Monthly from "../../shared/monthly/Monthly";
import { CSSTransition } from "react-transition-group";
import { INotification } from "../../../store/notification/models";
import {
    formatCreatedAt,
    formatMonthsParams,
} from "../../../helpers/dateFormatter";
import {
    IEmployeeTransaction,
    IEmployeeTransactions,
    IMonthlyParams,
} from "../../../store/transactions/models";
import { navigationActive } from "../../../store/navigation/actions";
import HeaderTitle from "../../shared/title/Title";
import { IUsageState } from "../../../store/usage/models";
import {
    brandArrayformatter,
    categoryArrayFormatter,
    colorSelector,
    iconSelector,
    searchOptions,
} from "./helpers";
import Amount from "../../shared/amount/Amount";
import ClearButton from "../../shared/button/clear/ClearButton";
import useInput from "../../../hooks/useInput";
import useSelect from "../../../hooks/useSelect";
import { compare } from "../history/helpers";
import {
    CategoryWidget,
    Notify,
    Page,
    Paginate,
    Panel,
    SearchFilter,
    SummaryWidget,
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Tr,
    UsageChart,
    UsageCircle,
} from "@kortvaluta/admin-twooca-react";
import PageBackground from "../../shared/page/PageBackground";
import "./styles.scss";

interface ISortParams {
    created_at: boolean;
    employee_code: boolean;
    description: boolean;
    provider_name: boolean;
    amount: boolean;
}

const DashboardScreen: React.FC = () => {
    // redux state
    const dispatch = useDispatch();

    const token: string = useSelector(
        (store: RootState) => store.session.token
    );

    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    const notification: INotification = useSelector(
        (store: RootState) => store.notifications
    );

    const usage: IUsageState = useSelector((store: RootState) => store.usage);

    const categoryUsage = useSelector(
        (store: RootState) => store.categoryUsage
    );

    const brand = useSelector((store: RootState) => store.brand);

    const employeesTransactions: IEmployeeTransactions = useSelector(
        (store: RootState) => store.transactions.employees
    );

    // component state
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput, resetSearchInput] = useInput("");
    const [selected, setSelected, resetSelect] = useSelect(
        "employee_info_employee_code_cont"
    );
    const [sortParams, setSortParams] = useState<ISortParams>({
        created_at: false,
        employee_code: false,
        description: false,
        provider_name: false,
        amount: false,
    });
    const [tableData, setTableData] = useState<IEmployeeTransaction[]>(null);

    // handlers
    const handleHideNotification = (): void => {
        dispatch(notificationHideSet(false));
    };

    const handleSetDateRange = (monthNum: number, yearNum: number) => {
        setMonth(monthNum);
        setYear(yearNum);
        const monthlyParams: IMonthlyParams = formatMonthsParams(
            monthNum,
            yearNum
        );
        dispatch(thunkGetDashboardTransactions(token, 1, monthlyParams));
    };

    const handleOnChangePage = (page: number) => {
        setCurrentPage(page);
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        // dispatch
        dispatch(thunkGetEmployeesTransactions(token, page, monthlyParams, {}));
    };

    const handleResetFilter = () => {
        //set current page to 1
        setCurrentPage(1);
        //reset input value
        resetSearchInput("");
        //reset select
        resetSelect("employee_info_employee_code_cont");
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);

        // dispatch
        dispatch(thunkGetEmployeesTransactions(token, 1, monthlyParams));
    };

    const handleSortSummary = (name: string) => {
        const tempObj = {
            ...sortParams,
            [name]: !sortParams[name],
        };
        setSortParams(tempObj);
        const tempArr = tableData.sort((a, b) => {
            return compare(a, b, sortParams[name], "created_at");
        });
        setTableData(tempArr);
    };

    const submit = (
        e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
    ): void => {
        e.preventDefault();
        //set current page to 1
        setCurrentPage(1);
        const params =
            selected && searchInput ? { [selected]: searchInput } : {};
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        // dispatch
        dispatch(
            thunkGetEmployeesTransactions(
                token,
                currentPage,
                monthlyParams,
                params
            )
        );
    };

    // effects
    useEffect(() => {
        dispatch(navigationActive({ id: 'home' }));
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        dispatch(
            thunkGetDashboardTransactions(token, currentPage, monthlyParams)
        );
        // call init thunks
    }, []);

    useEffect(() => {
        const descArray = employeesTransactions.perk_transactions.sort((a,b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        })
        setTableData(descArray);
    }, [employeesTransactions]);

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <div className="dashboard">
                    {notification.kv_notification ? (
                        <CSSTransition
                            in={notification.show}
                            timeout={200}
                            unmountOnExit
                            classNames="login__form__alert"
                        >
                            <Notify
                                color="danger"
                                text={notification.kv_notification}
                                close={handleHideNotification}
                                statics={false}
                                textSpeed={30}
                            />
                        </CSSTransition>
                    ) : null}
                    <div className="dashboard__date">
                        <Monthly
                            month={month}
                            year={year}
                            setDateRange={handleSetDateRange}
                        />
                    </div>
                    <div className="dashboard__usage-summary">
                        <HeaderTitle title="利用実績" />
                        <div className="dashboard__usage-summary__content">
                            <SummaryWidget
                                label={"利用Pt"}
                                value={usage.summary.total_usage_amount.toString()}
                                icon={<FaCoins size={20} />}
                                color={"primary"}
                                decorator={"Pt"}
                            />
                            <SummaryWidget
                                label={"社員数"}
                                value={usage.summary.total_enrolled_employees.toString()}
                                icon={<FaUsers size={25} />}
                                color={"success"}
                                decorator={"人"}
                            />
                            <SummaryWidget
                                label={"平均利用Pt"}
                                value={usage.summary.average_use_per_employee.toString()}
                                icon={<FaUserAlt size={20} />}
                                color={"warning"}
                                decorator={"Pt"}
                            />
                        </div>
                    </div>
                    <div className="dashboard__summary">
                        <CSSTransition
                            in={
                                categoryUsage.categories &&
                                categoryUsage.categories.length > 0
                            }
                            timeout={200}
                            unmountOnExit
                            classNames="login__form__alert"
                        >
                            <div className="dashboard__summary__category">
                                <HeaderTitle title="利用Pt (カテゴリー)" />
                                <div className="dashboard__summary__category__container">
                                    <UsageCircle
                                        usageItems={categoryArrayFormatter(
                                            categoryUsage
                                        )}
                                    />
                                </div>
                                <div className="categories">
                                    {categoryUsage.categories &&
                                        categoryUsage.categories.map(
                                            (item, index) => {
                                                return (
                                                    <CategoryWidget
                                                        key={index.toString()}
                                                        label={item.category}
                                                        icon={iconSelector(
                                                            item.category
                                                        )}
                                                        color={colorSelector(
                                                            index
                                                        )}
                                                    />
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                        </CSSTransition>
                        <CSSTransition
                            in={brand.categories && brand.categories.length > 0}
                            timeout={200}
                            unmountOnExit
                            classNames="login__form__alert"
                        >
                            <div className="dashboard__summary__brand">
                                <HeaderTitle title="利用Pt (ブランド)" />
                                <div className="dashboard__summary__brand__container">
                                    <UsageChart
                                        usageItems={brandArrayformatter(brand)}
                                    />
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="dashboard__filter">
                        <div className="dashboard__filter__search">
                            <SearchFilter
                                options={searchOptions}
                                inputValue={searchInput}
                                selectValue={selected}
                                onSubmit={submit}
                                searchSubmit={submit}
                                selectOnChange={setSelected}
                                inputOnChange={setSearchInput}
                            />
                        </div>
                    </div>
                    <Panel>
                        <div className="dashboard__clear-btn">
                            <ClearButton onClick={handleResetFilter} />
                        </div>
                        <Table title="利用詳細">
                            <TableHead>
                                <Tr>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            handleSortSummary("created_at");
                                        }}
                                        toggle={sortParams.created_at}
                                    >
                                        利用日時
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            handleSortSummary("employee_code");
                                        }}
                                        toggle={sortParams.employee_code}
                                    >
                                        社員番号
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            handleSortSummary("description");
                                        }}
                                        toggle={sortParams.description}
                                    >
                                        サービス名
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            handleSortSummary("provider_name");
                                        }}
                                        toggle={sortParams.provider_name}
                                    >
                                        詳細
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            handleSortSummary("amount");
                                        }}
                                        toggle={sortParams.amount}
                                    >
                                        金額 (pt)
                                    </Th>
                                </Tr>
                            </TableHead>
                            <CSSTransition
                                in={tableData && tableData.length > 0}
                                timeout={200}
                                unmountOnExit
                                classNames="login__form__alert"
                            >
                                <TableBody>
                                    {tableData
                                        ? tableData.map((item, index) => (
                                              <Tr key={index.toString()}>
                                                  <Td>
                                                      {formatCreatedAt(
                                                          item.created_at
                                                      )}
                                                  </Td>
                                                  <Td>{item.employee_code}</Td>
                                                  <Td>
                                                      {item.kind ===
                                                      "regular_update"
                                                          ? "定期更新"
                                                          : item.provider_name}
                                                  </Td>
                                                  <Td>{item.description}</Td>
                                                  <Td>
                                                      <Amount
                                                          value={item.amount}
                                                          kind={item.kind}
                                                      />
                                                  </Td>
                                              </Tr>
                                          ))
                                        : null}
                                </TableBody>
                            </CSSTransition>
                        </Table>
                        <CSSTransition
                            in={employeesTransactions.total_pages > 1}
                            timeout={200}
                            unmountOnExit
                            classNames="login__form__alert"
                        >
                            <div>
                                <Paginate
                                    totalPages={
                                        employeesTransactions.total_pages
                                    }
                                    currentPage={currentPage}
                                    onPageChange={handleOnChangePage}
                                />
                            </div>
                        </CSSTransition>
                    </Panel>
                </div>
            </Page>
        </PageBackground>
    );
};

export default DashboardScreen;
