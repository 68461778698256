import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import { errorReset, errorSet } from "../error/actions";
import { RootState } from "../index";
import { sessionStatus } from "../session/actions";
import {
    announcementSet,
    announcementsSet,
    announcementUpdateSuccess,
} from "./actions";
import deleteAnnouncement from "./deleteAnnouncement";
import getAnnouncement from "./getAnnouncement";
import getAnnouncements from "./getAnnouncements";
import {
    IAnnouncementResponse,
    IAnnouncementSearch,
    IAnnouncementsParams,
    IAnnouncementsResponse,
} from "./models";
import postAnnouncement from "./postAnnouncement";
import putAnnouncement from "./putAnnouncement";

export const thunkGetAnnouncements =
    (
        token: string,
        page = 1,
        params: IAnnouncementSearch
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(announcementUpdateSuccess(false));
        try {
            // call API
            const res: IAnnouncementsResponse = await getAnnouncements(
                token,
                page,
                params
            );
            // set data
            dispatch(announcementsSet(res.data));
            dispatch(sessionStatus({ status: true, token: res.token }));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkGetAnnouncement =
    (
        token: string,
        id: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(announcementUpdateSuccess(false));
        try {
            // call API
            const res: IAnnouncementResponse = await getAnnouncement(token, id);
            // set data
            dispatch(announcementSet(res.data));
            dispatch(sessionStatus({ status: true, token: res.token }));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkPostAnnouncement =
    (
        token: string,
        body: IAnnouncementsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(announcementUpdateSuccess(false));
        try {
            // call API
            await postAnnouncement(token, body);
            // set success
            dispatch(announcementUpdateSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            // set success
            dispatch(announcementUpdateSuccess(false));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkPutAnnouncement =
    (
        token: string,
        id: string,
        body: IAnnouncementsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(announcementUpdateSuccess(false));
        try {
            // call API
            await putAnnouncement(token, id, body);
            // set success
            dispatch(announcementUpdateSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
            // reset success
            dispatch(announcementUpdateSuccess(false));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkDeleteAnnouncement =
    (
        token: string,
        id: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        // reset success
        dispatch(announcementUpdateSuccess(false));
        try {
            // call API
            await deleteAnnouncement(token, id);
            // set success
            dispatch(announcementUpdateSuccess(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(announcementUpdateSuccess(false));
            // hide loading bar
            dispatch(hideLoading());
        }
    };
