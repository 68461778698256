export enum AnnouncementsActionTypes {
    ANNOUNCEMENTS_SET = "ANNOUNCEMENTS_SET",
    ANNOUNCEMENT_SET = "ANNOUNCEMENT_SET",
    ANNOUNCEMENT_UPDATE_SUCCESS = "ANNOUNCEMENT_UPDATE_SUCCESS",
}

export type AnnoucementStatus =
    | "only_draft"
    | "only_before_release"
    | "only_released"
    | "only_expired";

export interface IAnnouncement {
    id?: string;
    status: string;
    title: string;
    message: string;
    released_at: string;
    expired_at: string;
    updated_at?: string;
}

export interface IAnnouncementSearch {
    status?: AnnoucementStatus;
    message_cont?: string;
    title_cont?: string;
}

export interface IAnnouncements {
    company_announcements: Array<IAnnouncement>;
    total_pages: number;
    limit_value: number;
}

export interface IAnnouncementsResponse {
    data: IAnnouncements;
    token: string;
}

export interface IAnnouncementResponse {
    data: IAnnouncement;
    token: string;
}

export interface IAnnouncementsParams {
    title: string;
    message: string;
    is_draft: boolean;
    released_at: string;
    expired_at: string;
}

export interface IAnnouncementsSetAction {
    type: AnnouncementsActionTypes.ANNOUNCEMENTS_SET;
    payload: IAnnouncements;
}

export interface IAnnouncementSetAction {
    type: AnnouncementsActionTypes.ANNOUNCEMENT_SET;
    payload: IAnnouncement;
}

export interface IAnnouncementUpdateSuccessAction {
    type: AnnouncementsActionTypes.ANNOUNCEMENT_UPDATE_SUCCESS;
    payload: boolean;
}

export interface IAnnouncementsState {
    list: IAnnouncements;
    item: IAnnouncement;
    success: boolean;
}

export const iniitalAnnouncementsState: IAnnouncementsState = {
    list: {
        company_announcements: [],
        total_pages: 0,
        limit_value: 0,
    },
    item: {
        id: "",
        status: "",
        title: "",
        message: "",
        released_at: "",
        expired_at: "",
        updated_at: "",
    },
    success: false,
};
