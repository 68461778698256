import * as Models from "./models";

export function announcementsSet(
    list: Models.IAnnouncements
): Models.IAnnouncementsSetAction {
    return {
        type: Models.AnnouncementsActionTypes.ANNOUNCEMENTS_SET,
        payload: {
            ...list,
        },
    };
}

export function announcementSet(
    item: Models.IAnnouncement
): Models.IAnnouncementSetAction {
    return {
        type: Models.AnnouncementsActionTypes.ANNOUNCEMENT_SET,
        payload: {
            ...item,
        },
    };
}

export function announcementUpdateSuccess(
    status: boolean
): Models.IAnnouncementUpdateSuccessAction {
    return {
        type: Models.AnnouncementsActionTypes.ANNOUNCEMENT_UPDATE_SUCCESS,
        payload: status,
    };
}
