export enum AdminInfoActionTypes {
    ADMIN_INFO_SET = "ADMIN_INFO_SET",
}

export interface IAdminInfo {
    company_name: string;
    company_admin_name: string;
    company_admin_email: string;
}

export interface IAdminInfoResponse {
    data: IAdminInfo;
    token: string;
}

export interface IAdminInfoSetAction {
    type: AdminInfoActionTypes.ADMIN_INFO_SET;
    payload: IAdminInfo;
}

const company_name = sessionStorage.getItem("company_name")
    ? sessionStorage.getItem("company_name")
    : "";
const company_admin_name = sessionStorage.getItem("comapny_admin_name")
    ? sessionStorage.getItem("comapny_admin_name")
    : "";

const company_admin_email = sessionStorage.getItem("comapny_admin_email")
    ? sessionStorage.getItem("comapny_admin_email")
    : "";
export const initialAdminInfoState: IAdminInfo = {
    company_name,
    company_admin_name,
    company_admin_email,
};
