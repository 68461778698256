import { AnyAction } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { errorSet, errorReset } from "../error/actions";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import { IAdminInfoResponse } from "./models";
import getAdminUserInfo from "./getAdminUserInfo";
import { adminInfoSet } from "./actions";
import { axiosError } from "../../helpers/axiosError";
import { sessionStatus } from "../session/actions";

export const thunkGetAdminUserInfo =
    (token: string): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        try {
            // call API
            const adminUsuerInfoRes: IAdminInfoResponse =
                await getAdminUserInfo(token);
            // set admin user info
            dispatch(adminInfoSet(adminUsuerInfoRes.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: adminUsuerInfoRes.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };
