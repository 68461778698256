import * as Models from "./models";

export function contactSuccessSet(
	success: boolean
): Models.IContactSuccessSetAction {
	return {
		type: Models.ContactActionTypes.CONTACT_SUCCESS_SET,
		payload: {
			success,
		},
	};
}

export function contactMessageSet(
	message: string
): Models.IContactMessageSetAction {
	return {
		type: Models.ContactActionTypes.CONTACT_MESSAGE_SET,
		payload: {
			message,
		},
	};
}
