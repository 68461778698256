import * as Models from "./models";

export function transactionReducer(
    state = Models.initialTransactionState,
    action:
        | Models.IProductTransactionAction
        | Models.IEmployeesTransactionsAction
        | Models.IEmployeeTransactionsAction
        | Models.IEmployeeTransactionsCsvAction
): Models.ITransactionState {
    switch (action.type) {
        case Models.TransactionActionTypes.PRODUCT_TRANSACTIONS_SET:
            return {
                ...state,
                products: {
                    ...action.payload,
                },
            };
        case Models.TransactionActionTypes.EMPLOYEES_TRANSACTIONS_SET:
            return {
                ...state,
                employees: {
                    ...action.payload,
                },
            };
        case Models.TransactionActionTypes.EMPLOYEE_TRANSACTIONS_SET:
            return {
                ...state,
                employee: {
                    ...action.payload,
                },
            };
        case Models.TransactionActionTypes.EMPLOYEE_TRANSACTIONS_CSV_SET:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
