import axios from "axios";
import { refreshUrl } from "../../constants";

const refresh = async (token: string): Promise<string> => {
	try {
		const res = await axios.get(refreshUrl, {
			headers: { Authorization: `${token}` },
		});
		return res.headers.authorization;
	} catch (e) {
		throw e;
	}
};

export default refresh;
