import * as Models from "./models";

export function currentPageReducer(
  state = Models.initialCurrentPageState,
  action: Models.ISetCurrentPageAction
): Models.ICurrentPage {
  switch (action.type) {
    case Models.PageActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
