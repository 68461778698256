import * as Models from "./models";

export function brandSummarySet(
	summary: Models.IBrandSummary
): Models.IBrandSummarySetAction {
	return {
		type: Models.BrandSummaryActionTypes.BRAND_SUMMARY_SET,
		payload: summary,
	};
}
