import * as Models from "./models";

export function punchReducer(
	state = Models.initialPunchState,
	action: Models.IPunchSuccessAction
): Models.IPunchState {
	switch (action.type) {
		case Models.PunchActionTypes.PUNCH_SUCCESS:
			return {
				...state,
				status: action.payload,
			};
		default:
			return state;
	}
}
