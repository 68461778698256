export const years = function (startYear = 1920) {
    const currentYear = new Date().getFullYear() - 14;
    const years = [];
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years.reverse();
};

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const days = function () {
    const nums = [];
    for (let i = 0; i < 31; i++) {
        nums[i] = i + 1;
    }
    return nums;
};

export const setDefaultDate = (defaultValue: string) => {
    const split = defaultValue.split("-");
    const year = `${split[0]}`;
    const month = split[1][0] === "0" ? `${split[1][1]}` : `${split[1]}`;
    const day = split[2][0] === "0" ? `${split[2][1]}` : `${split[2]}`;
    return {
        year: year,
        month: month,
        day: day,
    };
};
