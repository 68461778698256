import * as React from "react";
import "./styles.scss";

interface IEmptyBoxProps {
    text: string;
}

const EmptyBox: React.FC<IEmptyBoxProps> = ({ text }) => {
    return <h5 className="empty-box">{text}</h5>;
};

export default EmptyBox;
