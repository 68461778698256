import React, { useState } from "react";

export default function useInput(
    initVal: string
): [
    string,
    (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | string
    ) => void,
    (value: string) => void
] {
    const [input, setInput] = useState<string>(initVal);

    const updateInput = (e: React.ChangeEvent<HTMLInputElement> | string): void => {
        if (typeof e === 'string') {
            setInput(e);
        } else if (e && e.target) {
            setInput(e.target.value);
        }
    };

    const setDefaultInput = (value: string) => {
        setInput(value);
    };

    return [input, updateInput, setDefaultInput];
}
