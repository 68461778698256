import { Alert } from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";

interface AlertProps {
    color: "danger" | "warning" | "success";
    toggle: boolean;
    text: string;
    close: () => void;
}

const TransitionAlert: React.FC<AlertProps> = ({
    toggle,
    color,
    text,
    close,
}) => {
    return (
        <CSSTransition
            in={toggle}
            timeout={200}
            unmountOnExit
            classNames="login__form__alert"
        >
            <div className="transition-alert">
                <Alert text={text} color={color} close={close} />
            </div>
        </CSSTransition>
    );
};

export default TransitionAlert;

//this component is wrapper for Alert made in admin-twooca-react
