import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page, Panel } from "@kortvaluta/admin-twooca-react";
import { RootState } from "../../../../store";
import { employeeSuccess } from "../../../../store/employee/actions";
import {
    ISearchParams,
    ISearchTarget,
    SearchTarget,
    ISearchResultState,
} from "../../../../store/healthcareSearch/models";
import Content from "../../../shared/layout/content/Content";
import PageBackground from "../../../shared/page/PageBackground";
import SearchContainer from "../../../shared/searchContainer/SearchContainer";
import TabPanel from "../../../shared/tab/panel/TabPanel";
import HeaderTitle from "../../../shared/title/Title";
import {
    thunkGetSearchData,
    thunkGetSearchUsers,
} from "../../../../store/healthcareSearch/thunk";
import DataTable from "./component/DataTable";
import { downloadCSV } from "./helpers";
import ActionBtn from "../../../shared/actionButton/ActionBtn";

const HealthcareManagementScreen: React.FC = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((store: RootState) => store.loadingBar.default === 1);
    const token = useSelector((store: RootState) => store.session.token);
    const sidebarToggle = useSelector((store: RootState) => store.sidebar.sidebarToggle);
    const updateSuccess = useSelector((store: RootState) => store.employeesInfo.success);
    const result = useSelector((store: RootState) => store.healthcareSearch);
    const userOptions = useSelector((store: RootState) => store.userOptions);

    const [currentPage, setCurrentPage] = useState<number[]>([1, 1, 1, 1]);
    const [activeIdx, setActiveIdx] = useState(0);
    const [searchParams, setSearchParams] = useState<ISearchParams | null>(null);
    const [viewModes, setViewModes] = useState<("record" | "summary")[]>(["record", "record", "record", "record"]);
    const [selectedViewMode, setSelectedViewMode] = useState<"record" | "summary">("record");

    const tabItems: ISearchTarget[] = ["心拍", "体温", "歩数", "睡眠"];

    useEffect(() => {
        dispatch(thunkGetSearchUsers(token));
    }, []);

    const getSearchParams = (inputValue: ISearchParams) => {
        const currentTab = tabItems[activeIdx];
        const { user, dateRangeValue } = inputValue;
        let range = { start: "", end: "" };

        if (selectedViewMode === 'summary') {
            switch (currentTab) {
                case "心拍":
                    range = inputValue.averageHeartRange || range;
                    break;
                case "体温":
                    range = inputValue.averageTemperatureRange || range;
                    break;
                case "歩数":
                    range = inputValue.averageStepCountRange || range;
                    break;
                case "睡眠":
                    range = inputValue.averageSleepTimeRange || range;
                    break;
            }
        } else {
            switch (currentTab) {
                case "心拍":
                    range = inputValue.heartRange || range;
                    break;
                case "体温":
                    range = inputValue.temperatureRange || range;
                    break;
                case "歩数":
                    range = inputValue.stepCountRange || range;
                    break;
                case "睡眠":
                    range = inputValue.sleepTimeRange || range;
                    break;
            }
        }

        return {
            user: user || { label: "", value: "", name: "" },
            minDate: dateRangeValue?.start || "",
            maxDate: dateRangeValue?.end || "",
            minValue: range.start,
            maxValue: range.end,
        };
    };

    const handleSearch = (inputValue: ISearchParams) => {
        setSearchParams(inputValue);
        const params = getSearchParams(inputValue);
        dispatch(
            thunkGetSearchData(
                token,
                tabItems[activeIdx],
                params,
                1,
                selectedViewMode
            )
        );
        setCurrentPage(prev => {
            const newPage = [...prev];
            newPage[activeIdx] = 1;
            return newPage;
        });
        setViewModes(prev => {
            const newModes = [...prev];
            newModes[activeIdx] = selectedViewMode;
            return newModes;
        });
    };

    const handlePageChange = (page: number) => {
        if (searchParams) {
            const params = getSearchParams(searchParams);
            dispatch(
                thunkGetSearchData(
                    token,
                    tabItems[activeIdx],
                    params,
                    page,
                    viewModes[activeIdx]
                )
            );
        }
        setCurrentPage(prev => {
            const newPage = [...prev];
            newPage[activeIdx] = page;
            return newPage;
        });
        window.scrollTo(0, 0);
    };

    const selectMode = (mode: "record" | "summary") => {
        setSelectedViewMode(mode);
    };

    const handleTabChange = (index: number) => {
        setActiveIdx(index);
    };

    const handleDownloadCSV = () => {
        const currentData = getCurrentData();
        downloadCSV(currentData, tabItems[activeIdx], viewModes[activeIdx]);
    };

    const getCurrentData = () => {
        const currentTab = SearchTarget[tabItems[activeIdx]];
        return result[currentTab as keyof ISearchResultState]?.data || [];
    };

    const getTotalPages = () => {
        const currentTab = SearchTarget[tabItems[activeIdx]];
        return result[currentTab as keyof ISearchResultState]?.total_pages || 0;
    };

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <SearchContainer
                        target={tabItems[activeIdx]}
                        setSearchParams={setSearchParams}
                        viewMode={selectedViewMode}
                        selectMode={selectMode}
                        handleSearch={handleSearch}
                        userOptions={userOptions}
                    />
                    <TabPanel
                        items={tabItems}
                        activeIdx={activeIdx}
                        onClick={handleTabChange}
                    >
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "",
                        }}>
                            <ActionBtn
                                text="CSVをダウンロード"
                                onClick={handleDownloadCSV}
                                color="primary"
                                disabled={getCurrentData().length === 0}
                            />
                        </div>
                        <Panel>
                            <HeaderTitle title="会員リスト一覧" />
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : getCurrentData().length === 0 ? (
                                <div className="emptyTable">
                                    No result found
                                </div>
                            ) : (
                                <DataTable
                                    data={getCurrentData()}
                                    showDataType={tabItems[activeIdx]}
                                    totalPages={getTotalPages()}
                                    currentPage={currentPage[activeIdx]}
                                    viewMode={viewModes[activeIdx]}
                                    onPageChange={handlePageChange}
                                />
                            )}
                        </Panel>
                    </TabPanel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default HealthcareManagementScreen;
