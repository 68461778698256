import * as React from "react";
import "./styles.scss";

interface IHeaderTitleProps {
  title: string;
}

const SubTitle: React.VFC<IHeaderTitleProps> = ({ title }) => {
  return (
    <div className="vertical-line">
      <div className="vertical-line__title">{title}</div>
    </div>
  );
};

export default SubTitle;
