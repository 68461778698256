import * as React from "react";
import { useEffect, useState } from "react";

import {
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
} from "@kortvaluta/admin-twooca-react";

import { IHealthcareDashboard } from "../../../../store/healthcare/models";
import Table from "../../table/Table";
import { toTime } from "../../../../utils/dateUtils";
import "./styles.scss";

interface RingListTableProps {
  isLoading: boolean;
  data?: Array<IHealthcareDashboard>;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const RingListTable: React.FC<RingListTableProps> = (props) => {
  const { data } = props;
  const [tableData, setTableData] = useState<Array<IHealthcareDashboard>>([]);

  const minutesToHour = (minutes: number) => {
    if (!minutes) return null;
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <div className="table-scroll">
      <Table>
        <TableHead>
          <Tr>
            <Th>氏名</Th>
            <Th>心拍数</Th>
            <Th>計測時間</Th>
            <Th>平均心拍数</Th>
            <Th>歩数</Th>
            <Th>計測時間</Th>
            <Th>累積歩数</Th>
            <Th>体温 (度)</Th>
            <Th>計測時間</Th>
            <Th>平均体温</Th>
            <Th>入眠時間</Th>
            <Th>睡眠種別変更時間</Th>
            <Th>睡眠種別</Th>
            <Th>睡眠時間(分)</Th>
          </Tr>
        </TableHead>
        <TableBody>
          {tableData.map((item: IHealthcareDashboard, index: number) => (
            <Tr key={index.toString()}>
              <Td>{item.name}</Td>
              <Td>{item.heart_rate}</Td>
              <Td>{toTime(item.heart_rate_date)}</Td>
              <Td>{item.heart_rate_average}</Td>
              <Td>{item.steps_count}</Td>
              <Td>{toTime(item.steps_count_date)}</Td>
              <Td>{item.cumulative_steps_count}</Td>
              <Td>{item.temperature}</Td>
              <Td>{toTime(item.temperature_date)}</Td>
              <Td>{item.temperature_average}</Td>
              <Td>{toTime(item.sleep_start)}</Td>
              <Td>{toTime(item.sleep_end)}</Td>
              <Td>{item.sleep_status}</Td>
              <Td>{minutesToHour(item.sleep_sum)}</Td>
            </Tr>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default RingListTable;
