import { FlexBox, Page, Panel } from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import ErrorImage from "../../../assets/error.svg";
import { RootState } from "../../../store/index";
import Image from "../../shared/image/Image";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";

const Error404: React.FC = () => {
    //global state
    const status: boolean = useSelector(
        (store: RootState) => store.session.status
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    if (!status) return <Navigate to="/login" />;
    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <FlexBox
                            justify="center"
                            align="center"
                            direction="column"
                        >
                            <Image
                                size={150}
                                alt="Error 404"
                                src={ErrorImage}
                            />
                            <h1 style={{ color: "#29abe2" }}>404</h1>
                            <h4>not found</h4>
                            <p>お探しのページは</p>
                            <p>見つかりませんでした</p>
                        </FlexBox>
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default Error404;
