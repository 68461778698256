import * as React from "react";
import { useEffect, useState } from "react";
import "./styles.scss";

export interface PostcodeInputProps {
    id?: string;
    value?: string;
    pattern?: string;
    autoComplete?: string;
    required?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    onEnter?: boolean;
    name?: string;
    onChange?: (val: string, key: string) => void;
}

const PostcodeInput: React.FC<PostcodeInputProps> = (props) => {
    const {
        value,
        pattern,
        autoComplete,
        required,
        disabled,
        hasError,
        onEnter,
        name,
        onChange,
    } = props;

    const [first, setFirst] = useState<string>("");
    const [second, setSecond] = useState<string>("");

    useEffect(() => {
        if (value && value.length === 7) {
            setFirst(value.substring(0, 3));
            setSecond(value.substring(3, 7));
        }
    }, [value]);

    useEffect(() => {
        onChange(first + second, "postcode");
    }, [first, second]);

    const firstKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
        ["e", "E", "+", "-"].includes(event.key) && event.preventDefault();

    const handleOnChangeFirst = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { maxLength, value } = e.target;
        const fieldIntIndex = parseInt("1", 10);
        const nextfield = document.querySelector(`input[id=second]`);
        if (value.length >= maxLength) {
            if (fieldIntIndex < 7) {
                if (nextfield !== null) (nextfield as HTMLElement).focus();
            }
        }
        setFirst(value);
    };

    const SecondKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        const firstfield = document.querySelector(`input[id=first]`);
        ["e", "E", "+", "-"].includes(key) && event.preventDefault();
        if (key === "Backspace" && second.length === 0) {
            (firstfield as HTMLElement).focus();
        }
    };

    const handleOnChangeSecond = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSecond(e.target.value);
    };

    return (
        <div className="postcode-input">
            <input
                id={"first"}
                type={"text"}
                aria-label={"text"}
                aria-required="true"
                className={`input${hasError ? " input--error" : ""}`}
                placeholder={"例:000"}
                value={first}
                name={name}
                onKeyDown={firstKeyDown}
                autoComplete={autoComplete}
                required={required}
                onChange={handleOnChangeFirst}
                disabled={disabled ? true : false}
                maxLength={3}
                onKeyPress={(e) => {
                    if (!onEnter) {
                        e.key === "Enter" && e.preventDefault();
                    }
                }}
            />
            <div className="devider">-</div>
            <input
                id={"second"}
                type={"text"}
                aria-label={"number"}
                aria-required="true"
                className={`input${hasError ? " input--error" : ""}`}
                placeholder={"例:0000"}
                pattern={pattern}
                value={second}
                name={name}
                onKeyDown={SecondKeyDown}
                autoComplete={autoComplete}
                required={required}
                onChange={handleOnChangeSecond}
                maxLength={4}
                disabled={disabled ? true : false}
                onKeyPress={(e) => {
                    if (!onEnter) {
                        e.key === "Enter" && e.preventDefault();
                    }
                }}
            />
        </div>
    );
};

export default PostcodeInput;
