import * as Models from "./models";

export function sessionReducer(
  state = Models.initialSessionState,
  action: Models.ISessionStatusAction | Models.ISessionUserAction
): Models.ISessionState {
  switch (action.type) {
    case Models.SessionActionTypes.SESSION_STATUS:
      sessionStorage.setItem("token", action.payload.token);
      sessionStorage.setItem("status", action.payload.status.toString());
      return {
        ...state,
        ...action.payload,
      };
    case Models.SessionActionTypes.SESSION_USER:
      localStorage.setItem("name", action.payload.name);
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
