import * as React from "react";
import { useEffect, useRef } from "react";
import { FaTimes, FaExclamationCircle } from "react-icons/fa";
import { ActionButton } from "@kortvaluta/admin-twooca-react";
import "./styles.scss";

interface ModalProps {
    open: boolean;
    toggleModal: (event: React.MouseEvent<HTMLElement>) => void;
    submit: (event: React.MouseEvent<HTMLElement>) => void;
}

const Modal: React.FC<ModalProps> = (props) => {
    const { open, toggleModal, submit } = props;
    const node = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (node.current.contains(event.target)) {
            // inside click
            return;
        }
        // outside click
        toggleModal(event);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    return (
        <div className={`modal ${open ? "modal--open" : ""}`}>
            <div
                className={`modal__container ${
                    open ? "modal__container--open" : ""
                }`}
                ref={node}
            >
                <div className="modal__container__header">
                    <button
                        className="modal__container__close"
                        onClick={toggleModal}
                        aria-label="close"
                    >
                        <FaTimes />
                    </button>
                </div>
                <div className="modal__container__content">
                    <div className="modal__container__content__icon">
                        <FaExclamationCircle />
                    </div>
                    <div className="modal__container__content__message">
                        削除してもよろしいですか？
                    </div>
                </div>
                <div className="modal__container__footer">
                    <div className="modal__container__footer__btn">
                        <ActionButton
                            text="はい"
                            color="danger"
                            onClick={submit}
                        />
                    </div>
                    <div className="modal__container__footer__btn">
                        <ActionButton
                            text="いいえ"
                            color="secondary"
                            onClick={toggleModal}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
