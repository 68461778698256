import * as React from "react";

import { Column, Input, Label, Row } from "@kortvaluta/admin-twooca-react";
import "./styles.scss";
import { useEffect, useState } from "react";

interface ISearchInputProps {
    label: string;
    maxLength?: number;
    rangeValue: { start: string; end: string };
    setRangeValue: (value: { start: string; end: string }) => void;
    styleStart?: boolean;
    styleEnd?: boolean;
    isDisabled?: boolean;
    isSleep?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
    label,
    setRangeValue,
    rangeValue,
    styleStart,
    styleEnd,
    isDisabled,
    isSleep = false,
}) => {
    const [range, setRange] = useState<{
        starthour: string;
        startminute: string;
        endhour: string;
        endminute: string;
    }>({
        starthour: "",
        startminute: "",
        endhour: "",
        endminute: "",
    });

    useEffect(() => {
        if (
            range.starthour != "" ||
            range.startminute != "" ||
            range.endhour != "" ||
            range.endminute != ""
        ) {
            const mimic = { ...rangeValue };
            mimic.start = String(
                Number(range.starthour) * 60 + Number(range.startminute)
            );
            mimic.end = String(
                Number(range.endhour) * 60 + Number(range.endminute)
            );
            if (mimic.start === "0") {
                mimic.start = "";
            } else if (mimic.end === "0") {
                mimic.end = "";
            }
            setRangeValue(mimic);
        } else if (
            range.starthour == "" &&
            range.startminute == "" &&
            range.endhour == "" &&
            range.endminute == ""
        ) {
            setRangeValue({ start: "", end: "" });
        }
    }, [range]);
    const clearRange = () => {};
    return (
        <div className="search-input">
            <Row>
                {isSleep && (
                    <>
                        <Column xs={12} sm={12} md={12}>
                            <Label text={label} />
                        </Column>

                        <Column xs={2} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleStart}
                                value={range.starthour}
                                onChange={(e) => {
                                    setRange({
                                        ...range,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                disabled={isDisabled}
                                name="starthour"
                            />
                        </Column>
                        <Label text="時間" />
                        <Column xs={2} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleStart}
                                value={range.startminute}
                                onChange={(e) => {
                                    setRange({
                                        ...range,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                disabled={isDisabled}
                                name="startminute"
                            />
                        </Column>
                        <Label text="分" />

                        <Column xs={1} sm={1} md={1}>
                            <Label text="〜" />
                        </Column>
                        <Column xs={2} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleEnd}
                                value={range.endhour}
                                onChange={(e) => {
                                    setRange({
                                        ...range,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                disabled={isDisabled}
                                name="endhour"
                            />
                        </Column>
                        <Label text="時間" />
                        <Column xs={2} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleEnd}
                                value={range.endminute}
                                onChange={(e) => {
                                    setRange({
                                        ...range,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                                disabled={isDisabled}
                                name="endminute"
                            />
                        </Column>
                        <Label text="分" />
                    </>
                )}
                {!isSleep && (
                    <>
                        <Column xs={12} sm={6} md={6}>
                            <Label text={label} />
                        </Column>
                        <Column xs={3} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleStart}
                                value={rangeValue.start}
                                onChange={(e) =>
                                    setRangeValue({
                                        ...rangeValue,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                disabled={isDisabled}
                                name="start"
                            />
                        </Column>
                        <Column xs={1} sm={1} md={1}>
                            <Label text="〜" />
                        </Column>
                        <Column xs={3} sm={2} md={2}>
                            <Input
                                type="text"
                                hasError={!styleEnd}
                                value={rangeValue.end}
                                onChange={(e) =>
                                    setRangeValue({
                                        ...rangeValue,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                disabled={isDisabled}
                                name="end"
                            />
                        </Column>
                    </>
                )}
            </Row>
        </div>
    );
};

export default SearchInput;
