import * as Models from "./models";

export function sessionStatus(
	session: Models.ISession
): Models.ISessionStatusAction {
	return {
		type: Models.SessionActionTypes.SESSION_STATUS,
		payload: session,
	};
}

export function sessionUser(
	email: string,
	name: string
): Models.ISessionUserAction {
	return {
		type: Models.SessionActionTypes.SESSION_USER,
		payload: {
			email,
			name,
		},
	};
}
