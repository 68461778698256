import React, { useEffect } from "react";
import { Row, Column } from "@kortvaluta/admin-twooca-react";
import DataGraph from "./DataGraph";
import {
    ISearchParams,
    ISearchTarget,
    SearchTarget,
} from "../../../../../store/healthcareSearch/models";

interface DataViewProps {
    searchParams: ISearchParams | null;
    healthcareSearchData: any;
    isSearched: boolean;
    displayType: "record" | "summary";
    onPageChange: (page: number, dataType: ISearchTarget) => void;
}

const DataView: React.FC<DataViewProps> = ({
    searchParams,
    healthcareSearchData,
    isSearched,
    displayType,
    onPageChange,
}) => {
    const dataTypes: ISearchTarget[] = ["歩数", "心拍", "体温", "睡眠"];

    const getDataType = (dataType: ISearchTarget) => {
        return SearchTarget[dataType];
    };

    const getCurrentData = (dataType: ISearchTarget) => {
        return healthcareSearchData[getDataType(dataType)]?.data || [];
    };

    const getTotalPages = (dataType: ISearchTarget) => {
        return healthcareSearchData[getDataType(dataType)]?.total_pages || 0;
    };

    if (!isSearched) {
        return <div>検索を行ってください。</div>;
    }

    return (
        <>
            {dataTypes.map((dataType) => (
                <Row key={dataType}>
                    <Column xs={12}>
                        <DataGraph
                            data={getCurrentData(dataType)}
                            showDataType={dataType}
                            totalPages={getTotalPages(dataType)}
                            currentPage={1}
                            dateRange={searchParams?.dateRangeValue || null}
                            onPageChange={(page) =>
                                onPageChange(page, dataType)
                            }
                            isSearched={isSearched}
                            displayType={displayType}
                        />
                    </Column>
                </Row>
            ))}
        </>
    );
};

export default DataView;
