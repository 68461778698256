import {
    FlexBox,
    MailButton,
    Page,
    Paginate,
    Panel,
    Row,
    Title,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../../hooks/useInput";
import useSelect from "../../../hooks/useSelect";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import { employeeSuccess } from "../../../store/employee/actions";
import {
    IEmployee,
    IEmployeeExampleState,
} from "../../../store/employee/models";
import {
    thunkCreateEmployees,
    thunkGetEmployees,
} from "../../../store/employee/thunk";
import {
    bulkRequestEnrollmentSuccessSet,
    requestEnrollmentSuccessSet,
} from "../../../store/enrollment/actions";
import { IEnrollmentParams } from "../../../store/enrollment/models";
import {
    thunkPostBulkRequestEnrollment,
    thunkPostRequestEnrollment,
} from "../../../store/enrollment/thunk";
import { uploadErrorReset } from "../../../store/error/actions";
import TransitionAlert from "../../shared/alert/Alert";
import SpaceBox from "../../shared/box/space/SpaceBox";
import ClearButton from "../../shared/button/clear/ClearButton";
import Content from "../../shared/layout/content/Content";
import UploadAlertModal from "../../shared/modal/uploadAlert/UploadAlert";
import PageBackground from "../../shared/page/PageBackground";
import TabSwitch from "../../shared/tab/TabSwitch";
import ListTable from "../../shared/tables/list/ListTable";
import TitleTable from "../../shared/tables/title/TitleTable";
import UploadModal from "../../shared/upload/UploadModal";
import useWindowSize from "../../../hooks/useWindowSize";
import SearchColumn from "../../shared/search/SearchColumn";

const STORAGE_KEY = 'managementScreenState';

const ManagementScreen: React.FC = () => {
    //global state
    const isLoading: boolean = useSelector((store: RootState) =>
        store.loadingBar.default === 1 ? true : false
    );
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    const updateSuccess: boolean = useSelector(
        (store: RootState) => store.employeesInfo.success
    );
    const employees: IEmployee[] = useSelector(
        (store: RootState) => store.employeesInfo.employeeItems.employees
    );
    const totalPage: number = useSelector(
        (store: RootState) => store.employeesInfo.employeeItems.total_pages
    );
    const enrollmentRequestSuccess: boolean = useSelector(
        (store: RootState) => store.requestEnrollment.success
    );
    const bulkEnrollmentRequestSuccess: boolean = useSelector(
        (store: RootState) => store.requestEnrollment.bulkSuccess
    );
    const example: IEmployeeExampleState = useSelector(
        (store: RootState) => store.employeesInfo.example
    );
    const uploadError = useSelector(
        (store: RootState) => store.errors.uploadError
    );

    //local state
    const [currentPage, setCurrentPage] = useState(1);
    const [toggle, setToggle] = useState<boolean>(true);
    const [selected, updateSelected, clearSelected] = useSelect(
        "user_profile_full_name_cont"
    );
    const [additionalSelected, updateAdditionalSelected, clearAdditionalSelected] = useSelect(
        ""
    );
    const [success, setSuccess] = useState<boolean>(false);
    const [inputVal, updateInput, clearInput] = useInput("");
    const [tableData, setTableData] = useState(employees);
    const [showSuccess, setShowSuccess] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [requestEnrollmentEmployees, setRequestEnrollmentEmployees] =
        useState<Array<IEmployee>>([]);
    const [sort, setSort] = useState(
        "employee_info_employee_code desc"
    );
    const [sorted, setSorted] = useState(false);

    //dispatch
    const dispatch = useDispatch();

    const saveState = () => {
        const state = {
            currentPage,
            selected,
            additionalSelected,
            inputVal,
            sort,
            sorted,
            toggle
        };
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    };

    const restoreState = (): boolean => {
        const savedState = sessionStorage.getItem(STORAGE_KEY);
        if (!savedState) return false;
        const {
            currentPage = 1,
            selected = "user_profile_full_name_cont",
            additionalSelected = "",
            inputVal = "",
            sort = "employee_info_employee_code desc",
            sorted = false,
            toggle = false,
        } = JSON.parse(savedState);
        setCurrentPage(currentPage);
        // 文字列を直接渡すように変更
        updateSelected(selected);
        updateAdditionalSelected(additionalSelected);
        updateInput(inputVal);

        setSort(sort);
        setSorted(sorted);
        setToggle(toggle);

        // 保存した状態に基づいて社員データを取得
        const filterParams = {
            [selected]:
                inputVal,
            ["employee_info_status_eq"]: additionalSelected,
            ["sorts"]: sort,
        };
        handleGetEmployees(currentPage, filterParams);

        // 状態を復元したらsessionStorageをクリア
        sessionStorage.removeItem(STORAGE_KEY);
        return true;
    };

    const handleBulkRequestEnrollment = () => {
        const user_infos = requestEnrollmentEmployees.map((item: IEmployee) => {
            return {
                email: item.email,
                user_uid: item.user_uid,
            };
        });
        dispatch(thunkPostBulkRequestEnrollment(token, { user_infos }));
    };

    const handleGetEmployees = (page: number, params?: any) => {
        dispatch(thunkGetEmployees(token, page, params));
    };

    const handleFileUpload = (uploadedFile: File) => {
        const formData = new FormData();
        formData.append("csv_file", uploadedFile, uploadedFile.name);
        dispatch(thunkCreateEmployees(token, formData));
    };

    const handleResetBulkSuccess = () => {
        dispatch(bulkRequestEnrollmentSuccessSet(false));
    };

    const handleResetEnrollementSuccess = () => {
        dispatch(requestEnrollmentSuccessSet(false));
    };

    const handleResetSuccess = () => {
        dispatch(employeeSuccess(false));
    };

    const handleRequestEnrollment = (params: IEnrollmentParams) => {
        dispatch(thunkPostRequestEnrollment(token, params));
    };

    const handleResetUploadError = () => {
        dispatch(uploadErrorReset());
    };

    //useEffect

    //set ActiveId
    useSetActiveId('employees');

    // init
    // GET employees
    useEffect(() => {
        const stateRestored = restoreState();
        if (!stateRestored) {
            // 初期ロード時の既存の処理
            const filterParams = {
                [selected || "user_profile_full_name_cont"]: inputVal || "",
                ["employee_info_status_eq"]: additionalSelected || "",
                ["sorts"]: sort || "employee_info_employee_code desc",
            };
            handleGetEmployees(currentPage || 1, filterParams);
        }
    }, []);

    //Set sortable employees data
    useEffect(() => {
        const sortEmployees = employees.sort((a, b) => {
            const aValue = parseInt(a['employee_code']);
            const bValue = parseInt(b['employee_code']);

            if (typeof aValue === 'number' && typeof bValue === 'number') {
              return sort === 'employee_info_employee_code asc' ? aValue - bValue : bValue - aValue;
            }

            if (aValue < bValue) {
              return sort === 'employee_info_employee_code asc' ? -1 : 1;
            }

            if (aValue > bValue) {
              return sort === 'employee_info_employee_code asc' ? 1 : -1;
            }

            return 0;
        });
        setTableData(sortEmployees);
        saveState();
    }, [employees]);

    useEffect(() => {
        saveState();
    }, [toggle]);

    useEffect(() => {
        if (updateSuccess) {
            setSuccess(true);
            handleResetSuccess();
        }
    }, [updateSuccess]);

    useEffect(() => {
        const filteredEmployees = employees.filter((item: IEmployee) => {
            if (item.agreement === false && item.status !== "retired")
                return item;
        });
        if (Array.isArray(filteredEmployees))
            setRequestEnrollmentEmployees(filteredEmployees);
    }, [employees]);

    //method

    const handleSubmit = (
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
    ): void => {
        event.preventDefault();
        const filterParams = {
            [selected]: inputVal,
            ["employee_info_status_eq"]: additionalSelected,
            ["sorts"]: sort,
        };
        handleGetEmployees(1, filterParams);
        setCurrentPage(1);
    };

    const handleOnPageChange = (page: number) => {
        const filterParams = {
            [selected]: inputVal,
            ["employee_info_status_eq"]: additionalSelected,
            ["sorts"]: sort,
        };
        handleGetEmployees(page, filterParams);
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }

    const handleOnSort = (isDesc: boolean) => {
        const sort = `employee_info_employee_code ${isDesc ? "desc" : "asc"}`
        const filterParams = {
            [selected]: inputVal,
            ["employee_info_status_eq"]: additionalSelected,
            ["sorts"]: sort,
        };
        handleGetEmployees(currentPage, filterParams);
        setSort(sort);
        setSorted(!isDesc);
    }

    const handleResetFilter = () => {
        clearInput("");
        clearSelected("user_profile_full_name_cont");
        clearAdditionalSelected("");
        const filterParams = {
            ["sorts"]: sort,
        };
        handleGetEmployees(1, filterParams);
        setCurrentPage(1);
    };

    const handleResetUpdateSuccess = () => {
        setSuccess(false);
    };

    const size = useWindowSize();

    const searchColumnParams = {
        toggle: toggle,
        setToggle: setToggle,
        selected: selected,
        handleSubmit: handleSubmit,
        updateSelected: updateSelected,
        inputVal: inputVal,
        updateInput: updateInput,
        setModalOpen: setModalOpen,
        updateAdditionalSelected: updateAdditionalSelected,
        additionalSelected: additionalSelected,
    }

    return (
        <>
            <TransitionAlert
                toggle={success}
                text="従業員情報の更新に成功しました"
                color="success"
                close={handleResetUpdateSuccess}
            />
            <TransitionAlert
                toggle={showSuccess}
                text="送信に成功しました"
                color="success"
                close={() => {
                    setShowSuccess(false);
                }}
            />
            <TransitionAlert
                toggle={bulkEnrollmentRequestSuccess}
                text="対象者全員へのメール送信に成功しました"
                color="success"
                close={handleResetBulkSuccess}
            />
            <TransitionAlert
                toggle={enrollmentRequestSuccess}
                text="メール送信に成功しました"
                color="success"
                close={handleResetEnrollementSuccess}
            />
            <UploadModal
                isLoading={isLoading}
                open={modalOpen}
                example={example}
                toggleModal={() => {
                    setModalOpen(!modalOpen);
                }}
                submit={(uploadedFile: File) => {
                    handleFileUpload(uploadedFile);
                }}
            />
            <UploadAlertModal
                title={"下記のデータはすでに登録されています"}
                open={uploadError.hasError}
                errorMessageArr={uploadError.errorMessageArr}
                toggleModal={handleResetUploadError}
            />
            <PageBackground>
                <Page toggle={sidebarToggle}>
                    <Content>
                        <SpaceBox my="md">
                            {size.width >= 1200 ? (
                                <Row>
                                    <SearchColumn
                                        {...searchColumnParams}
                                    />
                                </Row>
                            ) : (
                                <SearchColumn
                                    {...searchColumnParams}
                                />
                            )}
                        </SpaceBox>
                        <Panel>
                            <FlexBox justify="end">
                                <MailButton
                                    text="メールをまとめて送信"
                                    onClick={handleBulkRequestEnrollment}
                                    disabled={
                                        isLoading ||
                                        requestEnrollmentEmployees.length === 0
                                    }
                                />
                                <ClearButton onClick={handleResetFilter} />
                            </FlexBox>
                            <Title title="社員管理" />
                            <TabSwitch
                                toggle={toggle}
                                tabItems={[
                                    <TitleTable
                                        isLoading={isLoading}
                                        data={tableData}
                                        requestEnrollment={
                                            handleRequestEnrollment
                                        }
                                    />,
                                    <ListTable
                                        isLoading={isLoading}
                                        data={tableData}
                                        requestEnrollment={
                                            handleRequestEnrollment
                                        }
                                        sorted={sorted}
                                        onSort={handleOnSort}
                                    />,
                                ]}
                            />
                            {totalPage > 1 && (
                                <Paginate
                                    totalPages={totalPage}
                                    currentPage={currentPage}
                                    onPageChange={handleOnPageChange}
                                />
                            )}
                        </Panel>
                    </Content>
                </Page>
            </PageBackground>
        </>
    );
};

export default ManagementScreen;
