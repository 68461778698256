import * as Models from "./models";

export function announcementsReducer(
	state = Models.iniitalAnnouncementsState,
	action:
		| Models.IAnnouncementsSetAction
		| Models.IAnnouncementSetAction
		| Models.IAnnouncementUpdateSuccessAction
): Models.IAnnouncementsState {
	switch (action.type) {
		case Models.AnnouncementsActionTypes.ANNOUNCEMENTS_SET:
			return {
				...state,
				list: {
					...action.payload,
				},
			};
		case Models.AnnouncementsActionTypes.ANNOUNCEMENT_SET:
			return {
				...state,
				item: {
					...action.payload,
				},
			};
		case Models.AnnouncementsActionTypes.ANNOUNCEMENT_UPDATE_SUCCESS:
			return {
				...state,
				success: action.payload,
			};
		default:
			return state;
	}
}
