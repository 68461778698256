import * as React from "react";
import "./styles.scss";

interface ITextareaProps {
    name?: string;
    value: string;
    hasError: boolean;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.VFC<ITextareaProps> = ({
    name,
    value,
    hasError,
    onChange,
}) => {
    return (
        <textarea
            className={`textarea ${hasError ? "textarea--hasError" : ""}`}
            onChange={onChange}
            value={value}
            name={name ? name : ""}
        />
    );
};

export default Textarea;
