import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Title,
    Table,
    TableHead,
    TableBody,
    Td,
    Tr,
    Th,
    Column,
    FlexBox,
    SearchFilter,
    Page,
    Panel,
    Row,
} from "@kortvaluta/admin-twooca-react";
import { RootState } from "../../../../store";
import useInput from "../../../../hooks/useInput";
import useSelect from "../../../../hooks/useSelect";
import useSetActiveId from "../../../../hooks/useSetActiveId";
import Content from "../../../shared/layout/content/Content";
import PageBackground from "../../../shared/page/PageBackground";
import Paginate from "../../../shared/pagination/Paginate";
import { thunkGetRingUsers } from "../../../../store/healthcare/thunk";
import { RingUsers, RingUser } from "../../../../store/healthcare/models";
import SpaceBox from "../../../shared/box/space/SpaceBox";
import useWindowSize from "../../../../hooks/useWindowSize";
import ClearButton from "../../../shared/button/clear/ClearButton";
import { searchOptions } from "../../../screens/management/helpers";
import { useNavigate } from "react-router-dom";

const STORAGE_KEY = "RingUsersState";

const RingUsersList: React.FC = () => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const navigate = useNavigate();
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const ringUsers: RingUsers = useSelector(
        (store: RootState) => store.ringUsers
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [inputVal, updateInput, clearInput] = useInput("");
    const [selected, updateSelected, clearSelected] = useSelect(
        "user_profile_full_name_cont"
    );
    const [sort, setSort] = useState("employee_info_employee_code desc");
    const [sortedRingUsers, setSortedRingUsers] = useState<RingUser[]>([]);

    const saveState = useCallback(() => {
        const state = { currentPage, selected, inputVal, sort };
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }, [currentPage, selected, inputVal, sort]);

    const restoreState = useCallback((): boolean => {
        const savedState = sessionStorage.getItem(STORAGE_KEY);
        if (!savedState) return false;
        const {
            currentPage = 1,
            selected = "user_profile_full_name_cont",
            inputVal = "",
            sort = "employee_info_employee_code desc",
        } = JSON.parse(savedState);
        setCurrentPage(currentPage);
        updateSelected(selected);
        updateInput(inputVal);
        setSort(sort);
        handleGetRingUsers(currentPage, {
            [selected]: inputVal,
            ["sorts"]: sort,
        });
        sessionStorage.removeItem(STORAGE_KEY);
        return true;
    }, []);

    const handleGetRingUsers = useCallback(
        (page = 1, filter = {}) => {
            dispatch(thunkGetRingUsers(token, page, filter));
        },
        [dispatch, token]
    );

    useEffect(() => {
        if (!restoreState()) {
            handleGetRingUsers(1, { [selected]: inputVal, ["sorts"]: sort });
        }
    }, []);

    useEffect(() => {
        if (ringUsers.list) {
            const sortedUsers = [...ringUsers.list].sort((a, b) => {
                const aValue = parseInt(a.employee_code);
                const bValue = parseInt(b.employee_code);
                return sort.includes("asc") ? aValue - bValue : bValue - aValue;
            });
            setSortedRingUsers(sortedUsers);
            saveState();
        }
    }, [ringUsers, sort]);

    const handleSubmit = (
        event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>
    ): void => {
        event.preventDefault();
        handleGetRingUsers(1, { [selected]: inputVal, ["sorts"]: sort });
        setCurrentPage(1);
    };

    const handleOnPageChange = (page: number) => {
        handleGetRingUsers(page, { [selected]: inputVal, ["sorts"]: sort });
        setCurrentPage(page);
        window.scrollTo(0, 0);
    };

    const handleOnSort = () => {
        const newSort = sort.includes("asc")
            ? "employee_info_employee_code desc"
            : "employee_info_employee_code asc";
        setSort(newSort);
        handleGetRingUsers(currentPage, {
            [selected]: inputVal,
            ["sorts"]: newSort,
        });
    };

    const handleResetFilter = () => {
        clearInput("");
        clearSelected("user_profile_full_name_cont");
        handleGetRingUsers(1, { ["sorts"]: sort });
        setCurrentPage(1);
    };

    const handleClick = (uid: string) => {
        navigate(`/healthcareGraph?userId=${uid}`);
    };

    const Search = () => (
        <Column xl={7}>
            <FlexBox direction="row" align="center">
                <SearchFilter
                    options={searchOptions}
                    inputValue={inputVal}
                    selectValue={selected}
                    onSubmit={handleSubmit}
                    searchSubmit={handleSubmit}
                    selectOnChange={updateSelected}
                    inputOnChange={updateInput}
                />
            </FlexBox>
        </Column>
    );

    const ListTable = () => (
        <Table>
            <TableHead>
                <Tr>
                    <Th sortable={true} onSort={handleOnSort}>
                        社員番号
                    </Th>
                    <Th>名前</Th>
                    <Th>操作</Th>
                </Tr>
            </TableHead>
            <TableBody>
                {sortedRingUsers.map((item) => (
                    <Tr key={item.employee_code}>
                        <Td>{item.employee_code}</Td>
                        <Td>{item.name}</Td>
                        <Td>
                            <button
                                onClick={() => handleClick(item.uid)}
                                className="text-blue-600 hover:text-blue-800 underline"
                            >
                                グラフを見る
                            </button>
                        </Td>
                    </Tr>
                ))}
            </TableBody>
        </Table>
    );

    useSetActiveId("healthcare-users");

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <SpaceBox my="md">
                        {size.width >= 1200 ? <Row>{Search()}</Row> : Search()}
                    </SpaceBox>
                    <Panel>
                        <FlexBox justify="end">
                            <ClearButton onClick={handleResetFilter} />
                        </FlexBox>
                        <Title title="本日の最新健康データ" />
                        {sortedRingUsers.length === 0 ? (
                            <div className="emptyTable">No result found</div>
                        ) : (
                            ListTable()
                        )}
                        {ringUsers.total_pages > 1 && (
                            <Paginate
                                totalPages={ringUsers.total_pages}
                                currentPage={currentPage}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default RingUsersList;
