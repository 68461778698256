import * as React from "react";
import "./styles.scss";

interface LoadingProps {
  isLoading: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { isLoading } = props;
  return (
    <div className={`loading${isLoading ? " loading--show" : ""}`}>
      <div className="loading__widget" />
    </div>
  );
};

export default Loading;
