import axios from "axios";
import { employeesUrl } from "../../constants";
import { IEmployeeUpdate } from "./models";

const updateFaceImage = async (
    token: string,
    formData: FormData,
    uid: string
): Promise<{ token: string; data: IEmployeeUpdate }> => {
    try {
        const res = await axios.put(
            `${employeesUrl}/${uid}/update_face_image`,
            formData,
            {
                headers: { Authorization: token },
            }
        );
        return {
            token: res.headers.authorization,
            data: res.data,
        };
    } catch (e) {
        throw e;
    }
};

export default updateFaceImage;
