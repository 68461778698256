import { AnyAction } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { errorSet, errorReset } from "../error/actions";
import { RootState } from "../index";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import { IEnrollmentParams, IBulkEnrollmentParams } from "./models";
import postRequestEnrollment from "./postRequestEnrollment";
import postBulkRequestEnrollment from "./postBulkRequestEnrollment";
import {
    bulkRequestEnrollmentSuccessSet,
    requestEnrollmentSuccessSet,
} from "./actions";

export const thunkPostRequestEnrollment =
    (
        token: string,
        body: IEnrollmentParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());

        dispatch(requestEnrollmentSuccessSet(false));
        try {
            // call API
            await postRequestEnrollment(token, body);
            dispatch(requestEnrollmentSuccessSet(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };

export const thunkPostBulkRequestEnrollment =
    (
        token: string,
        body: IBulkEnrollmentParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        // clear error
        dispatch(errorReset());
        dispatch(bulkRequestEnrollmentSuccessSet(false));
        try {
            // call API
            await postBulkRequestEnrollment(token, body);
            dispatch(bulkRequestEnrollmentSuccessSet(true));
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            // hide loading bar
            dispatch(hideLoading());
        }
    };
