import * as Models from "./models";

export function healthcareSearchReducer(
    state = Models.initialHealthCare,
    action:
        | Models.ISearchResultSetAction
): Models.ISearchResultState{
    switch (action.type) {
        case Models.SearchActionTypes.SET_SEARCH_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export function searchUserOptionsReducer(
    state = Models.initialSearchUserOptions,
    action:
        | Models.SearchUserOptionSetAction
): Models.SearchUserOptionsState{
    switch (action.type) {
        case Models.SearchUserOptionActionTypes.SET_SEARCH_USER_OPTIONS:
            return action.payload
        default:
            return state;
    }
}

export function HistoricalDataReducer(
    state = Models.initialHistoricalData,
    action:
        | Models.HistoricalDataSetAction
): Models.HistoricalDataState{
    switch (action.type) {
        case Models.HistoricalDataSetActionTypes.SET_HISTORICAL_DATA:
            return action.payload
        default:
            return state;
    }
}
