import axios from "axios";
import { employeesUrl } from "../../constants";

const deleteEmployee = async (token: string, uid: string): Promise<void> => {
	try {
		const res = await axios.delete(`${employeesUrl}/${uid}`, {
			headers: { Authorization: `${token}` },
		});
	} catch (e) {
		throw e;
	}
};

export default deleteEmployee;
