export enum NotificationActionTypes {
    NOTIFICATION_SET = "NOTIFICATION_SET",
    NOTIFICATION_HIDE_SET = "NOTIFICATION_HIDE_SET",
}

export interface INotification {
    kv_notification: string;
    show: boolean;
}

export interface INotificationSet {
    type: NotificationActionTypes.NOTIFICATION_SET;
    payload: INotification;
}

export interface INotificationHideSet {
    type: NotificationActionTypes.NOTIFICATION_HIDE_SET;
    payload: boolean;
}

const kv_notification: string = localStorage.getItem("notifyMsg")
    ? localStorage.getItem("notifyMsg")
    : "";
let show = false;
const storedShow = localStorage.getItem("notifyShow");
if (storedShow) {
    if (storedShow === "true") {
        show = true;
    }
}
export const initialNotificationState: INotification = {
    kv_notification,
    show,
};
