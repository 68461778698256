import * as React from "react";
import "./styles.scss";

interface ISpaceBoxProps {
    m?: "sm" | "md" | "lg" | "xl";
    mt?: "sm" | "md" | "lg" | "xl";
    mb?: "sm" | "md" | "lg" | "xl";
    ml?: "sm" | "md" | "lg" | "xl";
    mr?: "sm" | "md" | "lg" | "xl";
    mx?: "sm" | "md" | "lg" | "xl";
    my?: "sm" | "md" | "lg" | "xl";
    p?: "sm" | "md" | "lg" | "xl";
    pt?: "sm" | "md" | "lg" | "xl";
    pb?: "sm" | "md" | "lg" | "xl";
    pl?: "sm" | "md" | "lg" | "xl";
    pr?: "sm" | "md" | "lg" | "xl";
    px?: "sm" | "md" | "lg" | "xl";
    py?: "sm" | "md" | "lg" | "xl";
    w?: 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
    children?: React.ReactNode;
}

const SpaceBox: React.VFC<ISpaceBoxProps> = (props) => {
    let className = "box";
    Object.keys(props).forEach(function (key) {
        className = className.concat(` ${key}-${props[key]}`);
    });
    return <div className={className}>{props.children}</div>;
};

export default SpaceBox;
