import * as Models from "./models";

export function usageReducer(
    state = Models.initialUsageState,
    action: Models.IUsageListSetAction | Models.IUsageSummarySetAction
): Models.IUsageState {
    switch (action.type) {
        case Models.UsageActionTypes.USAGE_LIST_SET:
            return {
                ...state,
                list: {
                    ...action.payload,
                },
            };
        case Models.UsageActionTypes.USAGE_SUMMARY_SET:
            return {
                ...state,
                summary: {
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
