import axios from "axios";
import { INotification } from "./models";

async function getKvNotifications(token: string): Promise<INotification> {
    // GET /api/v1/company/kv_notifications
    try {
        const res = await axios.get(
            `${process.env.COMPANY_ADMIN_BASE}/kv_notifications`,
            {
                headers: { Authorization: `${token}` },
            }
        );
        return {
            kv_notification: res.data.kv_notification,
            show: true,
        };
    } catch (e) {
        throw e;
    }
}

export default getKvNotifications;
