import axios from "axios";
import Qs from "qs";
import { healthDataUrl } from "../../constants";
import { RingUsersState } from "./models";
import { IEmployeesSearchParams } from "../../store/employee/models"

const getRingUsers = async (
	token: string,
    page: number,
	filter: IEmployeesSearchParams
): Promise<{ token: string; data: RingUsersState}> => {
	try {
		const res = await axios.get(`${healthDataUrl}/ring_users`, {
			headers: { Authorization: `${token}` },
            params : { page, q: { ...filter } },
			paramsSerializer: function (params) {
				return Qs.stringify(params, { arrayFormat: "brackets" });
			},
		});
		return {
			token: res.headers.authorization,
			data: res.data
		};
	} catch (e) {
		throw e;
	}
};

export default getRingUsers;
