//Action Types
export enum UsageActionTypes {
    USAGE_LIST_SET = "USAGE_LIST_SET",
    USAGE_SUMMARY_SET = "USAGE_SUMMARY_SET",
}

//Usage Summary
export interface IUsageSummary {
    total_usage_amount: number;
    total_enrolled_employees: number;
    average_use_per_employee: number;
}

export interface ICoupon {
    brand: string;
    category: string;
    description: string;
    total_items_used: number;
    total_amount_used: number;
}

//Usage List
export interface IUsageList {
    coupons: {
        usage_list: Array<ICoupon>;
        total_pages: number;
        limit_value: number;
    };
}

//Overall
export interface IUsageState {
    list: IUsageList;
    summary: IUsageSummary;
}

export interface IUsageListRes {
    token: string;
    data: IUsageList;
}

//Action for Usage List
export interface IUsageListSetAction {
    type: UsageActionTypes.USAGE_LIST_SET;
    payload: IUsageList;
}

//Action for Usage Suammary
export interface IUsageSummarySetAction {
    type: UsageActionTypes.USAGE_SUMMARY_SET;
    payload: IUsageSummary;
}

//Initial Value
export const initialUsageState: IUsageState = {
    list: {
        coupons: {
            usage_list: [],
            total_pages: 0,
            limit_value: 0,
        },
    },
    summary: {
        total_usage_amount: 0,
        total_enrolled_employees: 0,
        average_use_per_employee: 0,
    },
};
