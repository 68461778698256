import React from "react";
import "./styles.scss";

interface ButtonProps {
    onClick: () => void;
    active?: boolean;
    children: React.ReactNode;
    className?: string;
}

export const Button: React.FC<ButtonProps> = ({
    onClick,
    active,
    children,
    className,
}) => {
    return (
        <button
            onClick={onClick}
            className={`button ${active ? "active" : ""} ${className || ""}`}
        >
            {children}
        </button>
    );
};

interface ButtonGroupProps {
    children: React.ReactNode;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => {
    return (
        <div className="buttonGroup">
            {React.Children.map(children, (child, index) => {
                if (React.isValidElement<ButtonProps>(child)) {
                    return React.cloneElement(child, {
                        className: `${
                            child.props.className || ""
                        } groupButton ${index === 0 ? "firstButton" : ""} ${
                            index === React.Children.count(children) - 1
                                ? "lastButton"
                                : ""
                        }`,
                    } as ButtonProps);
                }
                return child;
            })}
        </div>
    );
};
