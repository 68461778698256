// constants
export const loginUrl = `${process.env.COMPANY_ADMIN_BASE}/company_admin_users/sign_in`;
export const logoutUrl = `${process.env.COMPANY_ADMIN_BASE}/company_admin_users/sign_out`;
export const employeesUrl = `${process.env.COMPANY_ADMIN_BASE}/employees`;
export const healthDataUrl = `${process.env.COMPANY_ADMIN_BASE}/health_data`;
export const employeesTransactionsUrl = `${process.env.COMPANY_ADMIN_BASE}/employees/perk_transactions`;
export const employeeTransactionsUrl = `${process.env.COMPANY_ADMIN_BASE}/employees`;
export const refreshUrl = `${process.env.COMPANY_ADMIN_BASE}/sessions/refresh`;
export const productTransactionsUrl = `${process.env.COMPANY_ADMIN_BASE}/perk_transactions`;
export const passwordUrl = `${process.env.COMPANY_ADMIN_BASE}/company_admin_users/password`;
