import axios from "axios";
import { healthDataUrl } from "../../constants";
import { IHealthcareDashboardState } from "./models";

const getHealthDataDashboard = async (
	token: string,
	page = 1
): Promise<{ token: string; data: IHealthcareDashboardState}> => {
	try {
		const res = await axios.get(`${healthDataUrl}/dashboard`, {
			headers: { Authorization: `${token}` },
			params: {
				page
			}
		});
		return {
			token: res.headers.authorization,
			data: {
				dashboard: res.data
			},
		};
	} catch (e) {
		throw e;
	}
};

export default getHealthDataDashboard;
