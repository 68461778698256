import * as React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import { store } from "./store/index";
import App from "./components/App";
import "./styles/index.scss";

Sentry.init({
  dsn:
    "https://9a55524b6fe9456b802d8774ee0d09ab@o168939.ingest.sentry.io/5272622",
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
