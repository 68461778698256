import * as React from "react";
import "./styles.scss";

interface BrandLogoProps {
  isWhite?: boolean;
  styles?: string;
}
const BrandLogo: React.FC<BrandLogoProps> = (props) => {
  const { isWhite, styles } = props;
  return (
    <svg
      id="レイヤー_3"
      data-name="レイヤー 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1033.02 271.5"
      className={`brand--expand${styles ? ` ${styles}` : ""}`}
    >
      <title>twooca_logo</title>
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M109.91,149.77H210.42a12.38,12.38,0,0,1,0,24.75h-38V312.78a12.38,12.38,0,1,1-24.75,0V174.52H109.91a12.38,12.38,0,1,1,0-24.75Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M426.9,216.38l-38.24,102a13.64,13.64,0,0,1-12.24,8.41,12.64,12.64,0,0,1-12-8.41l-26.52-70.63-26.26,70.63a13.64,13.64,0,0,1-12.24,8.41,12.67,12.67,0,0,1-12-8.41l-38.24-102a12.44,12.44,0,0,1,7.39-16.32,12.25,12.25,0,0,1,16.06,7.39l26.78,71.14L325.94,208a12.37,12.37,0,0,1,7.64-7.9,10.26,10.26,0,0,1,3.83-.77h2.8a.25.25,0,0,0,.26.26H341c.25,0,.25,0,.51.25H342c.25.26.25.26.51.26a12,12,0,0,1,7.39,7.9l26.52,70.63,26.77-71.14a12.53,12.53,0,0,1,16.32-7.39C425.88,202.35,429.2,209.75,426.9,216.38Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M460.32,262.78a64,64,0,1,1,64,64A64,64,0,0,1,460.32,262.78Zm25.25,0A38.76,38.76,0,1,0,524.32,224,38.9,38.9,0,0,0,485.57,262.78Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M627.09,262.78a64,64,0,1,1,64,64A64,64,0,0,1,627.09,262.78Zm25.24,0A38.76,38.76,0,1,0,691.09,224,38.91,38.91,0,0,0,652.33,262.78Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M793.85,238.05c0-50.23,41.06-89.75,90-89.75,32.13,0,60.94,16.57,76.24,44.11,4.59,7.65,1.53,15.55-4.59,18.36-5.86,3.06-13.77,1.53-16.83-4.59-12-19.89-32.12-31.88-54.82-31.88-34.93,0-64,28.82-64,63.75,0,35.19,29.07,62.72,64,62.72a63.73,63.73,0,0,0,53.29-29.06c3.06-6.12,12.5-7.65,16.83-3.06,6.12,3.06,9.18,11,4.59,16.82-16.83,26-44.11,41.31-74.71,41.31C834.91,326.78,793.85,287,793.85,238.05Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M1130.44,263.8v50a12.32,12.32,0,0,1-12.75,12.75c-5.1,0-12.24-5.36-12.24-12.75-11,9.17-23.2,12.75-37.73,12.75-36,0-64.51-26.78-64.51-62.73a64.1,64.1,0,0,1,64.51-64.51C1103.67,199.29,1130.44,227.85,1130.44,263.8Zm-25,0c0-21.42-16.32-39.52-37.73-39.52s-39.52,18.1-39.52,39.52,18.1,37.74,39.52,37.74S1105.45,285.22,1105.45,263.8Z"
        transform="translate(-97.41 -148.3)"
      />
      <path
        className={isWhite ? "brand--white" : "brand--blue"}
        d="M505.23,351.8a12,12,0,1,0-17.88,16,157.49,157.49,0,0,0,233.88,0,12,12,0,1,0-17.88-16,133.51,133.51,0,0,1-198.12,0Z"
        transform="translate(-97.41 -148.3)"
      />
    </svg>
  );
};

export default BrandLogo;
