import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { IconButton, Notify, Page, Panel } from "@kortvaluta/admin-twooca-react";
import { FaHistory } from "react-icons/fa";
import { RootState } from "../../../../store";
import { notificationHideSet } from "../../../../store/notification/actions";
import { INotification } from "../../../../store/notification/models";
import Content from "../../../shared/layout/content/Content";
import PageBackground from "../../../shared/page/PageBackground";
import Paginate from "../../../shared/pagination/Paginate";
import RingListTable from "../../../shared/tables/ringList/RingListTable";
import HeaderTitle from "../../../shared/title/Title";
import { IHealthcareDashboard } from "../../../../store/healthcare/models"
import { thunkGetHealthDataDashboard } from "../../../../store/healthcare/thunk"
import "./styles.scss";

const HealthcareDashboardScreen: React.FC = () => {
  // redux state
  const dispatch = useDispatch();

  const token: string = useSelector((store: RootState) => store.session.token);

  const sidebarToggle: boolean = useSelector(
    (store: RootState) => store.sidebar.sidebarToggle
  );

  const notification: INotification = useSelector(
    (store: RootState) => store.notifications
  );

  const isLoading: boolean = useSelector((store: RootState) =>
    store.loadingBar.default === 1 ? true : false
  );

  const users: IHealthcareDashboard[] = useSelector(
    (store: RootState) => store.healthcare.dashboard.users
  );

  const totalPage: number = useSelector(
    (store: RootState) => store.healthcare.dashboard.total_pages
  );

  // component state
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState(users);

  const [currentDateTime, setCurrentDateTime] = useState("");

  // handlers
  const handleHideNotification = (): void => {
    dispatch(notificationHideSet(false));
  };

  const handleGetHealthDataDashboard = (page: number): void => {
    dispatch(thunkGetHealthDataDashboard(token, page))
  }

  const handleOnPageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleClickReload = () => {
    handleGetHealthDataDashboard(currentPage);
    setCurrentDateTime(getCurrentDateTime());
  }

  const getCurrentDateTime = () => {
    const date = new Date();
    return `${date.getFullYear()}年${date.getMonth()+1}月${date.getDate()}日 ${date.getHours()}時${date.getMinutes()}分${date.getSeconds()}秒`
  };

  useEffect(() => {
    handleGetHealthDataDashboard(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentDateTime(getCurrentDateTime());
    setTableData(users);
  }, [users])

  return (
    <PageBackground>
      <Page toggle={sidebarToggle}>
        <Content>
          {notification.kv_notification ? (
            <CSSTransition
              in={notification.show}
              timeout={200}
              unmountOnExit
              classNames="login__form__alert"
            >
              <Notify
                color="danger"
                text={notification.kv_notification}
                close={handleHideNotification}
                statics={false}
                textSpeed={30}
              />
            </CSSTransition>
          ) : null}
          <Panel>
            <HeaderTitle title="本日の最新健康データ" />
              <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                <div>{currentDateTime}時点</div>
                <IconButton
                    icon={<FaHistory />}
                    color="success"
                    plain={true}
                    onClick={handleClickReload}
                />
              </div>
            {tableData.length === 0 ? (
              <div className="emptyTable">No result found</div>
            ) : (
              <RingListTable
                isLoading={isLoading}
                data={tableData ? tableData : users}
              />
            )}

            {totalPage > 1 && (
              <Paginate
                totalPages={totalPage}
                currentPage={currentPage}
                onPageChange={handleOnPageChange}
              />
            )}
          </Panel>
        </Content>
      </Page>
    </PageBackground>
  );
};

export default HealthcareDashboardScreen;
