import { Alert } from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { Fragment } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import AnnoucemetsScreen from "../../components/screens/announcements/Announcements.Screen";
import ContactScreen from "../../components/screens/contact/Contact.Screen";
import EmployeeDetailScreen from "../../components/screens/employeeDetail/EmployeeDetail.Screen";
import EmployeeHistoryScreen from "../../components/screens/employeesHistory/EmployeeHistory.Screen";
import HistoryScreen from "../../components/screens/history/History.Screen";
import SettingScreen from "../../components/screens/settings/Setting.Sceeen";
import Navigation from "../navigation/Navigation";
import Error404 from "../screens/404/Error404";
import AnnouncementScreen from "../screens/announcement/Announcement.Screen";
import DashboardScreen from "../screens/dashboard/Dashboard.Screen";
import EditPreviewScreen from "../screens/editPreview/EditPreviewScreen";
import EmployeeEditScreen from "../screens/employeesEdit/EmployeeEdit.Screen";
import EmployeeNewScreen from "../screens/employeesNew/EmployeesNew.Screen";
import LoginScreen from "../screens/login/Login.Screen";
import ManagementScreen from "../screens/management/Management.Screen";
import PasswordRequestScreen from "../screens/passwordRequest/PasswordRequest.Screen";
import PasswordResetContainer from "../screens/passwordReset/PasswordResetContainer";
import HealthcareDashboardScreen from "../screens/healthcare/dashboard/Dashboard.Screen";
import HealthCareGraphScreen from "../screens/healthcare/graph/HealthCareGraph.screen";
import RingSearchScreen from "../screens/healthcare/ringSearch/RingSearch.Screen";
import RingUsersListScreen from "../screens/healthcare/users/ringUserList";
import HealthcareManagementScreen from "../screens/healthcare/management/Management.Screen";
import UsageScreen from "../screens/usage/Usage.Screen";
import PunchScreen from "../screens/punch/Punch.Screen";
import PrivateRoute from "./PrivateRoute";

interface RoutesProps {
    status: boolean;
    title: string;
    isAlert: boolean;
    errorMessage: string;
    closeAlert: () => void;
}

const RoutesPath: React.FC<RoutesProps> = (props) => {
    const { status, isAlert, errorMessage, closeAlert } = props;
    return (
        <BrowserRouter>
            <Navigation />
            <CSSTransition
                in={isAlert}
                timeout={200}
                unmountOnExit
                classNames="login__form__alert"
            >
                <Fragment>
                    <div className="error-alert">
                        <Alert
                            color="danger"
                            text={errorMessage}
                            icon={<FaExclamationTriangle />}
                            close={closeAlert}
                        />
                    </div>
                    <div className="spacer" />
                </Fragment>
            </CSSTransition>
            <Routes>
                <Route path="/login" element={<LoginScreen />} />
                <Route
                    path="/password-request"
                    element={<PasswordRequestScreen />}
                />
                <Route
                    path="/password-reset/:mailToken"
                    element={<PasswordResetContainer />}
                />
                <Route
                    path="/"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<DashboardScreen />}
                        />
                    }
                />
                <Route
                    path="/contact"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<ContactScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/list"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<ManagementScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/new"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<EmployeeNewScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/edit/:id"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<EmployeeEditScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/edit-preview"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<EditPreviewScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/transaction-history/:id"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<EmployeeHistoryScreen />}
                        />
                    }
                />
                <Route
                    path="/employees/detail/:id"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<EmployeeDetailScreen />}
                        />
                    }
                />
                <Route
                    path="/product/transaction-history"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<HistoryScreen />}
                        />
                    }
                />
                <Route
                    path="/usage"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<UsageScreen />}
                        />
                    }
                />
                <Route
                    path="/announcements"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<AnnoucemetsScreen />}
                        />
                    }
                />
                <Route
                    path="/announcements/new"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<AnnouncementScreen />}
                        />
                    }
                />
                <Route
                    path="/announcements/edit/:id"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<AnnouncementScreen />}
                        />
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<SettingScreen />}
                        />
                    }
                />
                <Route
                    path="/healthcare"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<HealthcareDashboardScreen />}
                        />
                    }
                />
                <Route
                    path="/healthcareSearch"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<HealthcareManagementScreen />}
                        />
                    }
                />
                <Route
                    path="/healthcareGraph"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<HealthCareGraphScreen />}
                        />
                    }
                />
                <Route
                    path="/ringSearch"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<RingSearchScreen />}
                        />
                    }
                />
                <Route
                    path="/ringUsers"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<RingUsersListScreen />}
                        />
                    }
                />
                <Route
                    path="/punch"
                    element={
                        <PrivateRoute
                            isAuthenticated={status}
                            children={<PunchScreen />}
                        />
                    }
                />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesPath;
