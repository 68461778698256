export enum EnrollmentRequestActionTypes {
	ENROLLMENT_REQUEST_SUCCESS_SET = "ENROLLMENT_REQUEST_SUCCESS_SET",
	BULK_ENROLLMENT_REQUEST_SUCCESS_SET = "BULK_ENROLLMENT_REQUEST_SUCCESS_SET",
}

export interface IEnrollmentParams {
	email: string;
	user_uid: string;
}

export interface IBulkEnrollmentParams {
	user_infos: Array<IEnrollmentParams>;
}

export interface IEnrollmentRequestSuccessSetAction {
	type: EnrollmentRequestActionTypes.ENROLLMENT_REQUEST_SUCCESS_SET;
	payload: boolean;
}

export interface IBulkEnrollmentRequestSuccessSetAction {
	type: EnrollmentRequestActionTypes.BULK_ENROLLMENT_REQUEST_SUCCESS_SET;
	payload: boolean;
}

export interface IRequestEnrollmentState {
	success: boolean;
	bulkSuccess: boolean;
}

export const initialEnrollmentState: IRequestEnrollmentState = {
	success: false,
	bulkSuccess: false,
};
