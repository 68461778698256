//redux
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../index";
//action
import { brandSummarySet } from "../brand/actions";
import { categorySummarySet } from "../category/actions";
import { employeeSet } from "../employee/actions";
import { errorReset, errorSet } from "../error/actions";
import { notificationSet } from "../notification/actions";
import { sessionStatus } from "../session/actions";
import { usageSummarySet } from "../usage/actions";
import {
    employeesTransactionsSet,
    employeeTransactionsCsvSet,
    employeeTransactionsSet,
    productTransactionsSet,
} from "./actions";
//models
import {
    IEmployeesTransactionRes,
    IEmployeesTransactionsParams,
    IEmployeeTransactionsParams,
    IMonthlyParams,
} from "./models";
//helper
import { axiosError } from "../../helpers/axiosError";
//api method
import { downloadAsFile } from "../../components/screens/employeesHistory/helpers";
import getBrandSummary from "../brand/getBrandSummary";
import getCategorySummary from "../category/getCategorySummary";
import getEmployee from "../employee/getEmployee";
import getKvNotifications from "../notification/getKvNotification";
import getUsageSummary from "../usage/getUsageSummary";
import getEmployeesTransactions from "./getEmployeesTransactions";
import getEmployeeTransactions from "./getEmployeeTransactions";
import getEmployeeTransactionsCsv from "./getEmployeeTransactionsCsv";
import getProductTransactions from "./getProductTransactions";

// get product transaction history
// @param token: string
export const thunkGetProductTransactions =
    (
        token: string,
        page = 1,
        months: IMonthlyParams,
        searchParams: IEmployeesTransactionsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        try {
            const res = await getProductTransactions(
                token,
                page,
                months,
                searchParams
            );
            dispatch(productTransactionsSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// get all transactions for dashboard
export const thunkGetDashboardTransactions =
    (
        token: string,
        page = 1,
        months: IMonthlyParams,
        searchParams?: IEmployeesTransactionsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(errorReset());
        try {
            //call api and set store
            // transaction
            const transactionRes: IEmployeesTransactionRes =
                await getEmployeesTransactions(
                    token,
                    page,
                    months,
                    searchParams
                );
            dispatch(employeesTransactionsSet(transactionRes.data));

            //usage summary
            const summaryRes = await getUsageSummary(token, page, months);
            dispatch(usageSummarySet(summaryRes));

            //usage category
            const categoryRes = await getCategorySummary(token, months);
            dispatch(categorySummarySet(categoryRes));

            //notifications
            const notificationRes = await getKvNotifications(token);
            dispatch(notificationSet(notificationRes));

            //brand summary
            const brandRes = await getBrandSummary(token, months);
            dispatch(brandSummarySet(brandRes));

            //refresh token
            dispatch(
                sessionStatus({
                    status: true,
                    token: token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// get employees transactions
// @param token: string
export const thunkGetEmployeeTransactions =
    (
        token: string,
        uid: string,
        page = 1,
        months: IMonthlyParams,
        searchParams?: IEmployeeTransactionsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeTransactionsCsvSet(null));
        try {
            const res = await getEmployeeTransactions(
                token,
                uid,
                page,
                months,
                searchParams
            );
            dispatch(employeeTransactionsSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// get employees transactions
// @param token: string
export const thunkGetEmployeeTransactionsCsv =
    (
        token: string,
        uid: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(employeeTransactionsCsvSet(null));
        try {
            const employeeRes = await getEmployee(token, uid);
            dispatch(employeeSet({ employee: employeeRes.data }));
            const res = await getEmployeeTransactionsCsv(token, uid);
            if (res) {
                downloadAsFile(res, employeeRes.data.employee_code);
            }
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };

// get all transactions for dashboard
export const thunkGetEmployeesTransactions =
    (
        token: string,
        page = 1,
        months: IMonthlyParams,
        searchParams?: IEmployeesTransactionsParams
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        dispatch(errorReset());
        try {
            //call api and set store
            // transaction
            const transactionRes: IEmployeesTransactionRes =
                await getEmployeesTransactions(
                    token,
                    page,
                    months,
                    searchParams
                );
            dispatch(employeesTransactionsSet(transactionRes.data));
            //refresh token
            dispatch(
                sessionStatus({
                    status: true,
                    token: token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    };
