import { IError } from "../store/error/models";
// axios error handler
export const axiosError = (error: any): IError => {
    const errorResult = {
        hasError: true,
        errorType: "unknown",
        errorMessage: "",
        errorMessageArr: [],
    };
    if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        if (error.response.data) {
            if (error.response.data.error) {
                if (error.response.data.error.message) {
                    if (Array.isArray(error.response.data.error.message)) {
                        errorResult.errorMessage =
                            "1 つ以上のフォーマット エラー";
                    } else {
                        if (
                            typeof error.response.data.error.message ===
                            "string"
                        ) {
                            errorResult.errorMessage =
                                error.response.data.error.message;
                        }
                    }
                }
            } else if (typeof error.response.data.error === "string") {
                errorResult.errorMessage = error.response.data.error;
            }
        }
        if (error.response.status) {
            errorResult.errorType = error.response.status.toString();
        }
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        errorResult.errorType = "ネットワークエラー";
        errorResult.errorMessage =
            "インターネットにアクセスできません。お手数ですが、しばらく時間をおいて再度お試しください。";
    } else {
        // Something happened in setting up the request and triggered an Error
        errorResult.errorMessage = error.message;
    }
    return errorResult;
};
