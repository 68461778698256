import axios from "axios";
import { healthDataUrl } from "../../constants";
import { IRingUserState } from "./models";

const getRingUser = async (
	token: string,
	type: string,
	data: string,
): Promise<{ token: string; data: IRingUserState}> => {
	try {
		const res = await axios.get(`${healthDataUrl}/user_search`, {
			headers: { Authorization: `${token}` },
			params: {
				type,
				data,
			}
		});
		return {
			token: res.headers.authorization,
			data: {
				userSearch: {
					users: res.data
			  }
			}
		};
	} catch (e) {
		throw e;
	}
};

export default getRingUser;
